var _a, _b, _c, _d, _e;
import { createSlice } from '@reduxjs/toolkit';
export const seasonStorageKey = 'season';
export const regionStorageKey = 'region';
export const cohortStorageKey = 'cohort';
const seasonStorage = localStorage.getItem(seasonStorageKey);
const regionStorage = localStorage.getItem(regionStorageKey);
const cohortStorage = localStorage.getItem(cohortStorageKey);
const initialState = {
    seasonId: seasonStorage ? (_a = JSON.parse(seasonStorage)) === null || _a === void 0 ? void 0 : _a.seasonId : undefined,
    harvestFilter: seasonStorage ? (_b = JSON.parse(seasonStorage)) === null || _b === void 0 ? void 0 : _b.harvestFilter : undefined,
    cohortId: cohortStorage ? (_c = JSON.parse(cohortStorage)) === null || _c === void 0 ? void 0 : _c.cohortId : undefined,
    regionCode: regionStorage ? (_d = JSON.parse(regionStorage)) === null || _d === void 0 ? void 0 : _d.regionCode : undefined,
    regionFilter: regionStorage ? (_e = JSON.parse(regionStorage)) === null || _e === void 0 ? void 0 : _e.regionCode : undefined,
};
export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        changeSeason: (state, action) => {
            var _a, _b;
            state.seasonId = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.seasonId;
            state.harvestFilter = (_b = action.payload) === null || _b === void 0 ? void 0 : _b.harvestFilter;
            localStorage.setItem(seasonStorageKey, JSON.stringify(action.payload));
        },
        changeRegion: (state, action) => {
            var _a, _b;
            state.regionCode = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.regionCode;
            state.regionFilter = (_b = action.payload) === null || _b === void 0 ? void 0 : _b.regionFilter;
            localStorage.setItem(regionStorageKey, JSON.stringify(action.payload));
        },
        changeCohort: (state, action) => {
            var _a, _b;
            state.cohortId = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.cohortId;
            state.cohortFilter = (_b = action.payload) === null || _b === void 0 ? void 0 : _b.cohortFilter;
            localStorage.setItem(cohortStorageKey, JSON.stringify(action.payload));
        },
    },
});
export const selectSeasonId = (state) => state.dashboard.seasonId;
export const selectRegionCode = (state) => state.dashboard.regionCode;
export const selectCohortId = (state) => state.dashboard.cohortId;
export const selectHarvestFilter = (state) => state.dashboard.harvestFilter;
export const selectRegionFilter = (state) => state.dashboard.regionFilter;
export const selectCohortFilter = (state) => state.dashboard.cohortFilter;
export const { changeSeason, changeRegion, changeCohort } = dashboardSlice.actions;
export const dashboardReducer = dashboardSlice.reducer;
