import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { StackedIcon } from '@components/StackedIcon/StackedIcon';
import { Box, useTheme } from '@mui/material';
import MapGL, { Marker, Filter } from '@urbica/react-map-gl';
import Cluster from '@urbica/react-map-gl-cluster';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useEffect, useRef, useState } from 'react';
const style = {
    textAlign: 'center',
};
export const ClusterMarker = ({ longitude, latitude, pointCount, }) => (_jsx(Marker, Object.assign({ longitude: longitude, latitude: latitude }, { children: _jsx(Box, Object.assign({ sx: style }, { children: _jsx(StackedIcon, { points: pointCount }) })) })));
export const Map = ({ data }) => {
    var _a, _b;
    const mapRef = useRef(null);
    const theme = useTheme();
    const [viewport, setViewport] = useState({
        latitude: 46.22,
        longitude: 2.21,
        zoom: 4,
    });
    const [toggleMapResize, setToggleMapResize] = useState(true);
    useEffect(() => {
        if (data)
            setToggleMapResize(!toggleMapResize);
    }, [data]);
    useEffect(() => {
        var _a;
        if (mapRef === null || mapRef === void 0 ? void 0 : mapRef.current) {
            (_a = mapRef.current.getMap()) === null || _a === void 0 ? void 0 : _a.resize();
        }
    }, [data]);
    useEffect(() => {
        if (mapRef === null || mapRef === void 0 ? void 0 : mapRef.current) {
            const map = mapRef.current.getMap();
            map === null || map === void 0 ? void 0 : map.on('idle', function () {
                map === null || map === void 0 ? void 0 : map.resize();
            });
            map === null || map === void 0 ? void 0 : map.resize();
        }
    }, [mapRef, data]);
    const showShadowForInactiveCountries = [
        'match',
        ['get', 'iso_3166_1'],
        data === null || data === void 0 ? void 0 : data.map((c) => c.countryCode),
        false,
        true,
    ];
    return (_jsx(_Fragment, { children: _jsxs(MapGL, Object.assign({ accessToken: process.env.MAPBOX_TOKEN, attributionControl: false, logoPosition: "bottom-right", latitude: viewport.latitude, longitude: viewport.longitude, zoom: viewport.zoom, style: {
                width: '100%',
                height: '100%',
                borderRadius: '8px',
                border: '1px solid',
                borderColor: (_b = (_a = theme.palette) === null || _a === void 0 ? void 0 : _a.grey) === null || _b === void 0 ? void 0 : _b[400],
            }, mapStyle: process.env.MAPBOX_STYLE, ref: mapRef, onViewportChange: setViewport }, { children: [_jsx(Filter, { layerId: "country-boundaries", filter: showShadowForInactiveCountries }), data === null || data === void 0 ? void 0 : data.map((country) => (_jsx(Cluster, Object.assign({ radius: 10, extent: 30, nodeSize: 64, component: ClusterMarker }, { children: country.regions.map((region) => Array.from({ length: region.farmCount }).map(() => (_jsx(Marker, Object.assign({ longitude: region.longitude, latitude: region.latitude }, { children: _jsx(Box, Object.assign({ sx: style }, { children: _jsx(StackedIcon, { points: region.farmCount }) })) }), `${country.countryCode}-${region.regionCode}`)))) }), country.countryCode)))] })) }));
};
