import { api } from './api.service';
export const segmentApi = api.injectEndpoints({
    endpoints: (builder) => ({
        trackHarvestFilterChange: builder.mutation({
            query: (data) => ({
                url: `/private/segments/dashboard/harvest`,
                method: 'POST',
                body: data,
            }),
        }),
        trackGeographyFilterChange: builder.mutation({
            query: (data) => ({
                url: `/private/segments/dashboard/geography`,
                method: 'POST',
                body: data,
            }),
        }),
        trackCohortFilterChange: builder.mutation({
            query: (data) => ({
                url: `/private/segments/dashboard/cohort`,
                method: 'POST',
                body: data,
            }),
        }),
        trackExportToPdf: builder.mutation({
            query: (data) => ({
                url: `/private/segments/dashboard/export`,
                method: 'POST',
                body: data,
            }),
        }),
    }),
});
export const { useTrackHarvestFilterChangeMutation, useTrackGeographyFilterChangeMutation, useTrackCohortFilterChangeMutation, useTrackExportToPdfMutation, } = segmentApi;
