import { makeStyles } from 'tss-react/mui';
export const useMembersTableStyles = makeStyles()((theme) => ({
    pending: {
        color: theme.palette.grey['500'],
        '&:after': {
            color: theme.palette.grey['500'],
        },
    },
    email: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));
