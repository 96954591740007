var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useChangeResultPublicationMutation } from '@services/results.api';
export const useResultPublication = ({ onSuccess } = {}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [changePublication, { isLoading }] = useChangeResultPublicationMutation();
    const changeResultPublication = ({ farmSeasonId, published, }) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield changePublication({ farmSeasonId, published }).unwrap();
            enqueueSnackbar(t(published ? 'snackbar.results-published' : 'snackbar.results-unpublished'), {
                variant: 'success',
            });
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
        }
        catch (e) {
            enqueueSnackbar(t('errors.server-error'), { variant: 'error' });
        }
    });
    return { changeResultPublication, isLoading };
};
