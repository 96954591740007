var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, CircularProgress } from '@mui/material';
import { useLogoutMutation } from '@services';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useLogoutButtonStyle from './LogoutButton.style';
import { IconLogout } from '@components/Icons';
function LogoutButton() {
    const { classes } = useLogoutButtonStyle();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [logout, { isLoading }] = useLogoutMutation();
    const handleClick = () => __awaiter(this, void 0, void 0, function* () {
        yield logout();
        navigate('/login');
    });
    return (_jsxs(Button, Object.assign({ disabled: isLoading, className: classes.avatarMenuLogoutBtn, variant: "text", onClick: handleClick, fullWidth: true }, { children: [isLoading ? (_jsx(CircularProgress, { className: classes.logoutIcon, color: "inherit", size: "16px" })) : (_jsx(IconLogout, { className: classes.logoutIcon, color: "error" })), t('buttons.logout')] })));
}
export default LogoutButton;
