export const KEYBOARD_KEY = {
    ENTER: 'Enter',
    BACKSPACE: 'Backspace',
    SPACE: ' ',
};
export const FARMER_STATUS = {
    RENEWED: 'RENEWED',
    INITIAL_SETUP_IN_PROGRESS: 'INITIAL_SETUP_IN_PROGRESS',
    WAITING_FOR_AGRONOMIST_MEETING: 'WAITING_FOR_AGRONOMIST_MEETING',
    AGRONOMIST_DATA_INPUT: 'AGRONOMIST_DATA_INPUT',
    DATA_VALIDATION: 'DATA_VALIDATION',
    RESULTS_UNDER_REVIEW: 'RESULTS_UNDER_REVIEW',
    RESULTS_AVAILABLE: 'RESULTS_AVAILABLE',
    INVITATION_REFUSED: 'INVITATION_REFUSED',
    PENDING: 'PENDING',
    CREATED: 'CREATED',
    ENROLLED: 'ENROLLED',
    FARM_CREATED: 'FARM_CREATED',
};
export const CARBON_RESULT_MODEL = {
    DNDC: 'DNDC',
    CFT: 'CFT',
};
