import { makeStyles } from 'tss-react/mui';
export const useAlarmingDataGroupStyles = makeStyles()((theme) => ({
    top: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    confirmButton: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    group: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
        border: `1px solid ${theme.palette.grey['200']}`,
        padding: theme.spacing(3),
        borderRadius: '8px',
    },
    infos: {
        display: 'flex',
        alignItems: 'center',
    },
    name: {
        minWidth: '350px',
        paddingRight: theme.spacing(5),
        borderRight: `1px solid ${theme.palette.grey['200']}`,
    },
    values: {
        marginLeft: theme.spacing(5),
    },
    value: {
        color: theme.palette.grey['400'],
        paddingLeft: theme.spacing(5),
    },
}));
