import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Tooltip, Typography } from '@mui/material';
import useStackedBarGraphStyle from './StackedBarGraph.style';
import clsx from 'clsx';
const StackedItemBar = ({ percentage, color, label }) => {
    const { classes } = useStackedBarGraphStyle();
    if (percentage === 0)
        return null;
    return (_jsx(Tooltip, Object.assign({ title: `${label} : ${percentage}%`, arrow: true, placement: "top" }, { children: _jsx(Box, { className: classes.stackedItem, width: `${percentage}%`, bgcolor: color }) })));
};
export const StackedItemLegend = ({ label, color }) => {
    const { classes } = useStackedBarGraphStyle();
    return (_jsxs("div", Object.assign({ className: classes.legendContainer }, { children: [_jsx(Box, { className: classes.legendIcon, bgcolor: color }), _jsx(Typography, Object.assign({ className: classes.legend, variant: "body2" }, { children: label }))] })));
};
export const StackedBarGraph = ({ name, items, isSelected = false, showLegend = true }) => {
    const { classes } = useStackedBarGraphStyle();
    // We have to Math.round => https://stackoverflow.com/questions/588004/is-floating-point-math-broken/588014#588014
    const isTotalAmount100 = Math.round(items.reduce((acc, cur) => (acc += cur.percentage), 0)) === 100;
    if (!isTotalAmount100)
        throw new Error('StackedBarGraph items total percentage should be 100 when added together');
    return (_jsxs(Box, Object.assign({ className: classes.container }, { children: [_jsxs(Box, Object.assign({ className: clsx(classes.stackedBarContainer, isSelected && classes.selectedBackground) }, { children: [_jsx(Box, Object.assign({ mr: "8px" }, { children: _jsx(Typography, Object.assign({ variant: "body2", className: classes.name }, { children: name })) })), _jsx(Box, Object.assign({ className: classes.stackedBar }, { children: items.map((item) => (_jsx(StackedItemBar, Object.assign({}, item), item.label))) }))] })), showLegend && (_jsx(Box, Object.assign({ className: classes.legendsContainer, mt: 1 }, { children: items.map((item) => (_jsx(StackedItemLegend, { label: item.label, color: item.color }, item.label))) })))] })));
};
