var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconTrash } from '@components/Icons';
import { Modal } from '@components/Modal/Modal';
import { RequiredInputLabel } from '@components/RequiredInputLabel';
import { SelectRoles } from '@components/SelectRoles/SelectRoles';
import { changeContact, selectFarmersListContactId } from '@features/farmer/listFarmers/farmersList.slice';
import { MemberEditModalNewLoader } from '@features/member/MemberEditModalNew/MemberEditModalNewLoader';
import { useMemberEditModalFormData } from '@features/member/MemberEditModalNew/useMemberEditModalFormData';
import { useAppDispatch, useAppSelector } from '@hooks';
import { useGetAssignableRoles } from '@hooks/useGetAssignableRoles';
import { usePermission } from '@hooks/usePermission';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useDeletePartnerUsersMutation, useUpdatePartnerUserMutation } from '@services';
import { useDeleteMemberInvitationsMutation } from '@services/invitation.api';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMemberEditModalNewStyles } from './MemberEditModalNew.style';
export const MemberEditModalNew = ({ open, onClose, member }) => {
    const { t } = useTranslation();
    const { classes } = useMemberEditModalNewStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { canDeletePartnerMembers } = usePermission();
    const { data: roles, isLoading } = useGetAssignableRoles(member.id);
    const contactId = useAppSelector(selectFarmersListContactId);
    const dispatch = useAppDispatch();
    const [updateRole, { isLoading: isLoadingUpdateMutation }] = useUpdatePartnerUserMutation();
    const [deleteInvitation, { isLoading: isLoadingDeleteInvitation }] = useDeleteMemberInvitationsMutation();
    const [deletePartnerUsers, { isLoading: isLoadingDeletePartnerUser }] = useDeletePartnerUsersMutation();
    const isMutationLoading = isLoadingUpdateMutation || isLoadingDeleteInvitation || isLoadingDeletePartnerUser;
    const { watch, setValue, handleSubmit, reset } = useMemberEditModalFormData({ member });
    const roleId = watch('roleId');
    const isMemberOwner = member.role.translation_slug === 'roles.name.owner';
    const shouldDisableEdit = !member.user_id || isMemberOwner;
    const shouldDisableDelete = !canDeletePartnerMembers || isMemberOwner;
    const handleRoleChange = (role) => setValue('roleId', role.id);
    const handleDeleteMember = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (member.partner_user_id) {
                yield deletePartnerUsers([member.partner_user_id]).unwrap();
                if (contactId === member.partner_user_id)
                    dispatch(changeContact({ contactId: undefined }));
            }
            else
                yield deleteInvitation({ ids: [member.invitation_id] }).unwrap();
            enqueueSnackbar(t('snackbar.member-deleted-success'), { variant: 'success' });
            handleClose();
        }
        catch (e) {
            enqueueSnackbar(t('errors.server-error'), { variant: 'error' });
        }
    });
    const handleClose = () => {
        reset();
        onClose();
    };
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (member.partner_user_id)
                yield updateRole({
                    id: member === null || member === void 0 ? void 0 : member.partner_user_id,
                    body: {
                        role_id: data.roleId,
                    },
                }).unwrap();
            enqueueSnackbar(t('snackbar.member-role-success'), { variant: 'success' });
            onClose();
        }
        catch (e) {
            enqueueSnackbar(t('errors.server-error'), { variant: 'error' });
        }
    });
    if (isLoading || !roleId)
        return _jsx(MemberEditModalNewLoader, { open: open, onClose: onClose });
    if (!isLoading && !roles) {
        enqueueSnackbar(t('errors.server-error'), { variant: 'error' });
        onClose();
    }
    return (_jsx(Modal, Object.assign({ open: open, onClose: handleClose, title: t('members.edit.title') }, { children: _jsxs("form", Object.assign({ role: "form", onSubmit: handleSubmit(onSubmit) }, { children: [_jsx("div", { children: _jsxs(Grid, Object.assign({ container: true, spacing: 5 }, { children: [_jsxs(Grid, Object.assign({ item: true, sm: 6 }, { children: [_jsx(Box, Object.assign({ mb: "5px" }, { children: _jsx(Typography, Object.assign({ color: "secondary", variant: "caption" }, { children: t('labels.first-name') })) })), _jsx(Typography, Object.assign({ variant: "body1", color: "primary" }, { children: member === null || member === void 0 ? void 0 : member.first_name }))] })), _jsxs(Grid, Object.assign({ item: true, sm: 6 }, { children: [_jsx(Box, Object.assign({ mb: "5px" }, { children: _jsx(Typography, Object.assign({ color: "secondary", variant: "caption" }, { children: t('labels.last-name') })) })), _jsx(Typography, Object.assign({ variant: "body1", color: "primary" }, { children: member === null || member === void 0 ? void 0 : member.last_name }))] })), _jsxs(Grid, Object.assign({ item: true, sm: 6 }, { children: [_jsx(Box, Object.assign({ mb: "5px" }, { children: _jsx(Typography, Object.assign({ color: "secondary", variant: "caption" }, { children: t('labels.email') })) })), _jsx(Typography, Object.assign({ variant: "body1", color: "primary" }, { children: member === null || member === void 0 ? void 0 : member.email }))] })), _jsxs(Grid, Object.assign({ item: true, sm: 6 }, { children: [_jsx(Box, Object.assign({ mb: "5px" }, { children: _jsx(Typography, Object.assign({ color: "secondary", variant: "caption" }, { children: t('labels.status') })) })), _jsx(Typography, Object.assign({ variant: "body1", color: "primary" }, { children: (member === null || member === void 0 ? void 0 : member.registered_at)
                                            ? t('statuses.active')
                                            : (member === null || member === void 0 ? void 0 : member.accepted_at)
                                                ? t('statuses.accepted')
                                                : t('statuses.PENDING') }))] })), !shouldDisableEdit && (_jsxs(Grid, Object.assign({ item: true, sm: 12 }, { children: [_jsx(Box, Object.assign({ mb: "5px" }, { children: _jsx(RequiredInputLabel, Object.assign({ color: "secondary", required: true }, { children: t('labels.role') })) })), _jsx(SelectRoles, { sx: { width: '40%' }, roleId: roleId, roles: roles, onChange: handleRoleChange })] })))] })) }), _jsxs("div", Object.assign({ className: classes.buttonsContainer }, { children: [_jsx("div", { children: !shouldDisableDelete && (_jsxs(Button, Object.assign({ variant: "text", color: "error", onClick: handleDeleteMember }, { children: [_jsx(IconTrash, { color: "error" }), t('buttons.delete-member')] }))) }), !shouldDisableEdit && (_jsxs("div", Object.assign({ className: classes.rightActionsContainer }, { children: [_jsx(Button, Object.assign({ onClick: handleClose, color: "info" }, { children: t('buttons.cancel') })), _jsx(Button, Object.assign({ disabled: isMutationLoading, type: "submit" }, { children: isMutationLoading ? _jsx(CircularProgress, { size: 20 }) : t('buttons.save') }))] })))] }))] })) })));
};
