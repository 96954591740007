import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Typography } from '@mui/material';
import { BigNumberUnit } from '../BigNumberUnit';
import { BoxedIcon } from '../BoxedIcon';
import { TooltipSc } from '../TooltipSc';
import useMetricStyle from './Metric.style';
export const Metric = ({ boxedIconProps, bigNumberUnitProps, label, tooltipScProps }) => {
    const { classes } = useMetricStyle();
    return (_jsxs(Box, Object.assign({ className: classes.container }, { children: [_jsx(Box, Object.assign({ sx: { paddingRight: '8px' } }, { children: _jsx(BoxedIcon, Object.assign({}, boxedIconProps)) })), _jsxs(Box, Object.assign({ sx: { display: 'flex', justifyContent: 'center', flexDirection: 'column' } }, { children: [bigNumberUnitProps && _jsx(BigNumberUnit, Object.assign({}, bigNumberUnitProps)), label && (_jsxs(Typography, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [label, tooltipScProps && (_jsx(TooltipSc, Object.assign({ display: 'flex', text: tooltipScProps.text }, { children: _jsx(InfoOutlinedIcon, { className: classes.infoIcon }) })))] })))] }))] })));
};
