import { makeStyles } from 'tss-react/mui';
export default makeStyles()(() => {
    return {
        container: {
            display: 'flex',
            gap: 16,
            width: '100%',
        },
        title: {
            paddingTop: 10,
            fontWeight: 700,
            display: 'block',
        },
        body: {
            fontWeight: 400,
            paddingTop: '8px',
            display: 'block',
        },
    };
});
