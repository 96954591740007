import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from '@mui/material';
import AuthLayout from '../../layouts/AuthLayout/AuthLayout';
import LoginForm from '@features/auth/LoginForm/LoginForm';
import { useTranslation } from 'react-i18next';
const LoginPage = () => {
    const { t } = useTranslation();
    return (_jsx(_Fragment, { children: _jsx(AuthLayout, { form: _jsx(LoginForm, {}), sideZone: _jsx(Box, {}), title: t('titles.login-in-to-partners-portal') }) }));
};
export default LoginPage;
