import { makeStyles } from 'tss-react/mui';
export const useRegionFilterStyle = makeStyles()(() => {
    return {
        select: {
            minWidth: '180px',
            height: '40px',
            '.MuiSelect-select': {
                display: 'flex',
            },
            '& > div.MuiSelect-select': {
                padding: '8px 16px',
            },
            '& .MuiTypography-body1': {
                fontWeight: 'normal',
                marginLeft: 0,
                fontSize: '16px',
            },
        },
        selectOption: {
            textTransform: 'capitalize',
            maxWidth: '200px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        selectWrapper: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: '20px',
        },
        selectIconMap: {
            width: 20,
            marginRight: '8px',
            marginTop: 2,
        },
        menuItem: {
            minHeight: '33px',
            zIndex: '40',
            textTransform: 'capitalize',
        },
        selectRegionInput: {
            minWidth: '160px',
            maxWidth: '280px',
            width: 'fit-content',
        },
        groupedOption: {
            color: '#596D79',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            '& svg': {
                width: '20px',
            },
        },
    };
});
