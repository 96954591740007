import { formatNumber, isNumberBetweenNegative1And1, roundUpIfPositiveDownIfNegative } from '@utils/numbers';
import { useTranslation } from 'react-i18next';
import { useGetCarbonResultsBestPerformersQuery, useGetFarmsCarbonResultsAnalyticsQuery, } from '@services/analytics.api';
import { useAppSelector } from '@hooks/redux';
import { selectCohortId, selectRegionCode, selectSeasonId } from '@features/dashboard/dashboardSlice';
import { useMemo } from 'react';
import { getNationality } from '@utils/language';
import { PREVIOUS_YEAR_VARIANTS } from '@components/PreviousYearResults/PreviousYearResults';
import { useAuth } from '@hooks/useAuth';
const CITIZENS_RATIOS = {
    BE: 8.24,
    FR: 4.74,
    UK: 5.15,
    AR: 4.12,
};
export const RATIOS = {
    trees: 25,
    citizens: CITIZENS_RATIOS,
    cars: 141.9,
};
export const compareCarbonResultsToPreviousYear = (currentSeasonBalance, previousYearSeasonBalance) => {
    if (currentSeasonBalance === undefined)
        return undefined;
    if (!previousYearSeasonBalance) {
        if (currentSeasonBalance === 0)
            return PREVIOUS_YEAR_VARIANTS.ZERO_NO_COMPARISON;
        if (currentSeasonBalance > 0)
            return PREVIOUS_YEAR_VARIANTS.EMITTING_NO_COMPARISON;
        return PREVIOUS_YEAR_VARIANTS.SEQUESTRATING_NO_COMPARISON;
    }
    if (currentSeasonBalance > 0 && currentSeasonBalance > previousYearSeasonBalance)
        return PREVIOUS_YEAR_VARIANTS.EMITTING_MORE;
    if (currentSeasonBalance > 0 && currentSeasonBalance < previousYearSeasonBalance)
        return PREVIOUS_YEAR_VARIANTS.EMITTING_LESS;
    if (currentSeasonBalance < 0 && currentSeasonBalance < previousYearSeasonBalance)
        return PREVIOUS_YEAR_VARIANTS.SEQUESTRATING_MORE;
    if (currentSeasonBalance < 0 && currentSeasonBalance > previousYearSeasonBalance)
        return PREVIOUS_YEAR_VARIANTS.SEQUESTRATING_LESS;
    if (currentSeasonBalance === 0 && currentSeasonBalance > previousYearSeasonBalance)
        return PREVIOUS_YEAR_VARIANTS.ZERO_WORSE_THAN_LAST_YEAR;
    if (currentSeasonBalance === 0 && currentSeasonBalance < previousYearSeasonBalance)
        return PREVIOUS_YEAR_VARIANTS.ZERO_BETTER_THAN_LAST_YEAR;
    return PREVIOUS_YEAR_VARIANTS.ZERO_NO_COMPARISON;
};
export const computeFarmsCarbonResultsData = (selectedSeasonId, fetchedData, partnerCountryIso) => {
    if (!fetchedData || !selectedSeasonId)
        return undefined;
    const filteredData = fetchedData.filter((season) => season.season_id === selectedSeasonId);
    const selectedSeasonData = filteredData === null || filteredData === void 0 ? void 0 : filteredData[0];
    const farmsAverageBalance = [];
    const farmsTotalEmission = [];
    const farmsTotalSequestration = [];
    const farmsTotalBalance = [];
    const farmsEmissionsPerspectiveTrees = [];
    const farmsEmissionsPerspectiveCitizens = [];
    const farmsEmissionsPerspectiveCars = [];
    const filteredSeasonIndex = fetchedData.findIndex((season) => season.season_id === (selectedSeasonData === null || selectedSeasonData === void 0 ? void 0 : selectedSeasonData.season_id));
    const previousYearSeason = fetchedData[filteredSeasonIndex - 1];
    const previousYearResults = compareCarbonResultsToPreviousYear(selectedSeasonData === null || selectedSeasonData === void 0 ? void 0 : selectedSeasonData.average_balance, previousYearSeason === null || previousYearSeason === void 0 ? void 0 : previousYearSeason.average_balance);
    const citizensRatio = partnerCountryIso && partnerCountryIso in RATIOS.citizens
        ? RATIOS.citizens[partnerCountryIso]
        : RATIOS.citizens.FR;
    fetchedData.map((season) => {
        const constant = { id: season.season_id, x: `${season.harvest_year}` };
        const farmAverageBalance = roundUpIfPositiveDownIfNegative(season.average_balance, 1);
        const farmRoundedEmissions = roundUpIfPositiveDownIfNegative(season.total_emission, 1);
        const farmRoundedSequestration = roundUpIfPositiveDownIfNegative(season.total_sequestration, 1);
        const farmTotalBalance = roundUpIfPositiveDownIfNegative(season.total_balance, 1);
        farmsAverageBalance.push(Object.assign(Object.assign({}, constant), { y: farmAverageBalance, unit: 't CO₂/ha' }));
        farmsTotalEmission.push(Object.assign(Object.assign({}, constant), { y: farmRoundedEmissions, unit: 't' }));
        farmsTotalSequestration.push(Object.assign(Object.assign({}, constant), { y: farmRoundedSequestration, unit: 't' }));
        farmsTotalBalance.push(Object.assign(Object.assign({}, constant), { y: farmTotalBalance, unit: 't' }));
        // 1 tree = 25kg of CO2
        // total_balance is in t of CO2
        const numberOfTrees = (season.total_balance * 1000) / RATIOS.trees;
        const roundedTrees = roundUpIfPositiveDownIfNegative(numberOfTrees, isNumberBetweenNegative1And1(numberOfTrees) ? 1 : 0);
        farmsEmissionsPerspectiveTrees.push(Object.assign(Object.assign({}, constant), { y: roundedTrees, unit: '' }));
        const numberOfCitizens = season.total_balance / citizensRatio;
        const roundedCitizens = roundUpIfPositiveDownIfNegative(numberOfCitizens, isNumberBetweenNegative1And1(numberOfCitizens) ? 1 : 0);
        farmsEmissionsPerspectiveCitizens.push(Object.assign(Object.assign({}, constant), { y: roundedCitizens, unit: '' }));
        // Cars = 141.9 g CO2/km
        // total_balance is in t of CO2
        const numberOfCarKms = (season.total_balance * 1000000) / RATIOS.cars;
        const roundedKms = roundUpIfPositiveDownIfNegative(numberOfCarKms, isNumberBetweenNegative1And1(numberOfCarKms) ? 1 : 0);
        farmsEmissionsPerspectiveCars.push(Object.assign(Object.assign({}, constant), { y: roundedKms, unit: 'km' }));
    });
    const currentSeasonTrees = ((selectedSeasonData === null || selectedSeasonData === void 0 ? void 0 : selectedSeasonData.total_balance) * 1000) / RATIOS.trees;
    const currentCitizens = (selectedSeasonData === null || selectedSeasonData === void 0 ? void 0 : selectedSeasonData.total_balance) / citizensRatio;
    const currentCarKms = ((selectedSeasonData === null || selectedSeasonData === void 0 ? void 0 : selectedSeasonData.total_balance) * 1000000) / RATIOS.cars;
    const currentSeasonPerspective = {
        trees: roundUpIfPositiveDownIfNegative(currentSeasonTrees, isNumberBetweenNegative1And1(currentSeasonTrees) ? 1 : 0),
        citizens: roundUpIfPositiveDownIfNegative(currentCitizens, isNumberBetweenNegative1And1(currentCitizens) ? 1 : 0),
        cars: roundUpIfPositiveDownIfNegative(currentCarKms, isNumberBetweenNegative1And1(currentCarKms) ? 1 : 0),
    };
    const averageBalance = roundUpIfPositiveDownIfNegative((selectedSeasonData === null || selectedSeasonData === void 0 ? void 0 : selectedSeasonData.average_balance) || 0, 1);
    const totalEmission = roundUpIfPositiveDownIfNegative((selectedSeasonData === null || selectedSeasonData === void 0 ? void 0 : selectedSeasonData.total_emission) || 0, 1);
    const totalSequestration = roundUpIfPositiveDownIfNegative((selectedSeasonData === null || selectedSeasonData === void 0 ? void 0 : selectedSeasonData.total_sequestration) || 0, 1);
    const totalBalance = roundUpIfPositiveDownIfNegative(selectedSeasonData === null || selectedSeasonData === void 0 ? void 0 : selectedSeasonData.total_balance, 1);
    return {
        averageBalance,
        totalEmission,
        totalSequestration,
        totalBalance,
        previousYearResults,
        farmsAverageBalance,
        farmsTotalEmission,
        farmsTotalSequestration,
        farmsTotalBalance,
        perspective: currentSeasonPerspective,
        farmsEmissionsPerspectiveTrees,
        farmsEmissionsPerspectiveCitizens,
        farmsEmissionsPerspectiveCars,
        citizensRatio,
        partnerIsoCode: partnerCountryIso,
    };
};
export const computeCarbonResultsBestPerformersData = (selectedSeasonId, fetchedData) => {
    if (!fetchedData || !selectedSeasonId)
        return undefined;
    const filteredData = fetchedData === null || fetchedData === void 0 ? void 0 : fetchedData.filter((season) => season.season_id === selectedSeasonId);
    const selectedSeasonData = filteredData === null || filteredData === void 0 ? void 0 : filteredData[0];
    const bestPerformersAverageBalances = [];
    fetchedData.map((season) => {
        bestPerformersAverageBalances.push({
            id: season.season_id,
            x: `${season.harvest_year}`,
            y: roundUpIfPositiveDownIfNegative(season.average_balance, 1),
            unit: 't CO₂/ha',
        });
    });
    const averageBalance = roundUpIfPositiveDownIfNegative((selectedSeasonData === null || selectedSeasonData === void 0 ? void 0 : selectedSeasonData.average_balance) || 0, 1);
    return {
        averageBalance,
        bestPerformersAverageBalances,
    };
};
export const useDashboardCarbonResultsDataView = () => {
    var _a, _b, _c, _d;
    const { i18n, t } = useTranslation();
    const regionCode = useAppSelector(selectRegionCode);
    const cohortId = useAppSelector(selectCohortId);
    const selectedSeasonId = useAppSelector(selectSeasonId);
    const { partner } = useAuth();
    const partnerCountry = (_a = partner === null || partner === void 0 ? void 0 : partner.country) === null || _a === void 0 ? void 0 : _a.iso_code;
    const { data: carbonResultsData, isLoading: carbonResultsDataIsLoading, isFetching: carbonResultsDataIsFetching, isError: carbonResultsDataIsError, } = useGetFarmsCarbonResultsAnalyticsQuery({ regionCode, cohortId });
    const { data: carbonResultsBestPerformersData, isLoading: carbonResultsBestPerformersDataIsLoading, isFetching: carbonResultsBestPerformersDataIsFetching, isError: carbonResultsBestPerformersDataIsError, } = useGetCarbonResultsBestPerformersQuery();
    const hasData = !!(carbonResultsData || carbonResultsBestPerformersData || selectedSeasonId);
    const carbonResults = useMemo(() => computeFarmsCarbonResultsData(selectedSeasonId, carbonResultsData, partnerCountry), [selectedSeasonId, carbonResultsData, partnerCountry]);
    const carbonResultsBestPerformers = useMemo(() => computeCarbonResultsBestPerformersData(selectedSeasonId, carbonResultsBestPerformersData), [selectedSeasonId, carbonResultsBestPerformersData]);
    const formattedAverageBalance = formatNumber(Math.abs((carbonResults === null || carbonResults === void 0 ? void 0 : carbonResults.averageBalance) || 0), i18n.language);
    const formattedBestPerformersAverageBalance = formatNumber(Math.abs((carbonResultsBestPerformers === null || carbonResultsBestPerformers === void 0 ? void 0 : carbonResultsBestPerformers.averageBalance) || 0), i18n.language);
    const formattedTotalEmission = formatNumber(Math.abs((carbonResults === null || carbonResults === void 0 ? void 0 : carbonResults.totalEmission) || 0), i18n.language);
    const formattedTotalSequestration = formatNumber(Math.abs((carbonResults === null || carbonResults === void 0 ? void 0 : carbonResults.totalSequestration) || 0), i18n.language);
    const formattedTotalBalance = formatNumber(Math.abs((carbonResults === null || carbonResults === void 0 ? void 0 : carbonResults.totalBalance) || 0), i18n.language);
    const formattedTrees = formatNumber(Math.abs(((_b = carbonResults === null || carbonResults === void 0 ? void 0 : carbonResults.perspective) === null || _b === void 0 ? void 0 : _b.trees) || 0), i18n.language);
    const formattedCitizens = formatNumber(Math.abs(((_c = carbonResults === null || carbonResults === void 0 ? void 0 : carbonResults.perspective) === null || _c === void 0 ? void 0 : _c.citizens) || 0), i18n.language);
    const formattedCarsKms = formatNumber(Math.abs(((_d = carbonResults === null || carbonResults === void 0 ? void 0 : carbonResults.perspective) === null || _d === void 0 ? void 0 : _d.cars) || 0), i18n.language);
    const isEmittingMoreOnAverage = (carbonResults === null || carbonResults === void 0 ? void 0 : carbonResults.averageBalance) ? carbonResults.averageBalance > 0 : false;
    const areBestPerformersEmittingOnAverage = (carbonResultsBestPerformers === null || carbonResultsBestPerformers === void 0 ? void 0 : carbonResultsBestPerformers.averageBalance)
        ? carbonResultsBestPerformers.averageBalance > 0
        : false;
    const isTotalBalanceEmitting = (carbonResults === null || carbonResults === void 0 ? void 0 : carbonResults.totalBalance) ? (carbonResults === null || carbonResults === void 0 ? void 0 : carbonResults.totalBalance) > 0 : false;
    const partnerNationality = t(getNationality((carbonResults === null || carbonResults === void 0 ? void 0 : carbonResults.partnerIsoCode) || 'FR'));
    const data = hasData
        ? Object.assign(Object.assign(Object.assign({}, carbonResults), carbonResultsBestPerformers), { formattedAverageBalance,
            formattedBestPerformersAverageBalance,
            formattedTotalEmission,
            formattedTotalSequestration,
            formattedTotalBalance,
            formattedTrees,
            formattedCitizens,
            formattedCarsKms,
            isEmittingMoreOnAverage,
            areBestPerformersEmittingOnAverage,
            isTotalBalanceEmitting,
            partnerNationality }) : undefined;
    const isLoading = carbonResultsDataIsLoading || carbonResultsBestPerformersDataIsLoading;
    const isFetching = carbonResultsDataIsFetching || carbonResultsBestPerformersDataIsFetching;
    const isError = carbonResultsDataIsError || carbonResultsBestPerformersDataIsError;
    return {
        data,
        isLoading,
        isError,
        isFetching,
    };
};
