import { makeStyles } from 'tss-react/mui';
export const useInviteFarmersModalStyles = makeStyles()((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
    },
    inputTitle: {
        marginBottom: theme.spacing(1),
        textTransform: 'capitalize',
    },
    chip: {
        paddingRight: theme.spacing(1),
    },
    chipDelete: {
        width: 12,
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    buttonLoader: {
        color: theme.palette.common.white,
    },
}));
