const autocompleteCss = {
    inputRoot: {
        padding: '7.5px',
    },
    listbox: {
        '&::-webkit-scrollbar': {
            width: '5px',
            backgroundColor: '#e2d0b6',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#b17c43',
            borderRadius: '15px',
        },
    },
};
const MuiAutocomplete = { styleOverrides: autocompleteCss };
export default MuiAutocomplete;
