import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CircularProgress } from '@mui/material';
import { DashboardContentFallback } from './DahsboardContentFallback';
import { DashboardProgramme } from '@features/dashboard/DashboardContent/DashboardProgramme/DashboardProgramme';
import { DashboardCarbonResults } from '@features/dashboard/DashboardContent/DashboardCarbonResults/DashboardCarbonResults';
import { useDashboardData } from '@features/dashboard/useDashboardData';
export const DashboardContent = () => {
    var _a, _b;
    const { data, isLoading, isFetching, isError } = useDashboardData();
    if (isLoading || isFetching)
        return (_jsx(Box, Object.assign({ display: "flex", justifyContent: "center", mt: 24 }, { children: _jsx(CircularProgress, { size: 48 }) })));
    if (isError)
        return _jsx(DashboardContentFallback, {});
    if (!data || !((_a = data === null || data === void 0 ? void 0 : data.programme) === null || _a === void 0 ? void 0 : _a.farmsAmount) || data.programme.farmsAmount <= 1)
        return _jsx(DashboardContentFallback, { amount: (_b = data === null || data === void 0 ? void 0 : data.programme) === null || _b === void 0 ? void 0 : _b.farmsAmount });
    return (_jsxs(_Fragment, { children: [_jsx(DashboardProgramme, { data: data.programme }), _jsx(Box, Object.assign({ mt: 16 }, { children: _jsx(DashboardCarbonResults, { data: data.carbonResults }) }))] }));
};
