import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRenewalStatus } from '@features/farmer/profile/farmSeasonStatus/RenewalStatus/useRenewalStatus';
import { DashboardStep } from '@features/farmer/profile/components/FarmerDashboard/Step/DashboardStep';
import CheckCircle from '@mui/icons-material/CheckCircle';
import useCollapsableStyles from '@features/farmer/profile/components/FarmerDashboard/Collapsable.style';
import { Box } from '@mui/material';
export const RenewalStatus = (props) => {
    const { classes } = useCollapsableStyles();
    const { renewalStatus, hasRenewed } = useRenewalStatus(props);
    return (_jsx(_Fragment, { children: hasRenewed && (_jsxs(_Fragment, { children: [_jsx(DashboardStep, { icon: _jsx(CheckCircle, { className: classes.checkIcon }), stepStatus: renewalStatus }), _jsx(Box, { className: classes.stepConnector })] })) }));
};
