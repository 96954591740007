import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FarmerProfileAboutCard } from '@features/farmer/profile/components/FarmerProfileAboutCard/FarmerProfileAboutCard';
import { Box, Grid } from '@mui/material';
import { useFarmerProfilePageStyles } from '@/pages/FarmerProfile/FarmerProfilePage.style';
import { DeferredPaymentBanner } from '@features/farmer/profile/deferredPayment/DeferredPaymentBanner';
import { FarmerProfileHeaderCard } from '@features/farmer/profile/components/FarmerProfileHeaderCard/FarmerProfileHeaderCard';
import { FarmerProfileFarmCard } from '@features/farmer/profile/components/FarmerProfileFarmCard/FarmerProfileFarmCard';
import { FarmerProfileCompanyCard } from '@features/farmer/profile/components/FarmerProfileCompanyCard/FarmerProfileCompanyCard';
import { FarmerDashboardCard } from '@features/farmer/profile/components/FarmerDashboard/FarmerDashboardCard';
import { FarmerProfileTopNav } from '@features/farmer/profile/components/FarmerProfileTopNav/FarmerProfileTopNav';
export const FarmerProfilePage = () => {
    const { classes } = useFarmerProfilePageStyles();
    return (_jsxs("div", Object.assign({ className: classes.container }, { children: [_jsx(FarmerProfileTopNav, {}), _jsxs("div", Object.assign({ className: classes.farmerProfileSection }, { children: [_jsx(DeferredPaymentBanner, {}), _jsx(FarmerProfileHeaderCard, {}), _jsxs(Grid, Object.assign({ mt: "0px", container: true, spacing: 4 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 6 }, { children: [_jsx(FarmerProfileAboutCard, {}), _jsx(Box, Object.assign({ mt: 4 }, { children: _jsx(FarmerProfileFarmCard, {}) })), _jsx(Box, Object.assign({ mt: 4 }, { children: _jsx(FarmerProfileCompanyCard, {}) }))] })), _jsx(Grid, Object.assign({ item: true, xs: 6 }, { children: _jsx(FarmerDashboardCard, {}) }))] }))] }))] })));
};
