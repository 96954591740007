import { jsx as _jsx } from "react/jsx-runtime";
import { Box, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
export const SearchInput = ({ defaultValue = '', placeholder, onChange, onReset }) => {
    const [value, setValue] = useState(defaultValue);
    const handleChange = (e) => {
        setValue(e.target.value);
        onChange(e.target.value);
    };
    const handleReset = () => {
        setValue('');
        onReset === null || onReset === void 0 ? void 0 : onReset();
    };
    return (_jsx(TextField, { sx: { width: 320 }, value: value, onChange: handleChange, inputProps: {
            style: { paddingLeft: 16 },
        }, InputProps: {
            placeholder,
            startAdornment: value ? (_jsx(CloseIcon, { onClick: handleReset, sx: { '&:hover': { cursor: 'pointer' }, width: 16 }, color: "secondary" })) : (_jsx(Box, { sx: { width: 16 } })),
            endAdornment: _jsx(SearchIcon, { color: "secondary" }),
        } }));
};
