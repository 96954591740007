import { makeStyles } from 'tss-react/mui';
const useValidationFieldStyle = makeStyles()((theme) => ({
    ruleRow: {
        display: 'flex',
        justifyItems: 'center',
        '&>:first-child': {
            marginRight: '10px',
            fontSize: '15px',
        },
    },
    successColor: {
        color: theme.palette.success.main,
    },
}));
export default useValidationFieldStyle;
