import { api } from '@services';
export var PermissionsEnum;
(function (PermissionsEnum) {
    // ACCESS
    PermissionsEnum["PP_ACCESS_TO_PLATFORM"] = "PP_ACCESS_TO_PLATFORM";
    PermissionsEnum["PP_ACCESS_TO_PLATFORM_AS_PARTNER"] = "PP_ACCESS_TO_PLATFORM_AS_PARTNER";
    PermissionsEnum["PP_ACCESS_TO_PLATFORM_AS_ASSOCIATE"] = "PP_ACCESS_TO_PLATFORM_AS_ASSOCIATE";
    PermissionsEnum["FP_ACCESS_TO_PLATFORM"] = "FP_ACCESS_TO_PLATFORM";
    PermissionsEnum["PP_CAN_SEE_DASHBOARD"] = "PP_CAN_SEE_DASHBOARD";
    // FARMERS
    PermissionsEnum["PP_CAN_SEE_ALL_PARTNER_FARMERS"] = "PP_CAN_SEE_ALL_PARTNER_FARMERS";
    PermissionsEnum["PP_CAN_SEE_ASSIGNED_PARTNER_FARMERS"] = "PP_CAN_SEE_ASSIGNED_PARTNER_FARMERS";
    PermissionsEnum["PP_CAN_SEE_ASSOCIATE_FARMERS"] = "PP_CAN_SEE_ASSOCIATE_FARMERS";
    PermissionsEnum["PP_CAN_SEE_ASSOCIATE_ASSIGNED_FARMERS"] = "PP_CAN_SEE_ASSOCIATE_ASSIGNED_FARMERS";
    PermissionsEnum["PP_CAN_EDIT_FARMER"] = "PP_CAN_EDIT_FARMER";
    PermissionsEnum["PP_CAN_EDIT_ALL_FARMERS"] = "PP_CAN_EDIT_ALL_FARMERS";
    PermissionsEnum["PP_CAN_INVITE_PARTNER_FARMERS"] = "PP_CAN_INVITE_PARTNER_FARMERS";
    PermissionsEnum["PP_CAN_VALIDATE_ALL_RESULTS"] = "PP_CAN_VALIDATE_ALL_RESULTS";
    PermissionsEnum["PP_CAN_VALIDATE_ASSIGNED_FARMERS_RESULTS"] = "PP_CAN_VALIDATE_ASSIGNED_FARMERS_RESULTS";
    PermissionsEnum["FP_CAN_VISIT_ASSOCIATE_FARM"] = "FP_CAN_VISIT_ASSOCIATE_FARM";
    PermissionsEnum["FP_CAN_VISIT_PARTNER_FARM"] = "FP_CAN_VISIT_PARTNER_FARM";
    PermissionsEnum["FP_CAN_VISIT_ASSIGNED_ASSOCIATE_FARM"] = "FP_CAN_VISIT_ASSIGNED-ASSOCIATE_FARM";
    PermissionsEnum["FP_CAN_VISIT_ASSIGNED_PARTNER_FARM"] = "FP_CAN_VISIT_ASSIGNED-PARTNER_FARM";
    PermissionsEnum["FP_CAN_FINALIZE_ALL_FARM_SEASON"] = "FP_CAN_FINALIZE_ALL_FARM-SEASON";
    PermissionsEnum["FP_CAN_FINALIZE_ASSIGNED_ASSOCIATE_FARM_SEASON"] = "FP_CAN_FINALIZE_ASSIGNED-ASSOCIATE_FARM-SEASON";
    PermissionsEnum["FP_CAN_FINALIZE_ASSIGNED_PARTNER_FARM_SEASON"] = "FP_CAN_FINALIZE_ASSIGNED-PARTNER_FARM-SEASON";
    PermissionsEnum["FP_CAN_FINALIZE_PARTNER_FARM_SEASON"] = "FP_CAN_FINALIZE_PARTNER_FARM-SEASON";
    PermissionsEnum["PP_CAN_ASSIGN_ASSOCIATE_TO_FARMER"] = "PP_CAN_ASSIGN_ASSOCIATE_TO_FARMER";
    PermissionsEnum["PP_CAN_DELETE_ALL_FARMERS"] = "PP_CAN_DELETE_ALL_FARMERS";
    PermissionsEnum["PP_CAN_SEE_ALARMING_DATA"] = "PP_CAN_SEE_ALARMING_DATA";
    PermissionsEnum["PP_CAN_SEE_ASSIGNED_ALARMING_DATA"] = "PP_CAN_SEE_ASSIGNED_ALARMING_DATA";
    PermissionsEnum["PP_CAN_ASSOCIATE_DEFER_PAYMENT"] = "PP_CAN_ASSOCIATE_DEFER_PAYMENT";
    PermissionsEnum["PP_CAN_ASSOCIATE_DEFER_PAYMENT_ASSIGNED_FARMERS"] = "PP_CAN_ASSOCIATE_DEFER_PAYMENT_ASSIGNED_FARMERS";
    PermissionsEnum["FP_CAN_EDIT_ALL_FARM_SEASON"] = "FP_CAN_EDIT_ALL_FARM-SEASON";
    // MEMBERS
    PermissionsEnum["PP_CAN_SEE_PARTNER_MEMBERS"] = "PP_CAN_SEE_PARTNER_MEMBERS";
    PermissionsEnum["PP_CAN_SEE_ASSOCIATE_MEMBERS"] = "PP_CAN_SEE_ASSOCIATE_MEMBERS";
    PermissionsEnum["PP_CAN_BE_ASSIGNED_BY_PARTNER"] = "PP_CAN_BE_ASSIGNED_BY_PARTNER";
    PermissionsEnum["PP_CAN_BE_ASSIGNED_BY_ASSOCIATE"] = "PP_CAN_BE_ASSIGNED_BY_ASSOCIATE";
    PermissionsEnum["PP_CAN_DELETE_MEMBER"] = "PP_CAN_DELETE_MEMBER";
    PermissionsEnum["PP_CAN_DELETE_ALL_MEMBERS"] = "PP_CAN_DELETE_ALL_MEMBERS";
    PermissionsEnum["PP_CAN_EDIT_MEMBER"] = "PP_CAN_EDIT_MEMBER";
    PermissionsEnum["PP_CAN_EDIT_ALL_MEMBERS"] = "PP_CAN_EDIT_ALL_MEMBERS";
    PermissionsEnum["PP_CAN_ASSIGN_PARTNER_INVITATION"] = "PP_CAN_ASSIGN_PARTNER_INVITATION";
    PermissionsEnum["PP_CAN_INVITE_PARTNER_MEMBERS"] = "PP_CAN_INVITE_PARTNER_MEMBERS";
    PermissionsEnum["PP_CAN_SEE_ASSIGNED_PARTNER_MEMBERS"] = "PP_CAN_SEE_ASSIGNED_PARTNER_MEMBERS";
    PermissionsEnum["PP_CAN_SEE_PARTNER_ALARMING_DATA"] = "PP_CAN_SEE_PARTNER_ALARMING_DATA";
    PermissionsEnum["PP_CAN_SEE_ASSIGNED_PARTNER_ALARMING_DATA"] = "PP_CAN_SEE_ASSIGNED_PARTNER_ALARMING_DATA";
})(PermissionsEnum || (PermissionsEnum = {}));
export const permissionApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getPermissions: builder.query({
            query: () => ({
                url: `/private/permissions`,
                method: 'GET',
            }),
        }),
    }),
});
export const { useGetPermissionsQuery, useLazyGetPermissionsQuery } = permissionApi;
