var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useAuth } from '@hooks/useAuth';
import { MenuItem, Skeleton } from '@mui/material';
import { useGetSupportedLanguagesQuery } from '@services/language.api';
import { useUpdateUserMutation } from '@services/user.api';
import { Select } from '@components/Select';
import { useTranslation } from 'react-i18next';
const LanguageSelector = () => {
    const { i18n, t } = useTranslation();
    const { data: languages = [], isLoading: areLanguagesLoading } = useGetSupportedLanguagesQuery();
    const { user } = useAuth();
    const [updateUser] = useUpdateUserMutation();
    const onChange = (event) => __awaiter(void 0, void 0, void 0, function* () {
        yield i18n.changeLanguage(event.target.value);
        yield updateUser({
            body: {
                language_id: languages.find((l) => l.iso_code === event.target.value).id,
            },
            userId: user === null || user === void 0 ? void 0 : user.id,
        });
    });
    if (areLanguagesLoading)
        return _jsx(Skeleton, { animation: "wave" });
    return (_jsx(Select, Object.assign({ value: i18n.language, autoWidth: true, onChange: onChange }, { children: languages === null || languages === void 0 ? void 0 : languages.map((language) => (_jsx(MenuItem, Object.assign({ value: language.iso_code, disableRipple: true }, { children: t(`values.languages.${language.iso_code}`) }), language.id))) })));
};
export default LanguageSelector;
