import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { Logo } from '@components/Logo';
import ProfileMenu from './ProfileMenu';
import useTopBarStyle from './TopBar.style';
import Notification from './Notification';
function TobBar({ className }) {
    const { classes } = useTopBarStyle();
    return (_jsxs("div", Object.assign({ className: clsx(className, classes.container) }, { children: [_jsx(Logo, {}), _jsxs("div", Object.assign({ className: classes.menuContainer }, { children: [_jsx(ProfileMenu, {}), _jsx(Notification, {})] }))] })));
}
export default TobBar;
