import { makeStyles } from 'tss-react/mui';
export const useModalStyles = makeStyles()((theme) => ({
    background: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
        backgroundColor: '#f6f3E5',
        borderRadius: '15px',
        padding: theme.spacing(6),
    },
    contentContainer: {
        position: 'relative',
        height: 'fit-content',
        maxHeight: '80vh',
        overflow: 'hidden',
    },
    title: {
        marginBottom: theme.spacing(6),
    },
    closeIcon: {
        width: 12,
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(5),
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
