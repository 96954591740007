import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InputChips } from '@components/Form/InputChips/InputChips';
import { SelectRoles } from '@components/SelectRoles/SelectRoles';
import { useInviteMembersModalStyles } from '@features/invite/member/Modal/InviteMembersModal.style';
import { useCreateValidationSchema } from '@features/invite/member/Modal/useInviteMembersFormData';
import { Box } from '@mui/material';
import { useGetAllMembersPendingInvitationsQuery } from '@services/invitation.api';
import { RequiredInputLabel } from '@components/RequiredInputLabel';
import { getHookFormErrorMessage } from '@utils/string';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetAssignableRoles } from '@hooks/useGetAssignableRoles';
export const SelectMemberEmailsForm = () => {
    const { t } = useTranslation();
    const { classes } = useInviteMembersModalStyles();
    const { emailValidationSchema } = useCreateValidationSchema(t);
    const { data: pendingMembers } = useGetAllMembersPendingInvitationsQuery();
    const { data: roles } = useGetAssignableRoles();
    const { setValue, watch, setError, clearErrors, formState: { errors }, } = useFormContext();
    const roleId = watch('roleId');
    const emailError = getHookFormErrorMessage(errors === null || errors === void 0 ? void 0 : errors.emails);
    const handleInputChange = () => clearErrors('emails');
    const handleEmailsChange = (chips) => setValue('emails', chips.map((chip) => chip.value));
    const handleEmailDeleted = (chip) => {
        const currentEmails = watch('emails');
        const updatedEmails = currentEmails === null || currentEmails === void 0 ? void 0 : currentEmails.filter((email) => email !== chip.value);
        setValue('emails', updatedEmails);
    };
    const handleReset = () => setValue('emails', []);
    const isChipValueCorrect = (value) => {
        try {
            emailValidationSchema.validateSync(value);
            const isAlreadyInvited = !!(pendingMembers === null || pendingMembers === void 0 ? void 0 : pendingMembers.find((member) => member.email === value));
            if (isAlreadyInvited) {
                setError('emails', { message: t('members.invite.errors.member-already-invited') });
            }
            return !isAlreadyInvited;
        }
        catch (error) {
            setError('emails', { message: error === null || error === void 0 ? void 0 : error.message });
            return false;
        }
    };
    const handleRoleChanged = (role) => setValue('roleId', role.id);
    if (!pendingMembers || !roles || !roleId)
        return _jsx(_Fragment, {});
    return (_jsxs(_Fragment, { children: [_jsxs(Box, Object.assign({ sx: { mb: 4 } }, { children: [_jsx(RequiredInputLabel, Object.assign({ required: true, className: classes.inputTitle }, { children: t('labels.role') })), _jsx(SelectRoles, { roleId: roleId, roles: roles, onChange: handleRoleChanged })] })), _jsx(RequiredInputLabel, Object.assign({ required: true, className: classes.inputTitle }, { children: t('members.invite.recipients') })), _jsx(InputChips, { fullWidth: true, onChange: handleEmailsChange, onInputChange: handleInputChange, error: !!(errors === null || errors === void 0 ? void 0 : errors.emails), helperText: emailError, validate: isChipValueCorrect, onChipDelete: handleEmailDeleted, onReset: handleReset, placeholder: t('members.invite.add-emails'), maxChips: 2, remainingChipsLabel: t('common.others') })] }));
};
