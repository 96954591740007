import { jsx as _jsx } from "react/jsx-runtime";
import { Avatar } from '@components/Avatar';
import { useGetFarmers } from '@features/farmer/listFarmers/useGetFarmers';
import { AvatarGroup, Tooltip } from '@mui/material';
import { FARMER_STATUS } from '@utils/constants';
import { getAvatarInitials } from '@utils/string';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFarmersTableStyles } from './FarmersTable.style';
export const useFarmersTableData = () => {
    const { t } = useTranslation();
    const { classes, cx } = useFarmersTableStyles();
    const { data: rows, count, isLoading, isFetching } = useGetFarmers({ shouldFilter: true });
    const getRowId = (row) => ((row === null || row === void 0 ? void 0 : row.user_id) ? Number(row === null || row === void 0 ? void 0 : row.user_id) : Number(row === null || row === void 0 ? void 0 : row.invitation_id));
    const renderCellStylesByStatus = (params) => {
        const shouldApplyPendingStyles = params.row.status === FARMER_STATUS.PENDING;
        const shouldApplyRefusedStyles = params.row.status === FARMER_STATUS.INVITATION_REFUSED;
        return (_jsx("span", Object.assign({ className: cx(classes.row, shouldApplyPendingStyles && classes.pending, shouldApplyRefusedStyles && classes.refused) }, { children: params.value })));
    };
    const sharedColumnProps = useMemo(() => ({
        sortable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
    }), []);
    const columns = useMemo(() => [
        Object.assign(Object.assign({ field: 'full_name', headerName: t('teams.grid.columns.full-name'), minWidth: 250 }, sharedColumnProps), { valueGetter: ({ row }) => row.first_name && row.last_name ? `${row.first_name} ${row.last_name}` : '-', renderCell: renderCellStylesByStatus }),
        Object.assign(Object.assign({ field: 'email', headerName: t('teams.grid.columns.email'), width: 250 }, sharedColumnProps), { valueGetter: ({ row }) => row.email, renderCell: (params) => {
                return (_jsx(Tooltip, Object.assign({ title: params.value, placement: "right", arrow: true }, { children: renderCellStylesByStatus(params) })));
            } }),
        Object.assign(Object.assign({ field: 'cohort', headerName: t('teams.grid.columns.cohort'), minWidth: 25, headerAlign: 'center', cellClassName: 'centeredCell' }, sharedColumnProps), { valueGetter: ({ row }) => row.cohort, renderCell: renderCellStylesByStatus }),
        Object.assign(Object.assign({ field: 'contact', headerName: t('teams.grid.columns.contact'), minWidth: 100, headerAlign: 'center', cellClassName: 'centeredCell' }, sharedColumnProps), { valueGetter: ({ row }) => row.assigned_to, renderCell: (params) => {
                var _a;
                return (_jsx(AvatarGroup, Object.assign({ className: classes.avatarGroup, max: 3 }, { children: (_a = params.value) === null || _a === void 0 ? void 0 : _a.map((contact) => (_jsx(Avatar, Object.assign({ className: classes.avatar, alt: `${contact.first_name} ${contact.last_name}` }, { children: _jsx(Tooltip, Object.assign({ title: contact.email, placement: "top", arrow: true }, { children: _jsx("div", { children: getAvatarInitials(contact.first_name, contact.last_name) }) })) }), Number(contact.id)))) })));
            } }),
        Object.assign(Object.assign({ field: 'status', headerName: t('teams.grid.columns.status'), minWidth: 300 }, sharedColumnProps), { valueGetter: ({ row }) => t(`statuses.${row.status}`), renderCell: renderCellStylesByStatus }),
    ], [t, sharedColumnProps]);
    return { columns, rows, count, getRowId, isLoading, isFetching };
};
