import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import useDashboardStepStyles from './DashboardStep.style';
export const DashboardStep = ({ stepStatus: stepStatus, icon: icon, by }) => {
    const { classes } = useDashboardStepStyles();
    const { t } = useTranslation();
    return (_jsxs(Box, Object.assign({ className: clsx(classes.statisticContainer, !stepStatus.isActive && classes.inactiveStatisticContainer) }, { children: [_jsxs(Box, Object.assign({ className: classes.leftCaption }, { children: [icon, _jsx(Typography, Object.assign({ className: stepStatus.isActive ? classes.label : classes.greyLabel, variant: "caption" }, { children: stepStatus.label }))] })), _jsxs(Box, Object.assign({ className: classes.stepProgressContainer }, { children: [_jsx(Typography, Object.assign({ className: stepStatus.isActive ? classes.statistic : classes.greyStatistic, variant: "caption", alignSelf: 'flex-end' }, { children: stepStatus.value })), by && _jsx(Typography, Object.assign({ variant: "body2" }, { children: `${t('constants.by')} ${by}` }))] }))] })));
};
