import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@mui/icons-material/Warning';
import useErrorCardStyle from './ErrorCard.style';
import { useNavigate } from 'react-router-dom';
import logoSoilcapitalSrc from '@images/logo-soilcapital.png';
import { ROUTES } from '@router/routes';
import clsx from 'clsx';
const ErrorCard = ({ className, buttonText, buttonUrl = ROUTES.HOME, errorTitle, errorDescription, }) => {
    const { t } = useTranslation();
    const { classes } = useErrorCardStyle();
    const navigate = useNavigate();
    return (_jsxs(Paper, Object.assign({ className: clsx(classes.container, className) }, { children: [_jsx("img", { alt: "Logo", src: logoSoilcapitalSrc, style: { maxWidth: '140px', height: '38px' } }), _jsx(Box, Object.assign({ className: classes.iconContainer }, { children: _jsx(WarningIcon, { className: classes.icon }) })), _jsx("div", Object.assign({ className: classes.content }, { children: _jsxs("div", Object.assign({ className: classes.contentGroup }, { children: [_jsx("div", Object.assign({ className: classes.titleContainer }, { children: _jsx(Typography, Object.assign({ align: "center", variant: "h4" }, { children: errorTitle || t('errors.server-error') })) })), _jsx(Typography, Object.assign({ align: "center", variant: "body2" }, { children: errorDescription || t('errors.error-occurred-while-fetching') }))] })) })), _jsx(Box, Object.assign({ className: classes.buttonContainer }, { children: _jsx(Button, Object.assign({ color: "primary", onClick: () => navigate(buttonUrl) }, { children: buttonText || t('common.home') })) }))] })));
};
export default ErrorCard;
