var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Select } from '@components/Select';
import { Box, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
export const SelectRoles = (_a) => {
    var { roleId, roles, onChange } = _a, rest = __rest(_a, ["roleId", "roles", "onChange"]);
    const { t } = useTranslation();
    const computedRoles = roles.map((r) => (Object.assign(Object.assign({}, r), { name: t(r.translation_slug) })));
    const getRoleLabelById = (id) => { var _a; return (_a = computedRoles === null || computedRoles === void 0 ? void 0 : computedRoles.find((r) => r.id === id)) === null || _a === void 0 ? void 0 : _a.name; };
    const handleLanguageChange = (e) => {
        const roleId = Number(e.target.value);
        const selectedRole = computedRoles === null || computedRoles === void 0 ? void 0 : computedRoles.find((r) => r.id === roleId);
        if (selectedRole)
            onChange(selectedRole);
    };
    if (!roles)
        return _jsx(_Fragment, {});
    return (_jsx(Select, Object.assign({ "data-testid": "roles-select", value: `${roleId}`, onChange: handleLanguageChange, renderValue: (selected) => _jsx(Box, { children: getRoleLabelById(Number(selected)) }) }, rest, { children: computedRoles === null || computedRoles === void 0 ? void 0 : computedRoles.map((role) => (_jsx(MenuItem, Object.assign({ value: role.id }, { children: role.name }), role.id))) })));
};
