export const ErrorCodes = {
    UserNotInTeam: { message: 'User is not a member of the team', code: 'USER_NOT_IN_TEAM' },
    MemberNotInTeam: { message: 'Member is not part of the team', code: 'MEMBER_NOT_IN_TEAM' },
    MemberNotInPartner: { message: 'Member is not part of the partner', code: 'MEMBER_NOT_IN_PARTNER' },
    FarmerNotInPartner: { message: 'Farmer is not part of the partner', code: 'FARMER_NOT_IN_PARTNER' },
    FarmerNotInTeam: { message: 'Farmer is not part of one of your teams', code: 'FARMER_NOT_IN_TEAM' },
    FarmNotInPartner: { message: 'Farm is not part of the partner', code: 'FARM_NOT_IN_PARTNER' },
    FarmNotInTeam: { message: 'Farm is not part of one of your teams', code: 'FARM_NOT_IN_TEAM' },
    InvitationNotInPartner: { message: 'Invitation is not part of the partner', code: 'INVITATION_NOT_IN_PARTNER' },
    InvitationNotInTeam: { message: 'Invitation is not part of one of your teams', code: 'INVITATION_NOT_IN_TEAM' },
    UnauthorizedAction: { message: 'Unauthorized action', code: 'UNAUTHORIZED_ACTION' },
    TeamNotInPartner: { message: 'Team is not part of your partner', code: 'TEAM_NOT_IN_PARTNER' },
    CompanyNotInPartner: { message: 'Company is not part of the partner', code: 'COMPANY_NOT_IN_PARTNER' },
    CompanyNotInTeam: { message: 'Company is not part of the team', code: 'COMPANY_NOT_IN_TEAM' },
    InvalidInvitationToken: { message: 'Invalid invitation token', code: 'INVALID_INVITATION_TOKEN' },
    FarmAlreadyLinkedToPartner: {
        message: 'User already has a farm linked to a partner',
        code: 'FARM_ALREADY_LINKED_TO_PARTNER',
    },
    InvitationTokenAlreadyUsed: { message: 'Invitation already accepted!', code: 'INVITATION_TOKEN_USED' },
    RegistrationFailure: { message: 'Register has failed', code: 'REGISTER_FAILED' },
    PartnerUserNotFound: { message: 'User not found', code: 'PARTNER_USER_NOT_FOUND' },
    InvitationConfigurationAlreadExists: {
        message: 'Invitation configuration already exists. Update it instead.',
        code: 'INVITATION_CONFIGURATION_ALREADY_EXISTS',
    },
    EmailNotInWhitelist: { message: 'Email not in whitelist', code: 'EMAIL_NOT_IN_WHITELIST' },
};
