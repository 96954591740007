import { jsx as _jsx } from "react/jsx-runtime";
import { useAppInit } from '@hooks/useAppInit';
import { Router } from '@router';
import LoadingPage from './pages/LoadingPage/LoadingPage';
import { useIntercom } from 'react-use-intercom';
import { useAuth } from '@hooks/useAuth';
import { useEffect } from 'react';
import i18n from 'i18next';
import useHotjar from '@hooks/useHotjar';
const App = () => {
    const { initialized } = useAppInit();
    const { user } = useAuth();
    const { update: intercomUpdate } = useIntercom();
    useHotjar();
    useEffect(() => {
        intercomUpdate({
            userId: user === null || user === void 0 ? void 0 : user.id.toString(),
            email: user === null || user === void 0 ? void 0 : user.email,
            name: `${user === null || user === void 0 ? void 0 : user.first_name} ${user === null || user === void 0 ? void 0 : user.last_name}`,
            languageOverride: i18n === null || i18n === void 0 ? void 0 : i18n.language,
            customAttributes: {
                userId_Hubspot: user === null || user === void 0 ? void 0 : user.hubspot_id,
            },
            hideDefaultLauncher: true,
        });
    }, [user, intercomUpdate]);
    return initialized ? _jsx(Router, {}) : _jsx(LoadingPage, {});
};
export default App;
