import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LogoutButton } from '@features/auth';
import SettingsButton from '@features/settings/SettingsButton/SettingsButton';
import { useAuth } from '@hooks/useAuth';
import { Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import packageInfo from '../../../package.json';
import LanguageSelector from '@features/translation/LanguageSelector';
import useProfileMenuStyle from './ProfileMenu.style';
import { Avatar } from '@components/Avatar';
function ProfileMenu() {
    const { classes } = useProfileMenuStyle();
    const [anchorEl, setAnchorEl] = useState();
    const { user } = useAuth();
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const openProfileMenu = () => {
        const menuAnchor = document.querySelector('#nav-avatar-menu-anchor');
        setAnchorEl(menuAnchor);
    };
    const closeAvatarMenu = () => setAnchorEl(null);
    const openGeneralConditions = () => {
        if (i18n.language !== 'fr') {
            window.open('/general_conditions_en.pdf', '_blank');
        }
        else {
            window.open('/general_conditions_fr.pdf', '_blank');
        }
    };
    return (_jsxs("div", Object.assign({ className: classes.container }, { children: [_jsxs("div", Object.assign({ id: "nav-avatar-wrapper" }, { children: [_jsx(Avatar, Object.assign({ className: classes.avatar, onClick: openProfileMenu }, { children: user ? (user.first_name.charAt(0) + user.last_name.charAt(0)).toUpperCase() : '' })), _jsx("span", { id: "nav-avatar-menu-anchor" })] })), _jsxs(Menu, Object.assign({ PaperProps: { style: { marginTop: '11px' } }, anchorEl: anchorEl, anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, className: classes.avatarMenu, id: "simple-menu", open: Boolean(anchorEl), transformOrigin: { vertical: 'top', horizontal: 'left' }, disableAutoFocusItem: true, keepMounted: true, onClose: closeAvatarMenu }, { children: [_jsx(MenuItem, Object.assign({ className: classes.avatarMenuEmail, disableRipple: true }, { children: user === null || user === void 0 ? void 0 : user.email })), _jsx("hr", { className: classes.emailSeparation }), _jsxs(MenuItem, Object.assign({ className: classes.avatarMenuLanguage, disableRipple: true }, { children: [_jsxs(Typography, Object.assign({ className: classes.avatarMenuLanguageText }, { children: [t('labels-language'), " : "] })), _jsx(LanguageSelector, {})] })), _jsx(MenuItem, Object.assign({ className: classes.avatarMenuItem, disableRipple: true }, { children: _jsx(SettingsButton, {}) })), _jsx(MenuItem, Object.assign({ className: classes.avatarMenuItem, disableRipple: true }, { children: _jsx(LogoutButton, {}) })), _jsx("hr", { className: classes.emailSeparation }), _jsx(Typography, Object.assign({ className: classes.tcsLink, color: "primary", variant: "body1", textAlign: 'center', 
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onClick: () => openGeneralConditions() }, { children: t('labels.terms-of-use') })), _jsx(MenuItem, Object.assign({ className: classes.appVersion }, { children: _jsxs(Typography, Object.assign({ component: "div", variant: "body1" }, { children: [t('labels.version'), ": ", packageInfo.version] })) }))] })), _jsxs(Typography, Object.assign({ className: classes.typography, color: "primary", variant: "caption", onClick: openProfileMenu }, { children: [user === null || user === void 0 ? void 0 : user.first_name, " ", user === null || user === void 0 ? void 0 : user.last_name] }))] })));
}
export default ProfileMenu;
