var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { LabelPasswordField } from '@components/LabelPasswordField';
import { ValidationField } from '@components/ValidationField';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, CircularProgress, Snackbar, Typography } from '@mui/material';
import { useChangePasswordMutation } from '@services';
import { Colors, PasswordRules } from '@utils/config';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import useChangePasswordStyle from './ChangePassword.style';
export const ChangePassword = () => {
    var _a, _b, _c;
    const { classes } = useChangePasswordStyle();
    const { t } = useTranslation();
    const [changePassword, { isLoading }] = useChangePasswordMutation();
    const [showPasswordValidations, setShowPasswordValidations] = useState(false);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [passwordRules, setPasswordRules] = useState([
        {
            isValid: ''.length >= PasswordRules.minLength,
            text: t('validations.password-must-be-at-least-x-characters-short', { min: PasswordRules.minLength }),
        },
        {
            isValid: PasswordRules.digitMatch.test(''),
            text: t('validations.password-must-at-least-contain-one-figure'),
        },
        {
            isValid: PasswordRules.specialCharactersMatch.test(''),
            text: t('validations.password-must-at-least-contain-one-special-character'),
        },
    ]);
    const ChangePasswordFormSchema = yup.object({
        old_password: yup.string().required(t('validations.old-password-required')),
        new_password: yup
            .string()
            .required(t('validations.password-required'))
            .min(8, t('validations.password-must-be-at-least-x-characters-short', { min: 8 }))
            .matches(/^(?=.*[0-9])/, t('validations.password-must-at-least-contain-one-figure'))
            .matches(/^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/, t('validations.password-must-at-least-contain-one-special-character')),
        new_password_confirmation: yup
            .string()
            .oneOf([yup.ref('new_password')], t('validations.password-must-match'))
            .required(t('validations.confirm-password-required')),
    });
    const { register, handleSubmit, formState: { errors }, } = useForm({
        reValidateMode: 'onChange',
        resolver: yupResolver(ChangePasswordFormSchema),
    });
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield changePassword(data).unwrap();
            setNotificationOpen(true);
        }
        catch (err) {
            if (err.message) {
                enqueueSnackbar(t(`responses.${err.message}`), { variant: 'error' });
                return;
            }
            enqueueSnackbar(t('errors.server-error'), { variant: 'error' });
        }
    });
    return (_jsxs(_Fragment, { children: [_jsxs("div", { children: [_jsx(Typography, Object.assign({ variant: "h3", className: classes.subtitle, color: Colors.newGrey }, { children: t('subtitles.change-password') })), _jsx("div", Object.assign({ className: classes.descriptionContainer }, { children: _jsx(Typography, Object.assign({ className: classes.description, variant: "subtitle2", color: Colors.newGrey }, { children: t('app.profile.change-password-description') })) })), _jsxs("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx(LabelPasswordField, { InputProps: Object.assign({}, register('old_password', {
                                    required: true,
                                })), autoComplete: "first_name", disabled: isLoading, error: errors.old_password !== undefined, helperText: (_a = errors.old_password) === null || _a === void 0 ? void 0 : _a.message, label: t('labels.old-password'), variant: "outlined", fullWidth: true }), _jsx(ValidationField, Object.assign({ open: showPasswordValidations, rules: passwordRules }, { children: _jsx(LabelPasswordField, { InputProps: Object.assign(Object.assign({}, register('new_password', {
                                        required: true,
                                    })), { onBlur: (event) => {
                                            setShowPasswordValidations(false);
                                            register('new_password').onBlur(event);
                                        }, onChange: (event) => {
                                            const target = event.target;
                                            setPasswordRules([
                                                {
                                                    isValid: target.value.length >= PasswordRules.minLength,
                                                    text: t('validations.password-must-be-at-least-x-characters-short', {
                                                        min: PasswordRules.minLength,
                                                    }),
                                                },
                                                {
                                                    isValid: PasswordRules.digitMatch.test(target.value),
                                                    text: t('validations.password-must-at-least-contain-one-figure'),
                                                },
                                                {
                                                    isValid: PasswordRules.specialCharactersMatch.test(target.value),
                                                    text: t('validations.password-must-at-least-contain-one-special-character'),
                                                },
                                            ]);
                                            register('new_password').onChange(event);
                                        } }), autoComplete: "password", disabled: isLoading, error: errors.new_password !== undefined, helperText: (_b = errors.new_password) === null || _b === void 0 ? void 0 : _b.message, label: t('labels.new-password'), variant: "outlined", onFocus: () => {
                                        setShowPasswordValidations(true);
                                    }, fullWidth: true }) })), _jsx(LabelPasswordField, { InputProps: Object.assign({}, register('new_password_confirmation', {
                                    required: true,
                                })), autoComplete: "password", disabled: isLoading, error: errors.new_password_confirmation !== undefined, helperText: (_c = errors.new_password_confirmation) === null || _c === void 0 ? void 0 : _c.message, label: t('labels.confirm-password'), variant: "outlined", fullWidth: true }), _jsx(Button, Object.assign({ type: "submit", variant: "contained", disableElevation: true, fullWidth: true, className: classes.changeBtn }, { children: isLoading ? _jsx(CircularProgress, { size: 20 }) : t('buttons.change-password') }))] }))] }), _jsx(Snackbar, Object.assign({ open: notificationOpen, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, className: classes.notificationContainer }, { children: _jsx(Alert, Object.assign({ severity: "success", sx: { width: '100%' }, icon: false, variant: "filled", onClose: () => {
                        setNotificationOpen(false);
                    }, className: classes.notification }, { children: t('messages.password-changed') })) }))] }));
};
