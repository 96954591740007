import { usePermission } from '@hooks/usePermission';
import { ApiToggle, useGetMembersQuery } from '@services';
import { skipToken } from '@reduxjs/toolkit/query';
export const useGetMembers = () => {
    const { canSeePartnerMembers, canSeeAssociateMembers } = usePermission();
    const params = canSeePartnerMembers
        ? { toggle: ApiToggle.PARTNER }
        : canSeeAssociateMembers
            ? { toggle: ApiToggle.ASSOCIATE }
            : skipToken;
    const { data, isLoading, isFetching, isError } = useGetMembersQuery(params);
    return {
        data,
        isLoading,
        isFetching,
        isError,
    };
};
