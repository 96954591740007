import { CARBON_RESULT_MODEL } from '@utils/constants';
import { useCallback, useEffect, useState } from 'react';
import { useRenewalStatus } from '@features/farmer/profile/farmSeasonStatus/RenewalStatus/useRenewalStatus';
import { useInitialSetupStatus } from '@features/farmer/profile/farmSeasonStatus/InitialSetupStatus/useInitialSetupStatus';
import { useOperationalDataStatus } from '@features/farmer/profile/farmSeasonStatus/OperationalDataStatus/useOperationalDataStatus';
import { useDataValidationStatus } from '@features/farmer/profile/farmSeasonStatus/DataValidationStatus/useDataValidationStatus';
import { useResultStatus } from '@features/farmer/profile/farmSeasonStatus/ResultStatus/useResultStatus';
export const PROFILE_STEPS = ['RENEWAL', 'INITIAL_SETUP', 'OPERATIONAL_DATA', 'DATA_VALIDATION', 'RESULTS_VALIDATION'];
export const useFarmSeasonSteps = (props) => {
    const { hasRenewed } = useRenewalStatus(props);
    const { hasValidatedInitialSetup, isInitialSetupInProgress } = useInitialSetupStatus(props);
    const { hasValidatedOperations, areOperationsInProgress } = useOperationalDataStatus(props);
    const { hasValidatedData } = useDataValidationStatus(props);
    const { areResultsValidatedByAgronomist } = useResultStatus(props);
    const [currentStep, setCurrentStep] = useState(0);
    const getCurrentStep = useCallback(() => {
        let currentStep = hasRenewed ? 1 : 0;
        if (hasValidatedInitialSetup || isInitialSetupInProgress)
            currentStep++;
        if (props.carbonModel === CARBON_RESULT_MODEL.CFT && (hasValidatedOperations || areOperationsInProgress))
            currentStep++;
        if (hasValidatedData)
            currentStep++;
        if (areResultsValidatedByAgronomist)
            currentStep++;
        return currentStep;
    }, [
        props.carbonModel,
        hasRenewed,
        hasValidatedInitialSetup,
        isInitialSetupInProgress,
        hasValidatedOperations,
        areOperationsInProgress,
        hasValidatedData,
        areResultsValidatedByAgronomist,
    ]);
    useEffect(() => {
        setCurrentStep(getCurrentStep());
    }, [getCurrentStep]);
    let maxSteps = PROFILE_STEPS.length;
    if (!hasRenewed)
        maxSteps--;
    // OPERATIONAL_DATA step only for CFT
    if (props.carbonModel !== CARBON_RESULT_MODEL.CFT)
        maxSteps--;
    return {
        currentStep,
        maxSteps,
    };
};
