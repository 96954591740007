const tooltipCss = {
    tooltip: ({ theme }) => ({
        backgroundColor: theme.palette.primary.light,
        borderRadius: '8px',
        padding: '12px',
    }),
    arrow: ({ theme }) => ({
        color: theme.palette.primary.light,
    }),
};
const MuiTooltip = { styleOverrides: tooltipCss };
export default MuiTooltip;
