import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
export const supportedLngs = ['en', 'fr', 'nl'];
i18n.use(LanguageDetector)
    .use(HttpApi)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
    keySeparator: '.',
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
    load: 'languageOnly',
    fallbackLng: 'en',
    fallbackNS: 'common',
    ns: ['common', 'main'],
    defaultNS: 'main',
    supportedLngs,
    backend: {
        loadPath: `${window.location.origin}/translations/{{lng}}/{{ns}}.json`,
    },
});
export default i18n;
