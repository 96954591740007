import { makeStyles } from 'tss-react/mui';
export const useFarmerProfileFarmCardStyles = makeStyles()((theme) => {
    return {
        farmCard: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#f8f8f8',
            padding: '20px 30px 0 30px',
            boxShadow: 'none',
        },
        editFarmButton: {
            position: 'absolute',
            right: 20,
            top: 15,
        },
    };
});
