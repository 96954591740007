var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CohortFilter } from '@components/CohortFilter/CohortFilter';
import { RegionFilter } from '@components/RegionFilter/RegionFilter';
import { SeasonFilter } from '@features/dashboard/DashboardHeader/SeasonFilter/SeasonFilter';
import { changeCohort, changeRegion, selectCohortId, selectRegionCode } from '@features/dashboard/dashboardSlice';
import { PDFExportButtonText } from '@features/export/DashboardPdf';
import { useAppDispatch, useAppSelector } from '@hooks';
import { useRegionsByCountryView } from '@hooks/useRegionsByCountryView';
import { useSeasonView } from '@hooks/useSeasonView';
import { Box, Typography } from '@mui/material';
import { useTrackCohortFilterChangeMutation, useTrackGeographyFilterChangeMutation, useTrackHarvestFilterChangeMutation, } from '@services/segment.api';
import { useTranslation } from 'react-i18next';
import useDashboardHeaderStyle from './DashboardHeader.style';
export const DashboardHeader = ({ shadow = false }) => {
    const { t } = useTranslation();
    const { classes } = useDashboardHeaderStyle({ hasShadow: shadow });
    const selectedCohortId = useAppSelector(selectCohortId);
    const defaultCohortValue = selectedCohortId === null || selectedCohortId === void 0 ? void 0 : selectedCohortId.toString();
    const defaultRegionCode = useAppSelector(selectRegionCode);
    const dispatch = useAppDispatch();
    const [trackHarvestFilterChange] = useTrackHarvestFilterChangeMutation();
    const [trackGeographyFilterChange] = useTrackGeographyFilterChangeMutation();
    const [trackCohortFilterChange] = useTrackCohortFilterChangeMutation();
    const { isLoading: isLoadingSeasons, isError: isErrorSeasons } = useSeasonView();
    const { isLoading: isLoadingRegionsByCountry, isError: isErrorRegionsByCountry } = useRegionsByCountryView();
    const handleSeasonSelectChange = (seasonId, harvestFilter) => __awaiter(void 0, void 0, void 0, function* () {
        yield trackHarvestFilterChange({ season_id: seasonId, harvest_filter: harvestFilter });
    });
    const handleCohortSelectChange = (cohortFilter, cohortId) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(changeCohort({ cohortId, cohortFilter }));
        yield trackCohortFilterChange({ cohort_id: cohortId || null, cohort_filter: cohortFilter });
    });
    const handleRegionSelectChange = (regionFilter, regionCode) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(changeRegion({ regionCode, regionFilter }));
        yield trackGeographyFilterChange({ region_code: regionCode, region_filter: regionFilter });
    });
    const isLoading = isLoadingRegionsByCountry || isLoadingSeasons;
    const isError = isErrorRegionsByCountry || isErrorSeasons;
    if (isLoading || isError)
        return null;
    return (_jsxs(Box, Object.assign({ className: classes.contentContainer }, { children: [_jsx(Typography, Object.assign({ className: classes.title, variant: "body1", color: "secondary" }, { children: t('titles.dashboard') })), _jsxs("div", Object.assign({ className: classes.actionsContainer }, { children: [_jsxs(Box, Object.assign({ display: "flex", alignItems: "center", gap: 2 }, { children: [_jsx(CohortFilter, { defaultValue: defaultCohortValue, onChange: handleCohortSelectChange }), _jsx(SeasonFilter, { onChange: handleSeasonSelectChange }), _jsx(RegionFilter, { defaultValue: defaultRegionCode, onChange: handleRegionSelectChange })] })), _jsx(PDFExportButtonText, {})] }))] })));
};
export default DashboardHeader;
