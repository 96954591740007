var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { SvgIcon } from '@mui/material';
export const IconMap = (_a) => {
    var { color = 'primary' } = _a, rest = __rest(_a, ["color"]);
    return (_jsx(SvgIcon, Object.assign({ viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", color: color }, rest, { children: _jsx("path", { d: "M10.5 13.8252V3.94238L5.5 2.18457V12.0674L10.5 13.8252ZM15.0703 0.504883C15.3568 0.504883 15.5 0.648112 15.5 0.93457V13.5127C15.5 13.721 15.3958 13.8512 15.1875 13.9033L10.5 15.5049L5.5 13.7471L1.04688 15.4658L0.929688 15.5049C0.643229 15.5049 0.5 15.3617 0.5 15.0752V2.49707C0.5 2.28874 0.604167 2.15853 0.8125 2.10645L5.5 0.504883L10.5 2.2627L14.9531 0.543945L15.0703 0.504883Z", fill: "currentColor" }) })));
};
