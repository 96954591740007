import { useAlarmingDataComputation } from '@/pages/AlarmingDataPage/useAlarmingDataComputation';
import { useAppDispatch, useAppSelector } from '@hooks';
import { selectAlarmingDataLastFetchedAt, setInitialFetch } from '@features/alarmingData/alarmingData.slice';
import { useElapsedTime } from '@hooks/useElapsedTime';
import { useDataRefetch } from '@hooks/useDataRefetch';
export const useAlarmingDataRefetch = () => {
    const dispatch = useAppDispatch();
    const { isLoading, isError, data, refetch, isFetching } = useAlarmingDataComputation();
    const lastFetchedAt = useAppSelector(selectAlarmingDataLastFetchedAt);
    const { elapsedMinutes } = useElapsedTime(lastFetchedAt ? new Date(lastFetchedAt) : undefined);
    const onInitialFetch = () => dispatch(setInitialFetch({ initialFetch: new Date().toString() }));
    const { handleRefetch } = useDataRefetch({
        onInitialFetch,
        refetch,
        isLoading,
        isError,
        hasData: !!data,
        automaticRefreshDelay: 1000 * 60 * 5,
    });
    return { elapsedMinutes, handleRefetch, isFetching };
};
