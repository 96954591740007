var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumber, round } from '@utils/numbers';
import { useGetFarmsCropsProgrammeAnalyticsQuery } from '@services/analytics.api';
import { useAppSelector } from '@hooks/redux';
import { useRegionsByCountryView } from '@hooks/useRegionsByCountryView';
import { selectCohortId, selectRegionCode, selectSeasonId } from '@features/dashboard/dashboardSlice';
const STACKED_BAR_COLORS = ['#431A06', '#5E360F', '#774C1C', '#A87F52', '#F9DCBE'];
export const computeFarmsCropsProgrammeData = (t, selectedSeasonId, fetchedData) => {
    var _a, _b, _c, _d;
    if (!fetchedData || !selectedSeasonId)
        return undefined;
    let farmsAmount = 0;
    let farmsTotalArea = 0;
    let farmsAverageArea = 0;
    const farmsAmountPerSeason = [];
    const farmsTotalAreaPerSeason = [];
    const farmsAverageAreaPerSeason = [];
    fetchedData.map((data) => {
        farmsAmountPerSeason.push({
            id: data.season_id,
            y: data.farms_count,
            x: `${data.harvest_year}`,
            unit: '',
        });
        farmsTotalAreaPerSeason.push({
            id: data.season_id,
            y: round((data === null || data === void 0 ? void 0 : data.total_farms_area) || 0, 1),
            x: `${data.harvest_year}`,
            unit: 'ha',
        });
        farmsAverageAreaPerSeason.push({
            id: data.season_id,
            y: round((data === null || data === void 0 ? void 0 : data.average_farms_area) || 0, 1),
            x: `${data.harvest_year}`,
            unit: 'ha',
        });
    });
    const filteredData = fetchedData.filter((data) => data.season_id === selectedSeasonId);
    filteredData.forEach((element) => {
        farmsAmount += Number(element.farms_count);
        farmsTotalArea += Number(element.total_farms_area);
        farmsAverageArea += Number(element.average_farms_area);
    });
    const cropsTotalArea = fetchedData === null || fetchedData === void 0 ? void 0 : fetchedData.map((season) => { var _a; return (_a = season === null || season === void 0 ? void 0 : season.crops) === null || _a === void 0 ? void 0 : _a.reduce((acc, crop) => (acc += crop.area), 0); });
    const mostRecentSeasonTopCropsIds = (_d = (_c = (_b = (_a = fetchedData
        .slice(-1)) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.crops) === null || _c === void 0 ? void 0 : _c.slice(0, 4)) === null || _d === void 0 ? void 0 : _d.map((crop) => crop.id);
    const colorMap = mostRecentSeasonTopCropsIds === null || mostRecentSeasonTopCropsIds === void 0 ? void 0 : mostRecentSeasonTopCropsIds.map((id, i) => ({ id, color: STACKED_BAR_COLORS[i] }));
    const seasonsTopCrops = fetchedData === null || fetchedData === void 0 ? void 0 : fetchedData.map((season, i) => {
        var _a, _b;
        return ({
            id: season.season_id,
            name: `${season.harvest_year}`,
            items: (_b = (_a = season === null || season === void 0 ? void 0 : season.crops) === null || _a === void 0 ? void 0 : _a.filter((crop) => mostRecentSeasonTopCropsIds.includes(crop.id))) === null || _b === void 0 ? void 0 : _b.map((crop) => {
                var _a;
                return ({
                    percentage: round((100 / cropsTotalArea[i]) * crop.area, 1),
                    label: t(crop.translation_slug),
                    color: ((_a = colorMap === null || colorMap === void 0 ? void 0 : colorMap.find((el) => el.id === crop.id)) === null || _a === void 0 ? void 0 : _a.color) || '#fff',
                });
            }),
        });
    });
    const crops = seasonsTopCrops === null || seasonsTopCrops === void 0 ? void 0 : seasonsTopCrops.map((crops) => {
        const { items } = crops, rest = __rest(crops, ["items"]);
        const topCropsPercentage = items === null || items === void 0 ? void 0 : items.reduce((acc, curr) => (acc += curr.percentage), 0);
        const otherCrops = {
            percentage: round(100 - topCropsPercentage, 1),
            label: t('labels.others'),
            color: STACKED_BAR_COLORS[STACKED_BAR_COLORS.length - 1],
        };
        const arr = [...items];
        arr.push(otherCrops);
        return Object.assign(Object.assign({}, rest), { items: arr });
    });
    const roundedFarmsTotalArea = round(farmsTotalArea, 1);
    const roundedFarmsAverageArea = round(farmsAverageArea, 1);
    return {
        farmsAmount: farmsAmount,
        farmsTotalArea: roundedFarmsTotalArea,
        farmsAverageArea: roundedFarmsAverageArea,
        crops,
        farmsAmountPerSeason,
        farmsTotalAreaPerSeason,
        farmsAverageAreaPerSeason,
    };
};
export const useDashboardProgrammeDataView = () => {
    const { i18n, t } = useTranslation();
    const seasonId = useAppSelector(selectSeasonId);
    const regionCode = useAppSelector(selectRegionCode);
    const cohortId = useAppSelector(selectCohortId);
    const { data: programmeData, isLoading: programmeDataIsLoading, isFetching: programmeDataIsFetching, isError: programmeDataIsError, } = useGetFarmsCropsProgrammeAnalyticsQuery({
        regionCode,
        cohortId,
    });
    const { data: regionsByCountry, isLoading: regionsByCountryIsLoading, isFetching: regionsByCountryIsFetching, isError: regionsByCountryIsError, } = useRegionsByCountryView({ seasonId, regionCode, cohortId });
    const hasData = !!(seasonId && programmeData && regionsByCountry);
    const farmsCropsComputedData = useMemo(() => computeFarmsCropsProgrammeData(t, seasonId, programmeData), [seasonId, programmeData]);
    const formattedFarmAmount = formatNumber((farmsCropsComputedData === null || farmsCropsComputedData === void 0 ? void 0 : farmsCropsComputedData.farmsAmount) || 0, i18n.language);
    const formattedFarmTotalArea = formatNumber((farmsCropsComputedData === null || farmsCropsComputedData === void 0 ? void 0 : farmsCropsComputedData.farmsTotalArea) || 0, i18n.language);
    const formattedFarmAverageArea = formatNumber((farmsCropsComputedData === null || farmsCropsComputedData === void 0 ? void 0 : farmsCropsComputedData.farmsAverageArea) || 0, i18n.language);
    const formattedData = hasData
        ? Object.assign(Object.assign({}, farmsCropsComputedData), { localizations: regionsByCountry, formattedFarmAmount,
            formattedFarmTotalArea,
            formattedFarmAverageArea }) : undefined;
    const isLoading = programmeDataIsLoading || regionsByCountryIsLoading;
    const isFetching = programmeDataIsFetching || regionsByCountryIsFetching;
    const isError = programmeDataIsError || regionsByCountryIsError;
    return {
        data: formattedData,
        isLoading,
        isFetching,
        isError,
    };
};
