import { makeStyles } from 'tss-react/mui';
export default makeStyles()(() => {
    return {
        contentContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        title: {
            paddingBottom: '20px',
        },
    };
});
