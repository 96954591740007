import { useGetRegionsByCountryQuery } from '@services';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { changeRegion, selectRegionFilter } from '@features/dashboard/dashboardSlice';
import { useEffect } from 'react';
import { regionCodeDefault } from '@components/RegionFilter/RegionFilter';
export const getRegionNameByCode = (code, data) => { var _a, _b; return (_b = (_a = data === null || data === void 0 ? void 0 : data.flatMap((country) => country.regions)) === null || _a === void 0 ? void 0 : _a.find((region) => region.regionCode === code)) === null || _b === void 0 ? void 0 : _b.regionName; };
export const useRegionsByCountryView = (props = {}) => {
    const { seasonId, regionCode, cohortId } = props !== null && props !== void 0 ? props : {};
    const regionFilter = useAppSelector(selectRegionFilter);
    const dispatch = useAppDispatch();
    const { data, isLoading, isFetching, isError } = useGetRegionsByCountryQuery({ seasonId, regionCode, cohortId });
    useEffect(() => {
        if (!regionCode && !regionFilter) {
            dispatch(changeRegion({
                regionCode: undefined,
                regionFilter: regionCodeDefault,
            }));
        }
    }, [data, dispatch, regionCode, regionFilter]);
    const countries = [];
    let result;
    if (data) {
        data.map((el) => {
            const countryCode = el.country_code;
            const countryName = el.country_name;
            if (!countries.some((country) => country.countryCode === countryCode))
                countries.push({
                    countryCode,
                    countryName,
                });
        });
        result = countries.map((country) => ({
            countryCode: country.countryCode,
            countryName: country.countryName,
            regions: data
                .filter((el) => el.country_code === country.countryCode)
                .map((region) => ({
                regionCode: region.region_code,
                regionName: region.region_name,
                latitude: region.latitude,
                longitude: region.longitude,
                farmCount: region.farm_count,
            })),
        }));
    }
    return { data: result, isLoading, isFetching, isError };
};
