import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Tooltip, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useRemainingChipsMessageStyles } from './RemainingChipsMessage.style';
const renderTooltipContent = (remainingChips) => (_jsx(_Fragment, { children: remainingChips.map((chip) => (_jsxs(Fragment, { children: [chip.value, _jsx("br", {})] }, chip.key))) }));
export const RemainingChipsMessage = ({ remainingChips, label, TooltipProps }) => {
    const tooltipContent = renderTooltipContent(remainingChips);
    const remainingChipsCount = remainingChips.length;
    const { classes } = useRemainingChipsMessageStyles();
    return (_jsx(Tooltip, Object.assign({ title: tooltipContent, placement: "bottom", arrow: true }, TooltipProps, { children: _jsx(Typography, Object.assign({ className: classes.chipsLeftLabel, variant: "body2" }, { children: `+ ${remainingChipsCount} ${label || ''}` })) })));
};
