import { makeStyles } from 'tss-react/mui';
export const useAlarmingDataSidePanelStyles = makeStyles()((theme) => ({
    sidePanel: {
        width: '300px',
        paddingLeft: theme.spacing(6),
        paddingBottom: theme.spacing(6),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflowY: 'hidden',
        backgroundColor: theme.palette.layout.main,
    },
    sidePanelLinksList: {
        listStyle: 'none',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    panelLink: {
        textDecoration: 'none',
        padding: theme.spacing(4),
        borderRadius: '8px',
        marginRight: theme.spacing(3),
    },
    selectedPanelLink: {
        textDecoration: 'none',
        padding: theme.spacing(4),
        borderRadius: '8px',
        backgroundColor: 'white',
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
        width: '100%',
        marginRight: 0,
    },
    profileCard: {
        marginRight: theme.spacing(3),
    },
}));
