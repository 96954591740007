import { makeStyles } from 'tss-react/mui';
export const useFarmerDashboardCardStyles = makeStyles()((theme) => {
    return {
        farmerDashboardCard: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#f8f8f8',
            padding: '0px 30px 0 30px',
            boxShadow: 'none',
        },
        bold: { fontWeight: theme.typography.fontWeightBold },
        modalContent: {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(8),
        },
        modalFooter: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    };
});
