import { selectCurrentUser } from '@features/auth';
import { useAppSelector } from '@hooks/redux';
import { usePermission } from '@hooks/usePermission';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetCurrentPartnerQuery } from '@services';
import { useMemo } from 'react';
export const useAuth = () => {
    const { canAccessPlatformAsAssociate } = usePermission();
    const user = useAppSelector(selectCurrentUser);
    const param = user && !canAccessPlatformAsAssociate ? undefined : skipToken;
    const { data: partner } = useGetCurrentPartnerQuery(param);
    return useMemo(() => ({ user, partner }), [user, partner]);
};
