import { makeStyles } from 'tss-react/mui';
export const useAlarmingDataCommentsStyles = makeStyles()((theme) => ({
    list: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
    },
    comment: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        backgroundColor: theme.palette.grey['200'],
        padding: theme.spacing(2),
        borderRadius: '8px',
    },
    author: {
        fontWeight: 'bold',
    },
    icon: {
        fontSize: '16px',
        cursor: 'pointer',
    },
}));
