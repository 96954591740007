import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useGetSupportedLanguagesQuery } from '@services';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useGetEmailConfigurations } from '@features/invite/farmer/useGetEmailConfigurations';
export const useCreateValidationSchema = (t) => {
    const sharedSchema = yup.object().shape({
        languageId: yup.number(),
        languageCode: yup.string(),
        subject: yup.string().required(t('farmers.invite.errors.subject-required')),
        body: yup.string().required(t('farmers.invite.errors.body-required')),
    });
    const emailValidationSchema = yup
        .string()
        .email(t('farmers.invite.errors.email-invalid'))
        .required(t('farmers.invite.errors.email-required'));
    const inviteSchema = yup.object().shape(Object.assign({ emails: yup.array().of(emailValidationSchema).required(t('farmers.invite.errors.email-required')) }, sharedSchema.fields));
    const resendSchema = yup.object().shape(Object.assign({ invitationIds: yup.array().of(yup.number()).required(t('farmers.invite.errors.email-required')) }, sharedSchema.fields));
    return {
        sharedSchema,
        emailValidationSchema,
        inviteSchema,
        resendSchema,
    };
};
export const useInviteFarmerModalForm = ({ shouldResend } = {}) => {
    const { data: languages } = useGetSupportedLanguagesQuery();
    const { data: emailConfigs } = useGetEmailConfigurations();
    const { i18n, t } = useTranslation();
    const { resendSchema, inviteSchema } = useCreateValidationSchema(t);
    const defaultLanguage = languages === null || languages === void 0 ? void 0 : languages.find((l) => l.iso_code === i18n.language);
    const defaultEmailConfiguration = emailConfigs === null || emailConfigs === void 0 ? void 0 : emailConfigs.find((c) => c.language_id === (defaultLanguage === null || defaultLanguage === void 0 ? void 0 : defaultLanguage.id));
    const defaultSubject = defaultEmailConfiguration === null || defaultEmailConfiguration === void 0 ? void 0 : defaultEmailConfiguration.email_subject;
    const defaultBody = defaultEmailConfiguration === null || defaultEmailConfiguration === void 0 ? void 0 : defaultEmailConfiguration.email_body;
    const hasDefaultValues = defaultLanguage && defaultSubject && defaultBody;
    const inviteDefaultValue = hasDefaultValues
        ? {
            emails: [],
            languageId: defaultLanguage.id,
            languageCode: defaultLanguage.iso_code,
            subject: defaultSubject,
            body: defaultBody,
        }
        : undefined;
    const resendDefaultValue = hasDefaultValues
        ? {
            invitationIds: [],
            languageId: defaultLanguage.id,
            languageCode: defaultLanguage.iso_code,
            subject: defaultSubject,
            body: defaultBody,
        }
        : undefined;
    const methods = useForm({
        defaultValues: shouldResend ? resendDefaultValue : inviteDefaultValue,
        resolver: yupResolver(shouldResend ? resendSchema : inviteSchema),
    });
    useEffect(() => {
        if (hasDefaultValues) {
            methods.reset(shouldResend ? resendDefaultValue : inviteDefaultValue);
        }
    }, [hasDefaultValues, shouldResend]);
    return { methods };
};
