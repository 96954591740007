import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BoxedIcon } from '@components/BoxedIcon';
import { IconSignal } from '@components/Icons';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import usePreviousYearResultsStyle from './PreviousYearResults.style';
import { useTranslation } from 'react-i18next';
export const PREVIOUS_YEAR_VARIANTS = {
    SEQUESTRATING_MORE: 'SEQUESTRATING_MORE',
    SEQUESTRATING_LESS: 'SEQUESTRATING_LESS',
    SEQUESTRATING_NO_COMPARISON: 'SEQUESTRATING_NO_COMPARISON',
    EMITTING_MORE: 'EMITTING_MORE',
    EMITTING_LESS: 'EMITTING_LESS',
    EMITTING_NO_COMPARISON: 'EMITTING_NO_COMPARISON',
    ZERO_BETTER_THAN_LAST_YEAR: 'ZERO_BETTER_THAN_LAST_YEAR',
    ZERO_WORSE_THAN_LAST_YEAR: 'ZERO_WORSE_THAN_LAST_YEAR',
    ZERO_NO_COMPARISON: 'ZERO_NO_COMPARISON',
};
export const resultMap = {
    [PREVIOUS_YEAR_VARIANTS.SEQUESTRATING_MORE]: {
        boxVariant: 'success',
        iconVariant: 'high',
        title: 'messages.carbon.sequestrating-more',
        body: '',
    },
    [PREVIOUS_YEAR_VARIANTS.SEQUESTRATING_LESS]: {
        boxVariant: 'warning',
        iconVariant: 'medium',
        title: 'messages.carbon.sequestrating-less',
        body: 'messages.carbon.body',
    },
    [PREVIOUS_YEAR_VARIANTS.SEQUESTRATING_NO_COMPARISON]: {
        boxVariant: 'neutral',
        iconVariant: 'none',
        title: 'messages.carbon.sequestrating-no-comparison',
        body: '',
    },
    [PREVIOUS_YEAR_VARIANTS.EMITTING_MORE]: {
        boxVariant: 'error',
        iconVariant: 'low',
        title: 'messages.carbon.emitting-more',
        body: 'messages.carbon.body',
    },
    [PREVIOUS_YEAR_VARIANTS.EMITTING_LESS]: {
        boxVariant: 'warning',
        iconVariant: 'medium',
        title: 'messages.carbon.emitting-less',
        body: 'messages.carbon.body',
    },
    [PREVIOUS_YEAR_VARIANTS.EMITTING_NO_COMPARISON]: {
        boxVariant: 'neutral',
        iconVariant: 'none',
        title: 'messages.carbon.emitting-no-comparison',
        body: '',
    },
    [PREVIOUS_YEAR_VARIANTS.ZERO_BETTER_THAN_LAST_YEAR]: {
        boxVariant: 'neutral',
        iconVariant: 'none',
        title: 'messages.carbon.zero-better',
        body: 'messages.carbon.body',
    },
    [PREVIOUS_YEAR_VARIANTS.ZERO_WORSE_THAN_LAST_YEAR]: {
        boxVariant: 'neutral',
        iconVariant: 'none',
        title: 'messages.carbon.zero-worse',
        body: 'messages.carbon.body',
    },
    [PREVIOUS_YEAR_VARIANTS.ZERO_NO_COMPARISON]: {
        boxVariant: 'neutral',
        iconVariant: 'none',
        title: 'messages.carbon.zero-no-comparison',
        body: 'messages.carbon.body',
    },
};
export const PreviousYearResults = ({ variant }) => {
    const { classes } = usePreviousYearResultsStyle();
    const { t } = useTranslation();
    if (!variant)
        return null;
    const data = resultMap[variant];
    return (_jsxs("div", Object.assign({ className: classes.container }, { children: [_jsx(Box, Object.assign({ width: 40 }, { children: _jsx(BoxedIcon, { icon: _jsx(IconSignal, { "data-icon-variant": data.iconVariant, variant: data.iconVariant || 'none' }), variant: data.boxVariant, "data-boxed-variant": data.boxVariant }) })), _jsxs("div", { children: [_jsx(Typography, Object.assign({ className: classes.title, variant: "subtitle2" }, { children: t(data.title) })), _jsx(Typography, Object.assign({ "data-testid": "body", className: classes.body, variant: "body1" }, { children: t(data.body) }))] })] })));
};
