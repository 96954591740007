import { makeStyles } from 'tss-react/mui';
export const useContactsEditFormStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
    form: {
        width: '80%',
    },
    inputLabel: {
        marginBottom: '10px',
    },
    select: {
        width: '100%',
    },
}));
