import { api } from './api.service';
export const languageApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getSupportedLanguages: builder.query({
            query: () => ({
                url: `/public/languages/supported`,
                method: 'GET',
            }),
        }),
        getSupportedInvitationLanguages: builder.query({
            query: () => ({
                url: `/public/languages/supported/invitations`,
                method: 'GET',
            }),
        }),
    }),
});
export const { useGetSupportedLanguagesQuery, useGetSupportedInvitationLanguagesQuery } = languageApi;
