import { makeStyles } from 'tss-react/mui';
export default makeStyles()(() => {
    return {
        programmeItemsContainer: {
            display: 'flex',
        },
        title: {
            paddingBottom: '20px',
        },
        mapContainer: {
            minWidth: '33%',
        },
    };
});
