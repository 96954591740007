var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ROUTES } from '@router/routes';
export var ApiToggle;
(function (ApiToggle) {
    ApiToggle[ApiToggle["NONE"] = 0] = "NONE";
    ApiToggle[ApiToggle["PARTNER"] = 1] = "PARTNER";
    ApiToggle[ApiToggle["ASSOCIATE"] = 2] = "ASSOCIATE";
})(ApiToggle || (ApiToggle = {}));
const baseQuery = fetchBaseQuery({
    baseUrl: process.env.API_URL,
    prepareHeaders: (headers) => {
        headers.set('Content-Type', 'application/json');
        headers.set('Accept', 'application/json, */*');
        return headers;
    },
    credentials: 'include',
});
const baseQueryWithInterceptor = (args, api, extraOptions) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        yield baseQuery({ url: 'private/auth/logout', method: 'POST' }, api, extraOptions);
        const location = window.location.pathname;
        if (location !== ROUTES.LOGIN)
            window.location.pathname = ROUTES.LOGIN;
    }
    return result;
});
export const api = createApi({
    tagTypes: [
        'Members',
        'Farmers',
        'Farms',
        'Companies',
        'Farmer',
        'InvitationConfiguration',
        'Assignment',
        'AlarmingData',
    ],
    baseQuery: baseQueryWithInterceptor,
    refetchOnMountOrArgChange: true,
    endpoints: () => ({}),
});
