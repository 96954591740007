export const typography = (palette) => ({
    htmlFontSize: 16,
    fontFamily: '"BrownStd-Regular","Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    allVariants: {
        color: palette.primary.main,
    },
    h1: {
        fontFamily: '"BrownStd-Bold","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 700,
        fontSize: '30px',
        lineHeight: '45px',
    },
    h2: {
        fontFamily: '"BrownStd-Bold","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '36px',
    },
    h3: {
        fontFamily: '"BrownStd-Bold","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '30px',
    },
    h4: {
        fontFamily: '"BrownStd-Bold","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '30px',
    },
    h5: {
        fontFamily: '"BrownStd-Bold","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '30px',
    },
    h6: {
        fontFamily: '"BrownStd-Bold","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '30px',
    },
    subtitle1: {
        fontFamily: '"BrownStd-Regular","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
    },
    subtitle2: {
        fontFamily: '"BrownStd-Bold","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px',
    },
    body1: {
        fontFamily: '"BrownStd-Regular","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '21px',
    },
    body2: {
        fontFamily: '"BrownStd-Regular","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
    },
    button: {
        fontFamily: '"BrownStd-Bold","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '16px',
        textTransform: 'none',
    },
    caption: {
        fontFamily: '"BrownStd-Bold","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 700,
        fontSize: '13px',
        lineHeight: '13px',
    },
    overline: {
        fontFamily: '"BrownStd-Regular","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: 2.66,
        letterSpacing: '0.08333em',
        textTransform: 'uppercase',
    },
});
