export const getLanguageId = (iso) => {
    if (iso === 'fr')
        return 3;
    return 1;
};
export const getNationality = (iso) => {
    const nationalities = 'values.nationalities';
    switch (iso.toUpperCase()) {
        case 'BE':
            return `${nationalities}.BE`;
        case 'GB':
            return `${nationalities}.GB`;
        default:
            return `${nationalities}.FR`;
    }
};
