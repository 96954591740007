var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AlarmingDataValues } from '@features/alarmingData/components/AlarmingDataValues/AlarmingDataValues';
import { Button, CircularProgress, Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useAlarmingDataInfoStyles } from './AlarmingDataInfo.style';
export const AlarmingDataInfo = ({ className, id, title, values, showConfirm, onConfirm, isConfirmDisabled, isLoading, }) => {
    const { t } = useTranslation();
    const { classes } = useAlarmingDataInfoStyles();
    const handleConfirm = () => __awaiter(void 0, void 0, void 0, function* () { return yield onConfirm([id]); });
    return (_jsxs("div", Object.assign({ className: clsx(classes.container, className) }, { children: [_jsx(Typography, Object.assign({ className: classes.name }, { children: title })), _jsx(AlarmingDataValues, { className: classes.values, values: values }), showConfirm && (_jsx(Button, Object.assign({ onClick: handleConfirm, disabled: isConfirmDisabled || isLoading, className: classes.button, size: "small", color: "primary" }, { children: isLoading ? _jsx(CircularProgress, { size: 12, sx: { color: 'white' } }) : t('common.confirm') })))] })));
};
