import { makeStyles } from 'tss-react/mui';
export default makeStyles()((theme) => {
    return {
        container: {},
        icon: {
            paddingRight: theme.spacing(3),
        },
        graphContainer: {
            marginTop: theme.spacing(3),
            paddingLeft: theme.spacing(2),
        },
    };
});
