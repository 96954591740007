import { usePermission } from '@hooks/usePermission';
import { useQueryParams } from '@hooks';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetPartnerInvitationByIdQuery } from '@services/invitation.api';
export const useCanAccessFarmerProfile = () => {
    var _a;
    const { canAccessPlatformAsPartner, canAccessPlatformAsAssociate, canManageFarmer } = usePermission();
    const { queryParams } = useQueryParams();
    const invitationId = (_a = queryParams.get('invitationId')) !== null && _a !== void 0 ? _a : '';
    const fetchParam = canAccessPlatformAsPartner && invitationId ? { invitationId: Number(invitationId) } : skipToken;
    const { data, isLoading } = useGetPartnerInvitationByIdQuery(fetchParam);
    if (isLoading)
        return { canAccessProfile: undefined, isLoading: true };
    if (!canManageFarmer)
        return { canAccessProfile: false, isLoading: false };
    if (canAccessPlatformAsAssociate)
        return { canAccessProfile: true, isLoading: false };
    return { canAccessProfile: !!(canAccessPlatformAsPartner && data && !data.rejected_at), isLoading: false };
};
