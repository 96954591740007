var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SvgIcon } from '@mui/material';
export const IconMarker = (_a) => {
    var { color = 'primary' } = _a, rest = __rest(_a, ["color"]);
    return (_jsx(SvgIcon, Object.assign({ viewBox: "0 0 32 35", fill: "none", xmlns: "http://www.w3.org/2000/svg", color: color }, rest, { children: _jsxs("g", Object.assign({ filter: "url(#filter0_d_795_4433)" }, { children: [_jsx("path", { d: "M28 14.25C28 19.9634 21.5815 26.6353 17.7785 29.0992C16.6823 29.8094 15.3177 29.8094 14.2215 29.0992C10.4185 26.6353 4 19.9634 4 14.25C4 7.48451 9.37258 2 16 2C22.6274 2 28 7.48451 28 14.25Z", fill: "currentColor" }), _jsx("path", { d: "M27.5714 14.25C27.5714 16.9628 26.0378 19.9648 23.9763 22.631C21.9232 25.2863 19.4029 27.5361 17.5455 28.7395C16.5911 29.3578 15.4089 29.3578 14.4545 28.7395C12.5971 27.5361 10.0768 25.2863 8.02366 22.631C5.96215 19.9648 4.42857 16.9628 4.42857 14.25C4.42857 7.71283 9.61756 2.42857 16 2.42857C22.3824 2.42857 27.5714 7.71283 27.5714 14.25Z", stroke: "currentColor", strokeWidth: "0.857143" })] })) })));
};
