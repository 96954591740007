import { makeStyles } from 'tss-react/mui';
export default makeStyles()(() => {
    return {
        contentContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        icon: {
            marginBottom: '48px',
            width: '160px',
            height: '160px',
        },
        subtitle: {
            marginTop: '8px',
        },
    };
});
