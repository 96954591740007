import { makeStyles } from 'tss-react/mui';
const useLabelCheckboxViewStyle = makeStyles()((theme, { border, noMargin }) => ({
    checkboxContainer: {
        padding: '0 10px',
        border: border === 'dotted' ? `2px dashed ${theme.palette.secondary.main}` : 'none',
        borderRadius: '5px',
        marginBottom: noMargin ? '0px' : '16px',
        marginLeft: '2px', // There is a positioning issue with the checkbox
    },
    checkbox: {
        borderRadius: '5px',
        '& svg': {
            borderColor: theme.palette.secondary.light,
            height: '20px',
            width: '20px',
        },
    },
}));
export default useLabelCheckboxViewStyle;
