import { api } from '@services';
export const partnerApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getCurrentPartner: builder.query({
            query: () => ({
                url: '/private/partners',
                method: 'GET',
            }),
        }),
        deleteFarmersFromPartner: builder.mutation({
            query: (ids) => ({
                url: '/private/partners/farmers',
                method: 'DELETE',
                body: ids,
            }),
            invalidatesTags: ['Farmers'],
        }),
        deleteFarmerFromPartner: builder.mutation({
            query: (data) => ({
                url: `/private/partners/farmer/${data.farmerId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Farmers'],
        }),
    }),
});
export const { useGetCurrentPartnerQuery, useDeleteFarmersFromPartnerMutation, useDeleteFarmerFromPartnerMutation } = partnerApi;
