import { api } from './api.service';
export const farmApi = api.injectEndpoints({
    endpoints: (builder) => ({
        updateFarm: builder.mutation({
            query: (data) => ({
                url: `/private/farms/${data.id}`,
                method: 'PUT',
                body: data.body,
            }),
        }),
    }),
});
export const { useUpdateFarmMutation } = farmApi;
