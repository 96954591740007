import { makeStyles } from 'tss-react/mui';
import backgroundDotsLuminosity from '@images/background-dots-luminosity.png';
export default makeStyles()(() => {
    return {
        container: {
            height: '100vh',
            width: '100vw',
            backgroundImage: `url(${backgroundDotsLuminosity})`,
            backgroundRepeat: 'repeat',
            backgroundSize: 'cover',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
});
