const dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
export const formatDate = (isoCode, value, defaultValue = '') => {
    return value ? new Date(value).toLocaleDateString(isoCode, dateOptions) : defaultValue;
};
export const getElapsedTimeInMinutes = (anteriorDate) => {
    if (!anteriorDate)
        return 0;
    const currentTime = new Date();
    const timeDifferenceInMilliseconds = currentTime.getTime() - anteriorDate.getTime();
    return Math.floor(timeDifferenceInMilliseconds / (1000 * 60));
};
