var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { SvgIcon } from '@mui/material';
export const IconCross = (_a) => {
    var { color = 'primary' } = _a, rest = __rest(_a, ["color"]);
    return (_jsx(SvgIcon, Object.assign({ viewBox: "0 0 60 61", fill: "none", xmlns: "http://www.w3.org/2000/svg", color: color }, rest, { children: _jsx("path", { d: "M30 34.8508L5.86207 58.9887C5.17241 59.6784 4.36782 60.0232 3.44828 60.0232C2.52874 60.0232 1.72414 59.6784 1.03448 58.9887C0.344827 58.2991 0 57.4945 0 56.5749C0 55.6554 0.344827 54.8508 1.03448 54.1611L25.1724 30.0232L1.03448 5.88526C0.344827 5.19561 0 4.39101 0 3.47147C0 2.55193 0.344827 1.74733 1.03448 1.05768C1.72414 0.368021 2.52874 0.0231934 3.44828 0.0231934C4.36782 0.0231934 5.17241 0.368021 5.86207 1.05768L30 25.1956L54.1379 1.05768C54.8276 0.368021 55.6322 0.0231934 56.5517 0.0231934C57.4713 0.0231934 58.2759 0.368021 58.9655 1.05768C59.6552 1.74733 60 2.55193 60 3.47147C60 4.39101 59.6552 5.19561 58.9655 5.88526L34.8276 30.0232L58.9655 54.1611C59.6552 54.8508 60 55.6554 60 56.5749C60 57.4945 59.6552 58.2991 58.9655 58.9887C58.2759 59.6784 57.4713 60.0232 56.5517 60.0232C55.6322 60.0232 54.8276 59.6784 54.1379 58.9887L30 34.8508Z", fill: "currentColor" }) })));
};
