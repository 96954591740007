var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SvgIcon } from '@mui/material';
export const IconBell = (_a) => {
    var { color = 'primary' } = _a, rest = __rest(_a, ["color"]);
    return (_jsx(SvgIcon, Object.assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 20", enableBackground: "new 0 0 16 20", color: color }, rest, { children: _jsxs("g", { children: [_jsx("path", { fill: "currentColor", d: "M14,13.846V8.718c0-3.148-1.63-5.785-4.5-6.482V1.539C9.5,0.687,8.83,0,8,0S6.5,0.687,6.5,1.539v0.697\n\t\tC3.64,2.933,2,5.559,2,8.718v5.128l-2,2.052v1.026h16v-1.026L14,13.846z M12,14.871H4V8.718c0-2.543,1.51-4.615,4-4.615\n\t\ts4,2.072,4,4.615V14.871z" }), _jsx("path", { fill: "currentColor", d: "M8,20c1.1,0,2-0.924,2-2.051H6C6,19.076,6.9,20,8,20z" })] }) })));
};
