var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useAlarmingDataCommentFormStyles } from '@features/alarmingData/components/AlarmingDataComments/AlarmingDataCommentForm/AlarmingDataCommentForm.style';
import { Avatar } from '@components/Avatar';
import { Button, CircularProgress, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useAppSelector } from '@hooks';
import { selectCurrentUser } from '@features/auth';
import { getAvatarInitials } from '@utils/string';
import { useRef } from 'react';
import { usePostCommentMutation, useUpdateCommentMutation } from '@services';
import { useSnackbar } from 'notistack';
export const AlarmingDataCommentForm = ({ alarmingDataIds, commentId, defaultValue = '', onSubmit, onBack, }) => {
    const { t } = useTranslation();
    const avatarRef = useRef(null);
    const { classes } = useAlarmingDataCommentFormStyles();
    const { register, watch, reset, handleSubmit } = useForm({
        defaultValues: {
            comment: defaultValue,
        },
    });
    const hasData = watch('comment');
    const [postComment, { isLoading: isLoadingPostComment }] = usePostCommentMutation();
    const [updateComment, { isLoading: isLoadingUpdateComment }] = useUpdateCommentMutation();
    const isLoading = isLoadingPostComment || isLoadingUpdateComment;
    const { enqueueSnackbar } = useSnackbar();
    const user = useAppSelector(selectCurrentUser);
    const initials = getAvatarInitials(user === null || user === void 0 ? void 0 : user.first_name, user === null || user === void 0 ? void 0 : user.last_name, user === null || user === void 0 ? void 0 : user.email);
    const onReset = () => {
        reset();
        onBack === null || onBack === void 0 ? void 0 : onBack();
    };
    const onFormSubmit = ({ comment }) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (commentId) {
                yield updateComment({ commentId, comment }).unwrap();
                enqueueSnackbar(t('snackbar.comment-updated'), { variant: 'success' });
            }
            else {
                yield postComment({ alarmingDataIds, comment }).unwrap();
                enqueueSnackbar(t('snackbar.comment-posted'), { variant: 'success' });
            }
            reset();
            onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit();
        }
        catch (e) {
            enqueueSnackbar(t('errors.server-error'), { variant: 'error' });
        }
    });
    return (_jsxs("form", Object.assign({ onSubmit: handleSubmit(onFormSubmit) }, { children: [_jsxs("div", Object.assign({ className: classes.container }, { children: [_jsx(Avatar, Object.assign({ ref: avatarRef, size: "small" }, { children: initials })), _jsx(TextField, { InputProps: Object.assign({}, register('comment')), variant: "outlined", className: classes.input, placeholder: t('placeholders.write-comment') })] })), hasData && (_jsxs("div", Object.assign({ className: classes.actions, style: { marginLeft: `40px` } }, { children: [_jsx(Button, Object.assign({ disabled: isLoading, type: "submit", className: classes.save, size: "small" }, { children: isLoading ? _jsx(CircularProgress, { size: 10, sx: { color: 'white' } }) : t('buttons.save') })), _jsx(Button, Object.assign({ onClick: onReset, variant: "text" }, { children: t('buttons.cancel') }))] })))] })));
};
