import { makeStyles } from 'tss-react/mui';
export const useFarmerProfileCompanyCardStyles = makeStyles()((theme) => {
    return {
        companyCard: {
            position: 'relative',
            backgroundColor: '#f8f8f8',
            padding: '20px 30px 0 30px',
            boxShadow: 'none',
        },
        editCompanyButton: {
            position: 'absolute',
            right: 20,
            top: 15,
        },
    };
});
