import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { canConfirmAlarmingData, isAlarmingDataConfirmationDisabled, } from '@/pages/AlarmingDataPage/useAlarmingDataComputation';
import { AlarmingDataCheckCard } from '@features/alarmingData/components/AlarmingDataCheck/AlarmingDataCheckCard';
import { ALARMING_DATA_CHECK_SLUGS } from '@features/alarmingData/constants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
export const AlarmingFarmSeasonData = ({ className, check, onConfirm, isLoading }) => {
    const { t } = useTranslation();
    const alarmingData = useMemo(() => {
        const data = [];
        check.groups.map((g) => data.push(...g.alarmingData));
        data.push(...check.ungrouped);
        return data;
    }, [check]);
    return (_jsx(_Fragment, { children: alarmingData.map((data) => {
            var _a, _b;
            return (_jsx(AlarmingDataCheckCard, { className: className, id: data.id, title: ALARMING_DATA_CHECK_SLUGS[data.check.name]
                    ? t(ALARMING_DATA_CHECK_SLUGS[data.check.name])
                    : data.check.name, showConfirm: canConfirmAlarmingData(data), isConfirmDisabled: isAlarmingDataConfirmationDisabled(data), onConfirm: onConfirm, isLoading: isLoading, values: data.values, comments: (_b = (_a = data.group) === null || _a === void 0 ? void 0 : _a.comments) !== null && _b !== void 0 ? _b : [] }, data.id));
        }) }));
};
