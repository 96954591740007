import { makeStyles } from 'tss-react/mui';
export default makeStyles()((theme) => {
    return {
        container: {
            paddingBottom: '20px',
        },
        placeholderTypography: {
            color: theme.palette.grey[500],
        },
    };
});
