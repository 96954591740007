import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FarmersPage from '@/pages/FarmersPage/FarmersPage';
import MembersPage from '@/pages/MembersPage/MembersPage';
import MobileHardstopPage from '@/pages/MobileHardstopPage/MobileHardstopPage';
import { Dashboard } from '@features/dashboard';
import { SettingsSection } from '@features/settings';
import { AcceptInvitePage, ForgotPassPage, HomePage, LoginPage, RegisterInvitePage, SignupPage } from '@pages';
import { Navigate, Route, Routes } from 'react-router-dom';
import Guard from '../guards/Guard';
import { ROUTES } from '@router/routes';
import { AlarmingDataPage } from '@/pages/AlarmingDataPage/AlarmingDataPage';
import { FarmerProfilePage } from '@/pages/FarmerProfile/FarmerProfilePage';
/**
 * Main Router
 */
function Router() {
    return (_jsxs(Routes, { children: [_jsxs(Route, Object.assign({ path: ROUTES.HOME, element: _jsx(Guard, { target: _jsx(HomePage, {}), guards: ['authenticated', 'isMobile'] }) }, { children: [_jsx(Route, { index: true, element: _jsx(Guard, { target: _jsx(Dashboard, {}), guards: ['canSeeDashboard'] }) }), _jsx(Route, { path: ROUTES.SETTINGS, element: _jsx(SettingsSection, {}) }), _jsx(Route, { path: ROUTES.MEMBERS, element: _jsx(Guard, { target: _jsx(MembersPage, {}), guards: ['canSeeMembers'] }) }), _jsxs(Route, Object.assign({ path: ROUTES.FARMERS }, { children: [_jsx(Route, { index: true, element: _jsx(Guard, { target: _jsx(FarmersPage, {}), guards: ['canManageFarmer'] }) }), _jsx(Route, { path: ROUTES.FARMER_PROFILE, element: _jsx(Guard, { target: _jsx(FarmerProfilePage, {}), guards: ['canManageFarmer', 'canAccessProfile'] }) })] }))] })), _jsx(Route, { path: ROUTES.ALARMING_DATA, element: _jsx(Guard, { target: _jsx(AlarmingDataPage, {}), guards: ['canManageFarmer', 'canSeeAlarmingData'] }) }), _jsx(Route, { path: ROUTES.LOGIN, element: _jsx(LoginPage, {}) }), _jsx(Route, { path: ROUTES.FORGOT_PASSWORD, element: _jsx(ForgotPassPage, {}) }), _jsx(Route, { path: ROUTES.FORGOT_PASSWORD_TOKEN, element: _jsx(ForgotPassPage, {}) }), _jsx(Route, { path: ROUTES.ACCEPT_INVITE, element: _jsx(AcceptInvitePage, {}) }), _jsx(Route, { path: ROUTES.REGISTER_INVITE, element: _jsx(RegisterInvitePage, {}) }), _jsx(Route, { path: ROUTES.MOBILE_HARDSTOP, element: _jsx(MobileHardstopPage, {}) }), _jsx(Route, { path: ROUTES.SIGNUP, element: _jsx(SignupPage, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: ROUTES.LOGIN }) })] }));
}
export default Router;
