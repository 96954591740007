import { usePermission } from '@hooks/usePermission';
import { useFarmerContacts } from '@features/assignment/useFarmerContacts';
export const useAssignedContactsFormData = ({ farmerId }) => {
    const { data: contacts, isLoading } = useFarmerContacts({ id: farmerId });
    const assignableContacts = contacts || [];
    const { canAssignPartnerMemberToFarmer, canAssignAssociateToFarmer } = usePermission();
    const canEditAssignedContacts = !!contacts && (canAssignPartnerMemberToFarmer || canAssignAssociateToFarmer);
    return {
        assignableContacts,
        isLoading,
        canEditAssignedContacts,
    };
};
