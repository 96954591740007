import { makeStyles } from 'tss-react/mui';
export const useMembersTableHeaderStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    actionContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(4),
    },
}));
