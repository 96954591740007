var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CircularProgress, TextField } from '@mui/material';
import { forwardRef, useState } from 'react';
import { KEYBOARD_KEY } from '@utils/constants';
import { ResetButton, SelectedChips } from '@components/Form/SelectedChips/SelectedChips';
import { useInputChipsStyles } from './InputChips.style';
import { v4 as uuid } from 'uuid';
export const InputChips = forwardRef((_a, ref) => {
    var { defaultChips, onChange, onInputChange, onChipDelete, placeholder, validate, helperText, onReset, maxChips, remainingChipsLabel, isLoading = false } = _a, props = __rest(_a, ["defaultChips", "onChange", "onInputChange", "onChipDelete", "placeholder", "validate", "helperText", "onReset", "maxChips", "remainingChipsLabel", "isLoading"]);
    const [inputValue, setInputValue] = useState('');
    const [chips, setChips] = useState(defaultChips || []);
    const { classes, cx } = useInputChipsStyles();
    const _b = props || {}, { className, inputProps, InputProps } = _b, rest = __rest(_b, ["className", "inputProps", "InputProps"]);
    const _c = InputProps || {}, { endAdornment } = _c, restInputProps = __rest(_c, ["endAdornment"]);
    const validationGuard = (inputValue, event) => {
        return (callback) => __awaiter(void 0, void 0, void 0, function* () {
            if (typeof validate === 'function') {
                const validation = validate(inputValue);
                if (validation instanceof Promise) {
                    try {
                        const isValid = yield validation;
                        if (!isValid) {
                            event === null || event === void 0 ? void 0 : event.preventDefault();
                            return;
                        }
                    }
                    catch (error) {
                        console.error('Validation error:', error);
                        event === null || event === void 0 ? void 0 : event.preventDefault();
                        return;
                    }
                }
                if (!validation) {
                    event === null || event === void 0 ? void 0 : event.preventDefault();
                    return;
                }
            }
            callback();
        });
    };
    const handleChange = (e) => {
        setInputValue(e.target.value);
        onInputChange === null || onInputChange === void 0 ? void 0 : onInputChange(e.target.value);
    };
    const handleChipDelete = (chip) => {
        setChips(chips === null || chips === void 0 ? void 0 : chips.filter((c) => c.key !== chip.key));
        onChipDelete === null || onChipDelete === void 0 ? void 0 : onChipDelete(chip);
    };
    const handleKeyDown = (event) => {
        const shouldAddValueAsChip = (event.key === KEYBOARD_KEY.ENTER || event.key === KEYBOARD_KEY.SPACE) && inputValue.length > 0;
        const shouldDeleteChip = event.key === KEYBOARD_KEY.BACKSPACE && inputValue.length === 0 && chips.length > 0;
        const inputValueTrimmed = inputValue.trim();
        if (inputValueTrimmed.length === 0)
            setInputValue('');
        if (shouldAddValueAsChip) {
            validationGuard(inputValueTrimmed, event)(() => {
                event.preventDefault();
                const newId = uuid();
                const newChips = [...chips, { key: newId, value: inputValueTrimmed }];
                setChips(newChips);
                onChange === null || onChange === void 0 ? void 0 : onChange(newChips);
                setInputValue('');
            });
        }
        if (shouldDeleteChip) {
            const chipIndex = chips.length - 1;
            onChipDelete === null || onChipDelete === void 0 ? void 0 : onChipDelete(chips[chipIndex]);
            setChips(chips.filter((c, i) => i !== chipIndex));
        }
    };
    const handleBlur = () => {
        const inputValueTrimmed = inputValue.trim();
        const shouldAddValueAsChip = inputValueTrimmed.length > 0;
        if (inputValueTrimmed.length === 0)
            setInputValue('');
        if (shouldAddValueAsChip) {
            validationGuard(inputValueTrimmed)(() => {
                const newId = uuid();
                const newChips = [...chips, { key: newId, value: inputValueTrimmed }];
                setChips(newChips);
                onChange === null || onChange === void 0 ? void 0 : onChange(newChips);
                setInputValue('');
            });
        }
    };
    const handleReset = () => {
        setChips([]);
        onReset === null || onReset === void 0 ? void 0 : onReset();
    };
    return (_jsx(TextField, Object.assign({ className: cx(classes.input, !!(maxChips && chips.length > maxChips) && classes.inputMinWidth, className), ref: ref, value: inputValue, placeholder: placeholder, onChange: handleChange, onBlur: handleBlur, inputProps: Object.assign({ onKeyDown: handleKeyDown }, inputProps), InputProps: Object.assign({ startAdornment: (_jsx(SelectedChips, { chips: chips, maxChips: maxChips, onChipDelete: handleChipDelete, remainingChipsLabel: remainingChipsLabel })), endAdornment: (_jsxs(_Fragment, { children: [endAdornment, isLoading ? _jsx(CircularProgress, { size: 15 }) : null, chips.length > 0 ? (_jsx(ResetButton, { className: classes.resetButton, onReset: handleReset })) : null] })) }, restInputProps), helperText: helperText }, rest)));
});
InputChips.displayName = 'InputChips';
