import { usePermission } from '@hooks/usePermission';
import { ApiToggle } from '@services';
import { useGetCohortsQuery } from '@services/cohort.api';
export const useGetCohorts = () => {
    const { canSeeAllPartnerFarmers, canSeeAssignedPartnerFarmers, canSeeAssociateFarmers, canSeeAssociateAssignedFarmers, } = usePermission();
    const apiToggle = canSeeAllPartnerFarmers || canSeeAssignedPartnerFarmers
        ? ApiToggle.PARTNER
        : canSeeAssociateFarmers || canSeeAssociateAssignedFarmers
            ? ApiToggle.ASSOCIATE
            : ApiToggle.NONE;
    const { data, isLoading, isFetching, isError } = useGetCohortsQuery({ toggle: apiToggle });
    return {
        data,
        isLoading,
        isFetching,
        isError,
    };
};
