import { makeStyles } from 'tss-react/mui';
export const useAlarmingDataValuesStyles = makeStyles()((theme) => ({
    rows: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: theme.spacing(6),
    },
    row: {
        display: 'flex',
        gap: theme.spacing(2),
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
    },
    valuesList: {
        listStyle: 'none',
    },
    value: {
        color: theme.palette.grey['400'],
    },
    year: {
        color: theme.palette.grey['400'],
    },
}));
