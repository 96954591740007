var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, CircularProgress } from '@mui/material';
import { useInviteFarmersModalStyles } from '@features/invite/farmer/Modal/InviteFarmersModal.style';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import { useGetAllFarmersPendingInvitationsQuery, useInviteFarmersMutation, useResendFarmerInvitesMutation, } from '@services/invitation.api';
import { useGetSupportedInvitationLanguagesQuery } from '@services/language.api';
import { InviteFarmersModalLoader } from './InviteFarmersModalLoader';
import { useInviteFarmerModalForm } from '../useInviteFarmerModalForm';
import { ComposeEmailForm } from '@features/invite/farmer/ComposeEmailForm/ComposeEmailForm';
import { useGetEmailConfigurations } from '@features/invite/farmer/useGetEmailConfigurations';
import { useSnackbar } from 'notistack';
import { useUpdateEmailConfigurations } from '@features/invite/farmer/useUpdateEmailConfigurations';
import { ResendFarmerEmailsForm } from '@features/invite/farmer/ResendFarmerEmailsForm/ResendFarmerEmailsForm';
import { SelectFarmersEmailsForm } from '@features/invite/farmer/SelectFarmersEmailsForm/SelectFarmersEmailsForm';
import { Modal } from '@components/Modal/Modal';
export const InviteFarmersModal = ({ open, onClose, shouldResend = false }) => {
    const { t } = useTranslation();
    const { classes } = useInviteFarmersModalStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { data: pendingInvitations, isLoading: isLoadingInvitations, isError: isErrorPendingInvitations, } = useGetAllFarmersPendingInvitationsQuery();
    const { data: emailConfigs, isLoading: isLoadingEmailConfigs, isError: isErrorEmailConfigs, } = useGetEmailConfigurations();
    const { data: languages, isLoading: isLoadingInvitationLanguages, isError: isErrorLanguages, } = useGetSupportedInvitationLanguagesQuery();
    const isLoading = isLoadingInvitations || isLoadingEmailConfigs || isLoadingInvitationLanguages;
    const isError = isErrorPendingInvitations || isErrorEmailConfigs || isErrorLanguages;
    const noData = !pendingInvitations || !emailConfigs || !languages;
    const [inviteMutation, { isLoading: isLoadingInviteMutation }] = useInviteFarmersMutation();
    const [resendMutation, { isLoading: isLoadingResendInviteMutation }] = useResendFarmerInvitesMutation();
    const { manageEmailConfigurationsMutation, isLoading: isLoadingManageEmailConfigMutation } = useUpdateEmailConfigurations();
    const isMutationLoading = isLoadingInviteMutation || isLoadingManageEmailConfigMutation || isLoadingResendInviteMutation;
    const { methods } = useInviteFarmerModalForm({ shouldResend });
    const isFormValid = Object.keys(methods.formState.errors).length === 0 &&
        (shouldResend
            ? !!methods.watch('invitationIds') && methods.watch('invitationIds').length > 0
            : !!methods.watch('emails') && methods.watch('emails').length > 0);
    const handleClose = () => {
        methods.reset();
        onClose();
    };
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            let amountSent = 0;
            let amountResent = 0;
            yield manageEmailConfigurationsMutation({
                body: {
                    language_id: data.languageId,
                    email_subject: data.subject,
                    email_body: data.body,
                },
            });
            if (shouldResend && 'invitationIds' in data) {
                yield resendMutation({
                    body: {
                        ids: data.invitationIds,
                    },
                    languageId: data.languageId,
                    languageCode: data.languageCode,
                }).unwrap();
                amountResent = data.invitationIds.length;
            }
            else if ('emails' in data) {
                const alreadyInvitedFarmerIds = pendingInvitations === null || pendingInvitations === void 0 ? void 0 : pendingInvitations.filter((invitation) => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.emails) === null || _a === void 0 ? void 0 : _a.includes(invitation.email); }).map((invitation) => invitation.id);
                if (Array.isArray(alreadyInvitedFarmerIds) && alreadyInvitedFarmerIds.length > 0) {
                    yield resendMutation({
                        body: {
                            ids: alreadyInvitedFarmerIds,
                        },
                        languageId: data.languageId,
                        languageCode: data.languageCode,
                    }).unwrap();
                    amountResent = alreadyInvitedFarmerIds.length;
                }
                if (!((alreadyInvitedFarmerIds === null || alreadyInvitedFarmerIds === void 0 ? void 0 : alreadyInvitedFarmerIds.length) === data.emails.length)) {
                    yield inviteMutation({
                        emails: data.emails,
                        languageId: data.languageId,
                        languageCode: data.languageCode,
                    }).unwrap();
                    amountSent = data.emails.length - ((alreadyInvitedFarmerIds === null || alreadyInvitedFarmerIds === void 0 ? void 0 : alreadyInvitedFarmerIds.length) || 0);
                }
            }
            handleClose();
            if (amountSent)
                enqueueSnackbar(t('snackbar.sent-success', { count: amountSent }), { variant: 'success' });
            if (amountResent)
                enqueueSnackbar(t('snackbar.resent-success', { count: amountResent }), { variant: 'success' });
        }
        catch (e) {
            enqueueSnackbar(t('errors.server-error'), { variant: 'error' });
        }
    });
    if (isLoading)
        return _jsx(InviteFarmersModalLoader, { open: open, onClose: onClose, shouldResend: shouldResend });
    if (open && (isError || (!isLoading && noData))) {
        enqueueSnackbar(t('errors.server-error'), { variant: 'error' });
        onClose();
    }
    return (_jsx(Modal, Object.assign({ open: open, onClose: handleClose, title: shouldResend ? t('farmers.invite.resend-invite') : t('farmers.invite-farmers') }, { children: _jsx(FormProvider, Object.assign({}, methods, { children: _jsxs("form", Object.assign({ className: classes.form }, { children: [shouldResend ? _jsx(ResendFarmerEmailsForm, {}) : _jsx(SelectFarmersEmailsForm, {}), _jsx(ComposeEmailForm, {}), _jsx(Box, Object.assign({ className: classes.actionContainer }, { children: _jsx(Button, Object.assign({ onClick: methods.handleSubmit(onSubmit), disabled: isMutationLoading || !isFormValid, variant: "contained", color: "primary" }, { children: isMutationLoading ? (_jsx(CircularProgress, { className: classes.buttonLoader, size: 20 })) : (t('buttons.save')) })) }))] })) })) })));
};
