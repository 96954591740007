import { makeStyles } from 'tss-react/mui';
const useLabelPasswordFieldStyle = makeStyles()((theme) => ({
    eyeIcon: {
        width: '1.2rem',
        color: theme.palette.secondary.main,
        transition: theme.transitions.create(['color'], { duration: theme.transitions.duration.short }),
        '&:hover': {
            color: theme.palette.secondary.dark,
        },
        cursor: 'pointer',
    },
}));
export default useLabelPasswordFieldStyle;
