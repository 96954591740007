var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { LabelTextField } from '../LabelTextField';
import { useState } from 'react';
import EyeIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import useLabelPasswordFieldStyle from './LabelPasswordField.style';
export const LabelPasswordField = (_a) => {
    var { autoComplete, disabled, error, helperText, id, label, name, value, variant, fullWidth, required, onChange, onBlur, onFocus } = _a, other = __rest(_a, ["autoComplete", "disabled", "error", "helperText", "id", "label", "name", "value", "variant", "fullWidth", "required", "onChange", "onBlur", "onFocus"]);
    const { classes } = useLabelPasswordFieldStyle();
    const [passwordVisible, setPasswordVisible] = useState(false);
    return (_jsx(LabelTextField, Object.assign({ autoComplete: autoComplete, disabled: disabled, endAdornment: _jsx(EyeIcon, { className: classes.eyeIcon, onMouseDown: () => setPasswordVisible(true), onMouseLeave: () => setPasswordVisible(false), onMouseUp: () => setPasswordVisible(false) }), error: error, fullWidth: fullWidth, helperText: helperText, id: id, label: label, name: name, required: required, type: passwordVisible ? 'text' : 'password', value: value, variant: variant, onBlur: onBlur, onChange: onChange, onFocus: onFocus }, other)));
};
