import { makeStyles } from 'tss-react/mui';
export const useAvatarStyle = makeStyles()((theme) => ({
    avatar: {
        border: `2px solid ${theme.palette.common.white}`,
        backgroundColor: '#E7EDF9',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        boxShadow: 'none',
        aspectRatio: 1,
        width: 'initial',
        height: 'initial',
    },
    small: {
        fontFamily: theme.typography.body2.fontFamily,
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        padding: theme.spacing(1),
    },
    medium: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontSize: theme.typography.subtitle1.fontSize,
        padding: theme.spacing(2),
    },
    large: {
        fontFamily: theme.typography.h3.fontFamily,
        fontSize: theme.typography.h3.fontSize,
        padding: theme.spacing(2),
    },
}));
