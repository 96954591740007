import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAlarmingDataAccess } from '@features/alarmingData/useAlarmingDataAccess';
export const AlarmingDataButton = ({ userOrInvitationId, farmSeasonId, }) => {
    const { t } = useTranslation();
    const { canAccessAlarmingDataPage, alarmingDataPagePath } = useAlarmingDataAccess({
        userOrInvitationId,
        farmSeasonId,
    });
    return (_jsx(_Fragment, { children: canAccessAlarmingDataPage && (_jsx(Button, Object.assign({ sx: { minWidth: 'auto', padding: 3 }, size: "small", component: Link, to: alarmingDataPagePath, target: "_blank", rel: "noopener noreferrer" }, { children: t('titles.data-analysis') }))) }));
};
