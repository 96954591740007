import { jsx as _jsx } from "react/jsx-runtime";
import AuthLayout from '../../layouts/AuthLayout/AuthLayout';
import { RegisterForm } from '@features/auth';
import { RegisterVariant } from '@features/auth/RegisterForm/RegisterForm';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
const RegisterInvitePage = () => {
    const { t } = useTranslation();
    return (_jsx(AuthLayout, { form: _jsx(RegisterForm, { registerVariant: RegisterVariant.REGISTER_FROM_INVITATION }), sideZone: _jsx(Box, {}), title: t('titles.register-to-partners-portal') }));
};
export default RegisterInvitePage;
