import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { Metric } from '@components/Metric';
import useMetricsLayout from './MetricsLayout.style';
export const MetricsLayout = ({ totalAmount, unit, title, tooltip, graph, icon, boxVariant }) => {
    const { classes } = useMetricsLayout();
    const metricProps = {
        boxedIconProps: {
            variant: boxVariant,
            icon: icon,
        },
        bigNumberUnitProps: {
            value: totalAmount,
            unit: unit,
        },
        label: title,
        tooltipScProps: {
            text: tooltip,
        },
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ className: classes.container }, { children: _jsx(Metric, { boxedIconProps: metricProps.boxedIconProps, bigNumberUnitProps: metricProps.bigNumberUnitProps, label: metricProps.label, tooltipScProps: tooltip ? { text: tooltip } : undefined }) })), _jsx(Box, Object.assign({ className: classes.graphContainer }, { children: graph }))] }));
};
