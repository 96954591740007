import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, FormControl, MenuItem, SvgIcon, Typography } from '@mui/material';
import { Select } from '@components/Select';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStatusFilterStyles } from './StatusFilter.style';
import { useGetAvailableFarmerStatuses } from '@hooks/useGetAvailableFarmerStatuses';
const statusFilterDefaultValue = 'all';
export const StatusFilter = ({ defaultValue, className, onChange }) => {
    const { t } = useTranslation();
    const { classes } = useStatusFilterStyles();
    const [inputValue, setInputValue] = useState(defaultValue || statusFilterDefaultValue);
    const statuses = useGetAvailableFarmerStatuses();
    const getStatusFilterText = (value) => { var _a; return value === statusFilterDefaultValue ? t('common.all') : t((_a = statuses.find((s) => s.type === value)) === null || _a === void 0 ? void 0 : _a.name); };
    const handleChange = (event) => {
        const inputValue = event.target.value;
        setInputValue(inputValue);
        onChange === null || onChange === void 0 ? void 0 : onChange(inputValue === statusFilterDefaultValue ? undefined : inputValue);
    };
    return (_jsx(FormControl, Object.assign({ className: className }, { children: _jsxs(Select, Object.assign({ IconComponent: KeyboardArrowUpIcon, className: classes.select, value: inputValue, onChange: handleChange, name: "contact-select", renderValue: (value) => {
                return (_jsxs(Box, Object.assign({ className: classes.selectWrapper }, { children: [_jsx(SvgIcon, Object.assign({ className: classes.selectIcon }, { children: _jsx(ContentPasteIcon, {}) })), _jsxs(Typography, Object.assign({ variant: "body1", className: classes.selectOption }, { children: [t('common.status'), " : ", getStatusFilterText(value)] }))] })));
            } }, { children: [_jsx(MenuItem, Object.assign({ value: statusFilterDefaultValue }, { children: t('common.all') })), statuses === null || statuses === void 0 ? void 0 : statuses.map((status) => (_jsx(MenuItem, Object.assign({ className: classes.menuItem, value: status.type }, { children: t(status.name) }), status.id)))] })) })));
};
