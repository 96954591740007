var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, CircularProgress, Link } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';
import { LabelPasswordField } from '@components/LabelPasswordField';
import { LabelTextField } from '@components/LabelTextField';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLoginMutation } from '@services';
import { PermissionsEnum, useLazyGetPermissionsQuery } from '@services/permissions.api';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useLoginFormStyle from './LoginForm.style';
function LoginForm() {
    var _a, _b;
    const { classes } = useLoginFormStyle();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const LoginFormSchema = yup.object({
        email: yup.string().email(t('validations.email-invalid')).required(t('validations.email-required')),
        password: yup.string().required(t('validations.password-required')),
    });
    const { register, handleSubmit, formState: { errors }, } = useForm({
        reValidateMode: 'onChange',
        resolver: yupResolver(LoginFormSchema),
    });
    const [loading, setLoading] = useState(false);
    const [login] = useLoginMutation();
    const [getPermissions] = useLazyGetPermissionsQuery();
    const { enqueueSnackbar } = useSnackbar();
    const onSubmit = (data) => __awaiter(this, void 0, void 0, function* () {
        try {
            setLoading(true);
            yield login(data).unwrap();
            const permissions = yield getPermissions().unwrap();
            setLoading(false);
            permissions.includes(PermissionsEnum.PP_CAN_SEE_DASHBOARD) ? navigate('/') : navigate('/farmers');
        }
        catch (err) {
            setLoading(false);
            if (err.message) {
                enqueueSnackbar(t(`responses.${err.message}`), { variant: 'error' });
                return;
            }
            enqueueSnackbar(t('errors.server-error'), { variant: 'error' });
        }
    });
    return (_jsx(Box, Object.assign({ className: classes.container }, { children: _jsxs("form", Object.assign({ id: "login-form", noValidate: true, onSubmit: handleSubmit(onSubmit) }, { children: [_jsx(LabelTextField, { InputProps: Object.assign({}, register('email')), autoComplete: "email", disabled: loading, error: errors.email !== undefined, helperText: (_a = errors.email) === null || _a === void 0 ? void 0 : _a.message, label: t('labels.email'), variant: "outlined", fullWidth: true, required: true }), _jsx(LabelPasswordField, { InputProps: Object.assign({}, register('password')), autoComplete: "current-password", disabled: loading, error: errors.password !== undefined, helperText: (_b = errors.password) === null || _b === void 0 ? void 0 : _b.message, label: t('labels.password'), variant: "outlined", fullWidth: true, required: true }), _jsx(Link, Object.assign({ className: classes.checkbox, component: RouterLink, to: "/forgot-password", variant: "button" }, { children: t('buttons.forgot-password') })), _jsx(Box, Object.assign({ mt: 6 }, { children: _jsx(Button, Object.assign({ disabled: loading, type: "submit", variant: "contained", disableElevation: true, fullWidth: true }, { children: loading ? _jsx(CircularProgress, { style: { color: 'white' }, size: 20 }) : t('buttons.login') })) }))] })) })));
}
export default LoginForm;
