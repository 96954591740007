import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
export const useMemberEditModalFormData = ({ member }) => {
    const defaultValues = { roleId: member.role.id };
    const methods = useForm({
        defaultValues,
    });
    useEffect(() => {
        if (member)
            methods.reset({ roleId: member.role.id });
    }, [member, methods]);
    return methods;
};
