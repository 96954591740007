import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { SelectChips } from '@components/Form/SelectChips/SelectChips';
import { getHookFormErrorMessage } from '@utils/string';
import { useTranslation } from 'react-i18next';
import { RequiredInputLabel } from '@components/RequiredInputLabel';
import { useResendMemberEmailsFormStyles } from './ResendMemberEmailsForm.style';
import { useInvitationResend } from '@features/invite/hooks/useInvitationResend';
import { useGetAllMembersPendingInvitationsQuery } from '@services/invitation.api';
export const ResendMemberEmailsForm = () => {
    const { setValue, formState: { errors }, clearErrors, } = useFormContext();
    const { classes } = useResendMemberEmailsFormStyles();
    const { t } = useTranslation();
    const { data: pendingMembers } = useGetAllMembersPendingInvitationsQuery();
    const onChange = (invitationIds) => {
        clearErrors('invitationIds');
        setValue('invitationIds', invitationIds);
    };
    const { handleChange, handleReset, handleInvitationDeleted, handleShouldResendToAll, resendInvitations, shouldResendToAll, } = useInvitationResend({ invitations: pendingMembers || [], onChange });
    const invitationErrorMessage = getHookFormErrorMessage(errors === null || errors === void 0 ? void 0 : errors.invitationIds);
    const getOptionKey = (option) => option.id;
    const getOptionLabel = (option) => option.email;
    const getOptionValue = (option) => option.id;
    if (!pendingMembers)
        return _jsx(_Fragment, {});
    return (_jsxs(Box, Object.assign({ className: classes.container }, { children: [_jsx(RequiredInputLabel, Object.assign({ required: true, className: classes.inputTitle }, { children: t('members.invite.recipients') })), _jsx(SelectChips, { className: classes.select, values: resendInvitations, options: pendingMembers, onChange: handleChange, getOptionLabel: getOptionLabel, getOptionValue: getOptionValue, getOptionKey: getOptionKey, onChipDelete: handleInvitationDeleted, error: !!(errors === null || errors === void 0 ? void 0 : errors.invitationIds), helperText: invitationErrorMessage, onReset: handleReset, maxChips: 2, remainingChipsLabel: t('common.others') }), _jsx(FormControlLabel, { className: classes.checkbox, slotProps: {
                    typography: {
                        variant: 'body2',
                    },
                }, control: _jsx(Checkbox, { size: "small", checked: shouldResendToAll, onChange: handleShouldResendToAll }), label: t('members.invite.resend-all') })] })));
};
