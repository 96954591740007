import { api } from '@services';
export const partnerUserApi = api.injectEndpoints({
    endpoints: (builder) => ({
        deletePartnerUsers: builder.mutation({
            query: (ids) => ({
                url: `/private/partner-users`,
                method: 'DELETE',
                body: ids,
            }),
            invalidatesTags: ['Members'],
        }),
        updatePartnerUser: builder.mutation({
            query: (data) => ({
                url: `/private/partner-users/${data.id}`,
                method: 'PUT',
                body: data.body,
            }),
            invalidatesTags: ['Members'],
        }),
        getPartnerUserInvitationConfiguration: builder.query({
            query: () => ({
                url: `/private/partner-users/configuration/invitation`,
                method: 'GET',
            }),
            providesTags: ['InvitationConfiguration'],
        }),
        createPartnerUserInvitationConfiguration: builder.mutation({
            query: (data) => ({
                url: `/private/partner-users/configuration/invitation`,
                method: 'POST',
                body: data.body,
            }),
            invalidatesTags: ['InvitationConfiguration'],
        }),
        updatePartnerUserInvitationConfiguration: builder.mutation({
            query: (data) => ({
                url: `/private/partner-users/configuration/invitation/${data.id}`,
                method: 'PUT',
                body: data.body,
            }),
            invalidatesTags: ['InvitationConfiguration'],
        }),
    }),
});
export const { useDeletePartnerUsersMutation, useUpdatePartnerUserMutation, useGetPartnerUserInvitationConfigurationQuery, useCreatePartnerUserInvitationConfigurationMutation, useUpdatePartnerUserInvitationConfigurationMutation, } = partnerUserApi;
