import { makeStyles } from 'tss-react/mui';
export const useStatusFilterStyles = makeStyles()(() => ({
    select: {
        minWidth: '180px',
        maxWidth: '240px',
        height: '40px',
        '.MuiSelect-select': {
            display: 'flex',
        },
        '& > div.MuiSelect-select': {
            padding: '8px 16px',
        },
        '& .MuiTypography-body1': {
            fontWeight: 'normal',
            marginLeft: 0,
            fontSize: '16px',
        },
    },
    selectOption: {
        textTransform: 'capitalize',
        maxWidth: '180px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    selectWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: '20px',
    },
    selectIcon: {
        marginRight: '8px',
        width: '16px',
        marginTop: 2,
    },
    menuItem: {
        minHeight: '33px',
        zIndex: '40',
        textTransform: 'capitalize',
    },
}));
