import { useGetCurrentPartnerQuery, useGetPartnerUserInvitationConfigurationQuery, useGetSupportedLanguagesQuery, } from '@services';
import { useTranslation } from 'react-i18next';
const getDefaultConfigurationPerLanguage = (t, l, partner) => ({
    email_body: `${t('emails.invite-farmer-email.welcome-message', {
        lng: l.iso_code,
        replace: { partnerName: partner === null || partner === void 0 ? void 0 : partner.name },
    })}\n\n${t('emails.invite-farmer-email.paragraph-one', {
        lng: l.iso_code,
        replace: { partnerName: partner === null || partner === void 0 ? void 0 : partner.name },
    })}\n\n${t('emails.invite-farmer-email.paragraph-two', {
        lng: l.iso_code,
        replace: { partnerName: partner === null || partner === void 0 ? void 0 : partner.name },
    })}\n\n${t('emails.invite-farmer-email.paragraph-three', {
        lng: l.iso_code,
        replace: { partnerName: partner === null || partner === void 0 ? void 0 : partner.name },
    })}`,
    email_subject: t('emails.invite-farmer-email.subject', {
        lng: l.iso_code,
    }),
    language_id: l.id,
    partner_user_id: 0,
});
const computeEmailConfigurations = (languages, userEmailSavedConfigs, t, partner) => {
    return languages.map((l) => {
        const foundSavedConfiguration = userEmailSavedConfigs.find((c) => c.language_id === l.id);
        if (foundSavedConfiguration)
            return foundSavedConfiguration;
        else
            return getDefaultConfigurationPerLanguage(t, l, partner);
    });
};
export const useGetEmailConfigurations = () => {
    const { t } = useTranslation();
    const { data: userEmailSavedConfigs, isLoading: isLoadingConfigs } = useGetPartnerUserInvitationConfigurationQuery();
    const { data: languages, isLoading: isLoadingLanguages, isError: isErrorLanguages, } = useGetSupportedLanguagesQuery();
    const { data: partner, isLoading: isLoadingPartner, isError: isErrorPartner } = useGetCurrentPartnerQuery();
    const isLoading = isLoadingConfigs || isLoadingLanguages || isLoadingPartner;
    const isError = isErrorLanguages || isErrorPartner;
    const emailConfigurations = languages && userEmailSavedConfigs && partner
        ? computeEmailConfigurations(languages, userEmailSavedConfigs, t, partner)
        : undefined;
    return { data: emailConfigurations, isLoading, isError };
};
