var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from 'react-i18next';
import { useAssignContacts } from '@features/assignment/useAssignContacts';
import { useRemoveAssignedContacts } from '@features/assignment/useRemoveAssignedContacts';
import { useSnackbar } from 'notistack';
export const useChangePartnerAssignedContacts = ({ selectedContacts, assignedContacts, invitationId, onSuccess, }) => {
    const { t } = useTranslation();
    const { assignPartnerContactToFarmer, isLoading: addContactIsLoading } = useAssignContacts();
    const { removePartnerContactsFromFarmer, isLoading: removeContactLoading } = useRemoveAssignedContacts();
    const { enqueueSnackbar } = useSnackbar();
    const changeAssignedContacts = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        try {
            const mutations = [];
            const contactsIdsToDelete = ((_a = assignedContacts === null || assignedContacts === void 0 ? void 0 : assignedContacts.filter((assigned) => !(selectedContacts === null || selectedContacts === void 0 ? void 0 : selectedContacts.find((selected) => selected.id === assigned.id)))) === null || _a === void 0 ? void 0 : _a.map((c) => Number(c.assignment_id))) || [];
            if ((contactsIdsToDelete === null || contactsIdsToDelete === void 0 ? void 0 : contactsIdsToDelete.length) > 0) {
                mutations.push(removePartnerContactsFromFarmer({
                    invitation_id: invitationId,
                    contact_ids: contactsIdsToDelete,
                }));
            }
            const newContactsIds = (_b = selectedContacts === null || selectedContacts === void 0 ? void 0 : selectedContacts.filter((c) => !c.assignment_id)) === null || _b === void 0 ? void 0 : _b.map((c) => Number(c.id));
            if ((newContactsIds === null || newContactsIds === void 0 ? void 0 : newContactsIds.length) > 0) {
                mutations.push(assignPartnerContactToFarmer({
                    invitation_id: invitationId,
                    contact_ids: newContactsIds,
                }));
            }
            yield Promise.all(mutations.map((promise) => __awaiter(void 0, void 0, void 0, function* () { return yield promise.unwrap(); })));
            enqueueSnackbar(t('snackbar.contact-assigned'), { variant: 'success' });
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
        }
        catch (e) {
            enqueueSnackbar(t('errors.server-error'), { variant: 'error' });
        }
    });
    const isLoading = addContactIsLoading || removeContactLoading;
    return { changeAssignedContacts, isLoading };
};
