import { useGetSeasonsForPartnerQuery } from '@services/season.api';
import { useEffect } from 'react';
import { changeSeason, seasonStorageKey } from '@features/dashboard/dashboardSlice';
import { useAppDispatch } from '@hooks/redux';
import { useTranslation } from 'react-i18next';
export const findSeasonById = (id, data) => data === null || data === void 0 ? void 0 : data.find((s) => s.id === id);
export const useSeasonView = () => {
    const dispatch = useAppDispatch();
    const { data, isLoading, isError } = useGetSeasonsForPartnerQuery();
    const { t } = useTranslation();
    // Should always have a season selected
    useEffect(() => {
        var _a, _b, _c;
        if (!localStorage.getItem(seasonStorageKey))
            if ((_a = data === null || data === void 0 ? void 0 : data[0]) === null || _a === void 0 ? void 0 : _a.id) {
                const seasonId = Number(`${(_b = data[0]) === null || _b === void 0 ? void 0 : _b.id}`);
                dispatch(changeSeason({
                    seasonId,
                    harvestFilter: `${t('common.harvest')} ${(_c = findSeasonById(seasonId, data)) === null || _c === void 0 ? void 0 : _c.harvest_year}`,
                }));
            }
    }, [data]);
    return { data, isLoading, isError };
};
