import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAlarmingDataCheckCardStyles } from '@features/alarmingData/components/AlarmingDataCheck/AlarmingDataCheckCard.style';
import { AlarmingDataComments } from '@features/alarmingData/components/AlarmingDataComments/AlarmingDataComments';
import { AlarmingDataInfo } from '@features/alarmingData/components/AlarmingDataInfo/AlarmingDataInfo';
import { Card } from '@mui/material';
import clsx from 'clsx';
export const AlarmingDataCheckCard = ({ className, id, title, values, showConfirm, isConfirmDisabled, isLoading, onConfirm, comments, }) => {
    const { classes } = useAlarmingDataCheckCardStyles();
    return (_jsxs(Card, Object.assign({ className: clsx(classes.card, className) }, { children: [_jsx(AlarmingDataInfo, { id: id, title: title, values: values, showConfirm: showConfirm, onConfirm: onConfirm, isConfirmDisabled: isConfirmDisabled, isLoading: isLoading }), _jsx(AlarmingDataComments, { comments: comments, alarmingDataIds: [id] })] })));
};
