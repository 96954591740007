import { makeStyles } from 'tss-react/mui';
export const useAlarmingDataCommentFormStyles = makeStyles()((theme) => ({
    container: {
        // backgroundColor: theme.palette.grey['200'],
        display: 'flex',
        alignItems: 'center',
        borderRadius: '8px',
    },
    input: {
        marginLeft: theme.spacing(2),
        width: '100%',
    },
    actions: {
        marginTop: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
    save: {
        padding: theme.spacing(3),
    },
}));
