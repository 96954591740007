import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, Typography } from '@mui/material';
import { PlotMetrics } from '@components/Dashboard/PlotMetrics/PlotMetrics';
import { StackedBarMetrics } from '@components/Dashboard/StackedBarMetrics/StackedBarMetrics';
import { Map } from '@components/Map/Map';
import { useTranslation } from 'react-i18next';
import useDashboardProgrammeStyle from './DashboardProgramme.style';
import { IconFarm, IconMap, IconSeed } from '@components/Icons';
import { useAppSelector } from '@hooks';
import { selectSeasonId } from '@features/dashboard/dashboardSlice';
export const DashboardProgramme = ({ data }) => {
    const selectedSeasonId = useAppSelector(selectSeasonId);
    const { t } = useTranslation();
    const { classes } = useDashboardProgrammeStyle();
    const renderLollipopTooltip = (item) => `${t('common.harvest')} ${item.x}: ${item.y} ${item.unit}`;
    const sharedGraphProps = { renderTooltipContent: renderLollipopTooltip, currentPeriodId: selectedSeasonId };
    if (!data)
        return _jsx(_Fragment, {});
    return (_jsxs(Box, { children: [_jsx(Typography, Object.assign({ className: classes.title, variant: "h2", color: "secondary" }, { children: t('titles.your-programme') })), _jsxs(Box, Object.assign({ className: classes.programmeItemsContainer }, { children: [_jsxs(Grid, Object.assign({ container: true, columns: 8, columnSpacing: 4, rowSpacing: 8 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 3 }, { children: _jsx(PlotMetrics, { title: t('subtitles.farms'), totalAmount: data.formattedFarmAmount, tooltip: t('tooltips.overview-farms'), icon: _jsx(IconFarm, {}), graphProps: Object.assign({ data: data.farmsAmountPerSeason }, sharedGraphProps) }) })), _jsx(Grid, { item: true, xs: 1 }), _jsx(Grid, Object.assign({ item: true, xs: 3 }, { children: _jsx(PlotMetrics, { title: t('subtitles.total-area'), totalAmount: data.formattedFarmTotalArea, unit: "ha", tooltip: t('tooltips.overview-total-area'), icon: _jsx(IconMap, {}), graphProps: Object.assign({ data: data.farmsTotalAreaPerSeason }, sharedGraphProps) }) })), _jsx(Grid, { item: true, xs: 1 }), _jsx(Grid, Object.assign({ item: true, xs: 3 }, { children: _jsx(StackedBarMetrics, { title: t('subtitles.main-crops'), graphProps: { data: data.crops, currentPeriodId: selectedSeasonId }, tooltip: t('tooltips.overview-main-crops'), icon: _jsx(IconSeed, {}) }) })), _jsx(Grid, { item: true, xs: 1 }), _jsx(Grid, Object.assign({ item: true, xs: 3 }, { children: _jsx(PlotMetrics, { title: t('subtitles.average-area'), totalAmount: data.formattedFarmAverageArea, unit: "ha", tooltip: t('tooltips.overview-average-area'), icon: _jsx(IconMap, {}), graphProps: Object.assign({ data: data.farmsAverageAreaPerSeason }, sharedGraphProps) }) })), _jsx(Grid, { item: true, xs: 1 })] })), _jsx(Box, Object.assign({ className: classes.mapContainer }, { children: _jsx(Map, { data: data.localizations }) }))] }))] }));
};
