var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useLazyUserQuery, useLogoutMutation } from '@services';
import { useLazyGetPermissionsQuery } from '@services/permissions.api';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { PUBLIC_ROUTES } from '@router/routes';
import { matchPath, useLocation } from 'react-router-dom';
export const useAppInit = () => {
    const [initialized, setInitialized] = useState(false);
    const [logout] = useLogoutMutation();
    const [getPermissions] = useLazyGetPermissionsQuery();
    const [getUser] = useLazyUserQuery();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const location = useLocation();
    useEffect(() => {
        function initApp() {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const isAPublicRoute = PUBLIC_ROUTES.some((route) => matchPath({ path: route }, location.pathname));
                    if (!isAPublicRoute) {
                        yield getUser().unwrap();
                        yield getPermissions().unwrap();
                    }
                }
                catch (e) {
                    enqueueSnackbar(t('errors.server-error'), { variant: 'error' });
                    yield logout();
                }
                setInitialized(true);
            });
        }
        initApp();
    }, []);
    return { initialized };
};
