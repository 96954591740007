import { makeStyles } from 'tss-react/mui';
export default makeStyles()((theme) => {
    return {
        name: {
            whiteSpace: 'nowrap',
            color: theme.palette.info.contrastText,
        },
        container: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            width: '100%',
        },
        stackedBarContainer: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: '4px',
            borderRadius: '4px',
        },
        selectedBackground: {
            backgroundColor: '#EDEFF1',
            borderLeft: '2px solid #D1D8DB',
        },
        stackedBar: {
            width: '100%',
            height: '16px',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
        },
        stackedItem: {
            height: '16px',
            '&:not(:last-of-type)': {
                borderRight: `1px solid ${theme.palette.grey[200]}`,
            },
        },
        legendsContainer: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        legendContainer: {
            display: 'flex',
            alignItems: 'center',
            marginRight: '8px',
        },
        legendIcon: {
            width: 8,
            minWidth: 8,
            height: 8,
            borderRadius: '50%',
            marginRight: '4px',
        },
        legend: {
            whiteSpace: 'nowrap',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    };
});
