var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { api, ApiToggle } from '@services/api.service';
export const assignmentApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getContacts: builder.query({
            queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
                var _a, _b, _c, _d;
                return __awaiter(this, void 0, void 0, function* () {
                    try {
                        if (arg.toggle === ApiToggle.ASSOCIATE && arg.farm_id) {
                            const res = yield fetchWithBQ(`/private/assignments/associate/${arg.farm_id}`);
                            return { data: res.data };
                        }
                        if (arg.toggle === ApiToggle.PARTNER && arg.invitation_id) {
                            const res = yield fetchWithBQ(`/private/assignments/partner/${arg.invitation_id}`);
                            return { data: res.data };
                        }
                        return { data: null };
                    }
                    catch (e) {
                        return {
                            error: {
                                status: (_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.status) !== null && _b !== void 0 ? _b : 500,
                                data: (_d = (_c = e === null || e === void 0 ? void 0 : e.response) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : 'Could not fetch members with assignments',
                            },
                        };
                    }
                });
            },
            providesTags: ['Assignment'],
        }),
        assignContacts: builder.mutation({
            queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
                var _a, _b, _c, _d;
                return __awaiter(this, void 0, void 0, function* () {
                    try {
                        if (arg && arg.toggle === ApiToggle.ASSOCIATE && arg.farm_id) {
                            const res = yield fetchWithBQ({
                                url: `/private/assignments/associate`,
                                method: 'POST',
                                body: {
                                    farm_id: arg.farm_id,
                                    user_ids: arg.contact_ids,
                                },
                            });
                            return { data: res.data };
                        }
                        if (arg && arg.toggle === ApiToggle.PARTNER && arg.invitation_id) {
                            const res = yield fetchWithBQ({
                                url: `/private/assignments/partner`,
                                method: 'POST',
                                body: {
                                    invitation_id: arg.invitation_id,
                                    partner_user_ids: arg.contact_ids,
                                },
                            });
                            return { data: res.data };
                        }
                        return { data: null };
                    }
                    catch (e) {
                        return {
                            error: {
                                status: (_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.status) !== null && _b !== void 0 ? _b : 500,
                                data: (_d = (_c = e === null || e === void 0 ? void 0 : e.response) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : 'Could not update contacts',
                            },
                        };
                    }
                });
            },
            invalidatesTags: ['Assignment', 'Farmer', 'Farmers'],
        }),
        removeContacts: builder.mutation({
            queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
                var _a, _b, _c, _d;
                return __awaiter(this, void 0, void 0, function* () {
                    try {
                        if (arg.toggle === ApiToggle.ASSOCIATE && arg.farm_id) {
                            const res = yield fetchWithBQ({
                                url: `/private/assignments/associate`,
                                method: 'DELETE',
                                body: {
                                    farm_id: arg.farm_id,
                                    farm_user_ids: arg.contact_ids,
                                },
                            });
                            return { data: res.data };
                        }
                        if (arg.toggle === ApiToggle.PARTNER && arg.invitation_id) {
                            const res = yield fetchWithBQ({
                                url: `/private/assignments/partner`,
                                method: 'DELETE',
                                body: {
                                    invitation_id: arg.invitation_id,
                                    partner_assignment_ids: arg.contact_ids,
                                },
                            });
                            return { data: res.data };
                        }
                        return { data: null };
                    }
                    catch (e) {
                        return {
                            error: {
                                status: (_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.status) !== null && _b !== void 0 ? _b : 500,
                                data: (_d = (_c = e === null || e === void 0 ? void 0 : e.response) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : 'Could not delete contacts',
                            },
                        };
                    }
                });
            },
            invalidatesTags: ['Assignment', 'Farmer', 'Farmers'],
        }),
    }),
});
export const { useGetContactsQuery, useAssignContactsMutation, useRemoveContactsMutation } = assignmentApi;
