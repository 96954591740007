import { makeStyles } from 'tss-react/mui';
export default makeStyles()((theme) => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        },
        infoIcon: {
            height: '19px !important',
            width: '19px',
            paddingLeft: theme.spacing(1),
        },
    };
});
