export const round = (number, precision, roundType) => {
    const multiplier = Math.pow(10, precision || 0);
    switch (roundType) {
        case 'floor':
            return Math.floor(number * multiplier) / multiplier;
        case 'ceil':
            return Math.ceil(number * multiplier) / multiplier;
        default:
            return Math.round(number * multiplier) / multiplier;
    }
};
export const isNumberBetweenNegative1And1 = (number) => {
    return number > -1 && number < 1;
};
export const roundUpIfPositiveDownIfNegative = (number, precision) => {
    if (number >= 0) {
        return round(number, precision);
    }
    return -round(Math.abs(number), precision);
};
export const formatNumber = (number, locale) => new Intl.NumberFormat(locale).format(number);
