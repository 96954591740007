var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal } from '@components/Modal/Modal';
import { InviteMembersModalLoader } from '@features/invite/member/Modal/InviteMembersModalLoader';
import { useInviteMembersFormData, } from '@features/invite/member/Modal/useInviteMembersFormData';
import { ResendMemberEmailsForm } from '@features/invite/member/ResendEmailsForm/ResendMemberEmailsForm';
import { SelectMemberEmailsForm } from '@features/invite/member/SelectMemberEmailsForm/SelectMemberEmailsForm';
import { Box, Button, CircularProgress } from '@mui/material';
import { useGetAllMembersPendingInvitationsQuery, useInviteMembersMutation, useResendMembersMutation, } from '@services/invitation.api';
import { useSnackbar } from 'notistack';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useInviteMembersModalStyles } from './InviteMembersModal.style';
import { useGetAssignableRoles } from '@hooks/useGetAssignableRoles';
export const InviteMembersModal = ({ open, onClose, shouldResend = false }) => {
    const { t } = useTranslation();
    const { classes } = useInviteMembersModalStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { data: pendingMembers, isLoading: isLoadingPendingMembers, isError: isErrorPendingMembers, } = useGetAllMembersPendingInvitationsQuery();
    const { data: roles, isLoading: isLoadingRoles, isError: isErrorRoles } = useGetAssignableRoles();
    const isLoading = isLoadingPendingMembers || isLoadingRoles;
    const isError = isErrorPendingMembers || isErrorRoles;
    const noData = !pendingMembers || !roles;
    const [invite, { isLoading: isLoadingInviteMutation }] = useInviteMembersMutation();
    const [resend, { isLoading: isLoadingResendMutation }] = useResendMembersMutation();
    const isMutationLoading = isLoadingInviteMutation || isLoadingResendMutation;
    const { methods } = useInviteMembersFormData({ shouldResend });
    const isFormValid = Object.keys(methods.formState.errors).length === 0 &&
        (shouldResend
            ? !!methods.watch('invitationIds') && methods.watch('invitationIds').length > 0
            : !!methods.watch('emails') && methods.watch('emails').length > 0);
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            let amountSent = 0;
            let amountResent = 0;
            if (shouldResend && 'invitationIds' in data) {
                yield resend({ ids: data.invitationIds }).unwrap();
                amountResent = data.invitationIds.length;
            }
            if ('emails' in data) {
                const computedInvitations = data.emails.map((email) => ({
                    target_app: 'PARTNER_PORTAL',
                    email,
                    role_id: data.roleId,
                }));
                yield invite(computedInvitations).unwrap();
                amountSent = data.emails.length;
            }
            handleClose();
            if (amountSent)
                enqueueSnackbar(t('snackbar.sent-success', { count: amountSent }), { variant: 'success' });
            if (amountResent)
                enqueueSnackbar(t('snackbar.resent-success', { count: amountResent }), { variant: 'success' });
        }
        catch (e) {
            enqueueSnackbar(t('errors.server-error'), { variant: 'error' });
        }
    });
    const handleClose = () => {
        methods.reset();
        onClose();
    };
    if (isLoading)
        return _jsx(InviteMembersModalLoader, { open: open, onClose: onClose });
    if (open && (isError || (!isLoading && noData))) {
        enqueueSnackbar(t('errors.server-error'), { variant: 'error' });
        onClose();
    }
    return (_jsx(Modal, Object.assign({ open: open, onClose: handleClose, title: shouldResend ? t('members.invite.resend-invite') : t('members.invite.invite-members') }, { children: _jsx(FormProvider, Object.assign({}, methods, { children: _jsxs("form", Object.assign({ onSubmit: methods.handleSubmit(onSubmit) }, { children: [_jsx(Box, Object.assign({ className: classes.inputContainer }, { children: shouldResend ? _jsx(ResendMemberEmailsForm, {}) : _jsx(SelectMemberEmailsForm, {}) })), _jsxs(Box, Object.assign({ display: "flex", justifyContent: "flex-end", className: classes.buttonsContainer }, { children: [_jsx(Button, Object.assign({ color: "info", onClick: onClose }, { children: t('buttons.cancel') })), _jsx(Button, Object.assign({ disabled: !isFormValid || isMutationLoading, type: "submit" }, { children: isMutationLoading ? (_jsx(CircularProgress, { className: classes.buttonLoader, size: 20 })) : (t('buttons.send')) }))] }))] })) })) })));
};
