import { jsx as _jsx } from "react/jsx-runtime";
import { Table } from '@components/Table/Table';
import { TableLoader } from '@components/Table/TableLoader';
import { useFarmersTableStyles } from '@features/farmer/listFarmers/Table/FarmersTable.style';
import { changePage, selectFarmersListPage } from '@features/farmer/listFarmers/farmersList.slice';
import { useAppDispatch, useAppSelector } from '@hooks';
import { usePermission } from '@hooks/usePermission';
import { Box } from '@mui/material';
import { ROUTES } from '@router/routes';
import { FARMER_STATUS } from '@utils/constants';
import { useNavigate } from 'react-router-dom';
import { useFarmersTableData } from './useFarmersTableData';
const PAGE_SIZE = 50;
export const FarmersTable = () => {
    const { classes } = useFarmersTableStyles();
    const { columns, rows, getRowId, isLoading, isFetching, count } = useFarmersTableData();
    const navigate = useNavigate();
    const { canSeeAssociateFarmers, canSeeAssociateAssignedFarmers } = usePermission();
    const dispatch = useAppDispatch();
    const page = useAppSelector(selectFarmersListPage);
    const onPageChange = (page) => {
        dispatch(changePage({ page }));
    };
    const handleGoToProfile = ({ row }) => {
        const invitationId = row === null || row === void 0 ? void 0 : row.invitation_id;
        const userId = row === null || row === void 0 ? void 0 : row.user_id;
        if (row.status !== FARMER_STATUS.INVITATION_REFUSED) {
            const queryString = (canSeeAssociateFarmers || canSeeAssociateAssignedFarmers) && userId
                ? `?userId=${userId}`
                : invitationId
                    ? `?invitationId=${invitationId}`
                    : '';
            navigate(`${ROUTES.FARMER_PROFILE}${queryString}`);
        }
    };
    return (_jsx(Box, Object.assign({ style: { height: '100%' } }, { children: _jsx(Table, { className: classes.farmersTable, page: page, rowsPerPageOptions: [PAGE_SIZE], pageSize: PAGE_SIZE, onRowClick: handleGoToProfile, rowCount: count || 0, columns: columns, rows: isLoading || isFetching ? [] : rows || [], autoHeight: false, getRowId: getRowId, loading: isLoading || isFetching, pagination: true, paginationMode: "server", onPageChange: onPageChange, hideFooter: false, components: { LoadingOverlay: TableLoader }, getRowClassName: (params) => `row-${params.row.status}`, disableVirtualization: true }) })));
};
