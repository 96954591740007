import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { BoxedIcon } from '@components/BoxedIcon';
import { IconCheck } from '@components/Icons';
import { AlarmingDataChecksList } from '@features/alarmingData/components/AlarmingDataChecksList/AlarmingDataChecksList';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useAlarmingDataGroupedByTypeStyles } from './AlarmingDataGroupedByType.style';
const AlarmingDataTypeSection = ({ data, title }) => {
    const { classes } = useAlarmingDataGroupedByTypeStyles();
    return (_jsx(_Fragment, { children: data.length > 0 && (_jsxs(_Fragment, { children: [_jsx(Typography, Object.assign({ variant: "h3" }, { children: title })), _jsx("div", Object.assign({ className: classes.list }, { children: _jsx(AlarmingDataChecksList, { data: data }) }))] })) }));
};
export const AlarmingDataGroupedByType = ({ data, className }) => {
    const { t } = useTranslation();
    const { classes } = useAlarmingDataGroupedByTypeStyles();
    const { yoy, benchmark } = data;
    if (yoy.length < 1 && benchmark.length < 1)
        return (_jsxs("div", Object.assign({ className: classes.noDataContainer }, { children: [_jsx(BoxedIcon, { className: classes.noDataIcon, variant: "success", icon: _jsx(IconCheck, { color: "success" }) }), _jsx(Typography, { children: t('alarming-data.no-data') })] })));
    return (_jsxs("div", Object.assign({ className: clsx(className) }, { children: [_jsx(AlarmingDataTypeSection, { data: yoy, title: t('alarming-data.yoy-comparison') }), _jsx(AlarmingDataTypeSection, { data: benchmark, title: t('alarming-data.benchmark-comparison') })] })));
};
