export const ROUTES = {
    HOME: '/',
    LOGIN: '/login',
    SIGNUP: '/signup',
    FORGOT_PASSWORD: '/forgot-password',
    FORGOT_PASSWORD_TOKEN: '/forgot-password/:resetToken',
    ACCEPT_INVITE: '/accept-invite/:id',
    REGISTER_INVITE: '/register-invite/:id',
    FARMERS: '/farmers',
    FARMER_PROFILE: `/farmers/profile`,
    VISIT_FARM: `/visit-farm`,
    ALARMING_DATA: '/alarming-data/:userId/:farmSeasonId',
    MEMBERS: '/members',
    SETTINGS: '/settings',
    MOBILE_HARDSTOP: '/mobile-hardstop',
};
export const PUBLIC_ROUTES = [
    ROUTES.LOGIN,
    ROUTES.SIGNUP,
    ROUTES.FORGOT_PASSWORD,
    ROUTES.FORGOT_PASSWORD_TOKEN,
    ROUTES.ACCEPT_INVITE,
    ROUTES.REGISTER_INVITE,
    ROUTES.MOBILE_HARDSTOP,
];
