class UnauthorizedError extends Error {
    constructor(message, code, data) {
        super(message !== null && message !== void 0 ? message : 'Unauthorized');
        this.code = code;
        this.data = data;
        this.name = 'UnauthorizedError';
        this.code = code;
        this.data = data;
    }
}
export default UnauthorizedError;
