var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Avatar as MUIAvatar } from '@mui/material';
import { useAvatarStyle } from './Avatar.style';
import { forwardRef } from 'react';
export const Avatar = forwardRef((_a, ref) => {
    var { className, size = 'medium' } = _a, rest = __rest(_a, ["className", "size"]);
    const { classes, cx } = useAvatarStyle();
    return (_jsx(MUIAvatar, Object.assign({ component: "div", ref: ref, className: cx(classes.avatar, size === 'small' && classes.small, size === 'medium' && classes.medium, size === 'large' && classes.large, className) }, rest)));
});
Avatar.displayName = 'Avatar';
