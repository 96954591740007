import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { INITIAL_SETUP_STATUSES, useInitialSetupStatus, } from '@features/farmer/profile/farmSeasonStatus/InitialSetupStatus/useInitialSetupStatus';
import { DashboardStep } from '@features/farmer/profile/components/FarmerDashboard/Step/DashboardStep';
import GridViewIcon from '@mui/icons-material/GridView';
import CheckCircle from '@mui/icons-material/CheckCircle';
import useCollapsableStyles from '@features/farmer/profile/components/FarmerDashboard/Collapsable.style';
import { Box } from '@mui/material';
export const INITIAL_SETUP_TEST_ID = {
    IN_PROGRESS: 'data-validation-in-progress',
    NOT_STARTED: 'data-validation-not-started',
    DONE: 'data-validation-done',
};
export const InitialSetupStatus = (props) => {
    const { classes } = useCollapsableStyles();
    const { initialSetupStatus } = useInitialSetupStatus(props);
    return (_jsxs(_Fragment, { children: [_jsx(DashboardStep, { icon: initialSetupStatus.status === INITIAL_SETUP_STATUSES.IN_PROGRESS ? (_jsx(GridViewIcon, { "data-testid": INITIAL_SETUP_TEST_ID.IN_PROGRESS, className: classes.icon })) : initialSetupStatus.status === INITIAL_SETUP_STATUSES.NOT_STARTED ? (_jsx(GridViewIcon, { "data-testid": INITIAL_SETUP_TEST_ID.NOT_STARTED, className: classes.greyIcon })) : (_jsx(CheckCircle, { "data-testid": INITIAL_SETUP_TEST_ID.DONE, className: classes.checkIcon })), stepStatus: initialSetupStatus }), _jsx(Box, { className: classes.stepConnector })] }));
};
