import { makeStyles } from 'tss-react/mui';
export const useAlarmingDataPageStyles = makeStyles()((theme) => ({
    topbar: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        padding: theme.spacing(3),
        backgroundColor: theme.palette.layout.main,
    },
    content: {
        display: 'flex',
        padding: theme.spacing(6),
        backgroundColor: theme.palette.common.white,
    },
    errorContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginTop: theme.spacing(8),
    },
    sidePanel: {
        zIndex: 1,
        position: 'fixed',
        top: 75,
        left: 0,
        height: `calc(100vh - 75px)`,
    },
    section: {
        zIndex: 0,
        marginTop: theme.spacing(15),
        marginLeft: '300px',
    },
}));
