import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import TopBar from '@components/TopBar/TopBar';
import { useAlarmingDataPageStyles } from '@/pages/AlarmingDataPage/AlarmingDataPage.style';
import { AlarmingDataSidePanel } from '@/pages/AlarmingDataPage/AlarmingDataSidePanel/AlarmingDataSidePanel';
import { AlarmingDataGroupedByResolution } from '@features/alarmingData/components/AlarmingDataGroupedByResolution/AlarmingDataGroupedByResolution';
import { useAlarmingDataPageView } from '@/pages/AlarmingDataPage/useAlarmingDataPageView';
import LoadingPage from '@/pages/LoadingPage/LoadingPage';
import ErrorCard from '@components/ErrorCard/ErrorCard';
export const AlarmingDataPage = () => {
    const { classes } = useAlarmingDataPageStyles();
    const { farmer, selectedFarmSeason, hasData, isLoading, alarmingData } = useAlarmingDataPageView();
    if (isLoading)
        return _jsx(LoadingPage, {});
    return (_jsxs(_Fragment, { children: [_jsx(TopBar, { className: classes.topbar }), _jsx("div", Object.assign({ className: classes.content }, { children: hasData ? (_jsxs(_Fragment, { children: [_jsx(AlarmingDataSidePanel, { className: classes.sidePanel, farmer: farmer, selectedFarmSeasonId: selectedFarmSeason.id }), _jsx(AlarmingDataGroupedByResolution, { className: classes.section, farmSeason: selectedFarmSeason, alarmingData: alarmingData })] })) : (_jsx("div", Object.assign({ className: classes.errorContainer }, { children: _jsx(ErrorCard, {}) }))) }))] }));
};
