import { useEffect, useRef } from 'react';
const useHotjar = () => {
    const isInitialized = useRef(false);
    useEffect(() => {
        //if (process.env.NODE_ENV === 'production' && !isInitialized.current) {
        if (!isInitialized.current) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (function (h, o, t, j, a, r) {
                h.hj =
                    h.hj ||
                        function () {
                            // eslint-disable-next-line prefer-rest-params
                            (h.hj.q = h.hj.q || []).push(arguments);
                        };
                h._hjSettings = { hjid: process.env.HOTJAR_ID, hjsv: process.env.HOTJAR_SV };
                a = o.getElementsByTagName('head')[0];
                r = o.createElement('script');
                r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
                isInitialized.current = true;
            })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
        }
    }, []);
};
export default useHotjar;
