import { makeStyles } from 'tss-react/mui';
const useLabelTextFieldStyle = makeStyles()(() => ({
    label: {
        marginBottom: '10px',
    },
    textField: {
        marginBottom: '16px',
    },
}));
export default useLabelTextFieldStyle;
