const tabsCss = {
    root: {
        backgroundColor: 'transparent',
        borderRadius: '15px 15px 0 0',
    },
    indicator: () => ({
        backgroundColor: '#64370B',
    }),
    flexContainer: () => ({
        boxSizing: 'border-box',
        borderBottom: `2px solid #ecdec5`,
    }),
};
const MuiTabs = { styleOverrides: tabsCss };
export default MuiTabs;
