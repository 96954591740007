import { makeStyles } from 'tss-react/mui';
export const useFarmerProfilePageStyles = makeStyles()((theme) => {
    return {
        container: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
        },
        farmerProfileSection: {
            width: '100%',
            height: '100%',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: '5px',
                backgroundColor: theme.palette.grey[400],
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: '15px',
            },
        },
        header: {
            height: '48px',
            marginBottom: '30px',
        },
        aqcLink: {
            display: 'block',
            marginBottom: theme.spacing(2),
        },
    };
});
