var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SvgIcon } from '@mui/material';
export const IconSeed = (_a) => {
    var { color = 'primary' } = _a, rest = __rest(_a, ["color"]);
    return (_jsx(SvgIcon, Object.assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 21", enableBackground: "new 0 0 20 21", color: color }, rest, { children: _jsxs("g", { children: [_jsx("path", { fill: "currentColor", d: "M4.351,10.747c-1.63,1.63-4.582,7.224-2.952,8.855c1.63,1.629,7.224-1.322,8.854-2.951\n\t\tc1.629-1.631,1.629-4.273,0-5.904C8.623,9.117,5.98,9.117,4.351,10.747z M8.839,15.235c-0.649,0.649-2.269,1.647-3.769,2.261\n\t\tc-0.792,0.324-1.496,0.521-2.015,0.569c-0.046,0.005-0.089,0.007-0.129,0.009c0.002-0.039,0.005-0.082,0.009-0.129\n\t\tc0.048-0.52,0.246-1.224,0.569-2.016c0.613-1.5,1.612-3.119,2.261-3.769c0.524-0.524,1.25-0.723,1.929-0.599l-1.123,1.123\n\t\tc-0.417,0.417-0.415,1.074-0.007,1.482c0.41,0.41,1.071,0.404,1.482-0.008l1.272-1.271C9.633,13.672,9.474,14.602,8.839,15.235z" }), _jsx("path", { fill: "currentColor", d: "M15.724,1.951c-1.63-1.63-4.272-1.63-5.903,0c-1.63,1.63-1.63,4.273,0,5.903\n\t\tc1.63,1.63,7.224,4.582,8.855,2.952C20.305,9.175,17.354,3.582,15.724,1.951z M17.02,9.27c-0.52-0.049-1.224-0.246-2.016-0.569\n\t\tc-1.5-0.613-3.119-1.611-3.769-2.261c-0.58-0.58-0.754-1.404-0.542-2.14l0.865,0.864c0.418,0.417,1.074,0.414,1.482,0.007\n\t\tc0.41-0.41,0.404-1.071-0.007-1.482L12.17,2.824c0.735-0.212,1.56-0.039,2.14,0.542c0.649,0.649,1.647,2.269,2.261,3.769\n\t\tc0.324,0.792,0.521,1.496,0.569,2.015c0.005,0.046,0.007,0.089,0.009,0.129C17.109,9.277,17.066,9.274,17.02,9.27z" }), _jsx("path", { fill: "currentColor", d: "M13.787,14.265c-0.65,0.65-0.65,1.705,0,2.355s2.883,1.828,3.533,1.178s-0.527-2.883-1.178-3.533\n\t\tS14.438,13.614,13.787,14.265z" }), _jsx("path", { fill: "currentColor", d: "M5.32,6.811c0.65-0.65,0.65-1.705,0-2.355s-1.705-0.65-2.355,0S1.136,7.338,1.787,7.988\n\t\tC2.437,8.639,4.669,7.461,5.32,6.811z" })] }) })));
};
