import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CollapsableCard } from '@components/CollapsableCard/CollapsableCard';
import { AlarmingDataButton } from '@features/alarmingData/AlarmingDataButton/AlarmingDataButton';
import { DataValidationStatus } from '@features/farmer/profile/farmSeasonStatus/DataValidationStatus/DataValidationStatus';
import { InitialSetupStatus } from '@features/farmer/profile/farmSeasonStatus/InitialSetupStatus/InitialSetupStatus';
import { OperationalDataStatus } from '@features/farmer/profile/farmSeasonStatus/OperationalDataStatus/OperationalDataStatus';
import { RenewalStatus } from '@features/farmer/profile/farmSeasonStatus/RenewalStatus/RenewalStatus';
import { ResultStatus } from '@features/farmer/profile/farmSeasonStatus/ResultStatus/ResultStatus';
import { useFarmSeasonSteps } from '@features/farmer/profile/farmSeasonStatus/useFarmSeasonSteps';
import { useGetFarmerProfileParamsFromUrl } from '@features/farmer/profile/hooks/useGetFarmerProfileParamsFromUrl';
import { CARBON_RESULT_MODEL, FARMER_STATUS } from '@utils/constants';
import { useTranslation } from 'react-i18next';
export const FarmSeasonCollapsable = ({ farmSeason, previousFarmSeason, isExpanded = false, profile, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { t } = useTranslation();
    const { userId, invitationId } = useGetFarmerProfileParamsFromUrl();
    const id = userId || invitationId;
    const carbonModel = (_c = (_b = (_a = profile === null || profile === void 0 ? void 0 : profile.farm_user) === null || _a === void 0 ? void 0 : _a.farm) === null || _b === void 0 ? void 0 : _b.carbon_result_model) !== null && _c !== void 0 ? _c : CARBON_RESULT_MODEL.CFT;
    const farmerStatus = (_d = profile === null || profile === void 0 ? void 0 : profile.status) !== null && _d !== void 0 ? _d : FARMER_STATUS.CREATED;
    const isBaselineYear = (farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.baseline_farm_season_id) === null;
    const isPartOfCarbonProgramme = !!(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.carbon_programme_year);
    const isHitoricalSeason = ((_e = farmSeason.carbon_programme_year) === null || _e === void 0 ? void 0 : _e.year) !== undefined && farmSeason.carbon_programme_year.year < 0;
    const { currentStep, maxSteps } = useFarmSeasonSteps({ farmSeason, previousFarmSeason, carbonModel });
    const collapsableCardHeader = isBaselineYear
        ? t('labels.baseline-season-years', { years: `${(_f = farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.season) === null || _f === void 0 ? void 0 : _f.harvest_year}` })
        : isHitoricalSeason
            ? t('labels.historical', { years: `${(_g = farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.season) === null || _g === void 0 ? void 0 : _g.harvest_year}` })
            : t('labels.season-count-years', {
                count: (_h = farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.carbon_programme_year) === null || _h === void 0 ? void 0 : _h.year,
                years: `${(_j = farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.season) === null || _j === void 0 ? void 0 : _j.harvest_year}`,
            });
    const statusProps = {
        farmSeason,
        previousFarmSeason,
        carbonModel,
        farmerStatus,
    };
    if (!isPartOfCarbonProgramme)
        return null;
    return (_jsx(CollapsableCard, Object.assign({ isExpanded: isExpanded, header: collapsableCardHeader, action: !!id && _jsx(AlarmingDataButton, { userOrInvitationId: id, farmSeasonId: Number(farmSeason.id) }), currentStep: isHitoricalSeason ? 0 : currentStep, maxSteps: isHitoricalSeason ? 0 : maxSteps }, { children: !isHitoricalSeason && (_jsxs(_Fragment, { children: [_jsx(RenewalStatus, Object.assign({}, statusProps)), _jsx(InitialSetupStatus, Object.assign({}, statusProps)), _jsx(OperationalDataStatus, Object.assign({}, statusProps, { profile: profile })), _jsx(DataValidationStatus, Object.assign({}, statusProps)), _jsx(ResultStatus, Object.assign({}, statusProps, { profile: profile }))] })) })));
};
