var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import useBoxedIconStyle from './BoxedIcon.style';
import clsx from 'clsx';
export const BoxedIcon = (_a) => {
    var { icon, variant = 'neutral', className } = _a, rest = __rest(_a, ["icon", "variant", "className"]);
    const { classes } = useBoxedIconStyle({ variant });
    return (_jsx(Box, Object.assign({ className: clsx(classes.container, className) }, rest, { children: icon })));
};
