import { api } from '@services';
export const userApi = api.injectEndpoints({
    endpoints: (builder) => ({
        updateUser: builder.mutation({
            query: (userData) => ({
                url: `/public/users/${userData.userId}`,
                method: 'PUT',
                body: userData.body,
            }),
        }),
        getUserNameById: builder.query({
            query: (userId) => {
                return {
                    url: `/public/users/${userId}/name`,
                    method: 'GET',
                };
            },
        }),
    }),
});
export const { useUpdateUserMutation, useGetUserNameByIdQuery } = userApi;
