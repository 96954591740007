import { makeStyles } from 'tss-react/mui';
export const useConfirmFarmerRemovalModalStyles = makeStyles()((theme) => {
    return {
        contentContainer: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            backgroundColor: '#f6f3E5',
            borderRadius: '15px',
            padding: '30px',
        },
        buttonsContainer: {
            marginTop: '30px',
            '> .MuiButton-root:not(:last-child)': {
                marginRight: '16px',
            },
        },
        title: {
            marginBottom: '30px',
        },
        cancelBtn: {
            backgroundColor: 'white',
            border: 0,
            '&:focus, :hover': {
                backgroundColor: theme.palette.grey[300],
                border: 0,
            },
        },
    };
});
