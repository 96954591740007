import { makeStyles } from 'tss-react/mui';
export const useSelectedChipsStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        overflow: 'hidden',
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        overflow: 'hidden',
        paddingRight: theme.spacing(2),
        gap: theme.spacing(1),
    },
    chipContainerGrow: {
        flexWrap: 'wrap',
    },
    actionContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    chip: {
        paddingRight: theme.spacing(1),
    },
    chipDelete: {
        width: 12,
    },
    reset: {
        width: 12,
        marginLeft: 'auto',
        padding: theme.spacing(1),
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
