import { makeStyles } from 'tss-react/mui';
export const useAlarmingDataCheckAccordionStyles = makeStyles()((theme) => ({
    accordion: {
        padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
        boxShadow: '0px 4px 16px 4px rgba(50, 63, 69, 0.04)',
        borderRadius: '8px',
        '&:before': {
            display: 'none',
        },
    },
    accordionSummary: {
        minHeight: 40,
        maxHeight: 40,
        '&.Mui-expanded': {
            minHeight: 40,
            maxHeight: 40,
        },
        '& .MuiAccordionSummary-content': {
            margin: 0,
            alignItems: 'center',
        },
        flexDirection: 'row-reverse',
        gap: theme.spacing(4),
    },
    chevron: {
        fontSize: '12px',
    },
    sub: {
        backgroundColor: theme.palette.grey['200'],
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '24px',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginLeft: theme.spacing(3),
    },
    subIcon: {
        fontSize: '12px',
        color: theme.palette.primary.main,
    },
    title: {
        fontWeight: 'bold',
    },
    accordionDetails: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
    },
}));
