import { makeStyles } from 'tss-react/mui';
export const useNewModalStyles = makeStyles()((theme) => ({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 456,
        margin: 'auto',
        padding: theme.spacing(5),
        backgroundColor: '#f6f3E5',
        borderRadius: '15px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    close: {
        width: 12,
        cursor: 'pointer',
        color: theme.palette.secondary.main,
    },
    title: {},
    body: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(5),
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    confirm: {},
    cancel: {
        textDecoration: 'underline',
        textUnderlineOffset: '0.2em',
        '&:hover': {
            textDecoration: 'underline',
            textUnderlineOffset: '0.2em',
        },
    },
}));
