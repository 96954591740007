import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    cohortId: undefined,
    regionCode: undefined,
    contactId: undefined,
    status: undefined,
    search: undefined,
    page: 0,
};
export const farmersListSlice = createSlice({
    name: 'farmersList',
    initialState,
    reducers: {
        changeCohort: (state, action) => {
            var _a;
            state.cohortId = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.cohortId;
        },
        changeRegion: (state, action) => {
            var _a;
            state.regionCode = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.regionCode;
        },
        changeContact: (state, action) => {
            var _a;
            state.contactId = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.contactId;
        },
        changeStatus: (state, action) => {
            var _a;
            state.status = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.status;
        },
        changeSearch: (state, action) => {
            var _a;
            state.search = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.search;
        },
        changePage: (state, action) => {
            var _a;
            state.page = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.page;
        },
    },
});
export const selectFarmersListCohortId = (state) => state.farmersList.cohortId;
export const selectFarmersListRegionCode = (state) => state.farmersList.regionCode;
export const selectFarmersListContactId = (state) => state.farmersList.contactId;
export const selectFarmersListStatus = (state) => state.farmersList.status;
export const selectFarmersListSearch = (state) => state.farmersList.search;
export const selectFarmersListPage = (state) => state.farmersList.page;
export const { changeCohort, changeRegion, changeContact, changeStatus, changeSearch, changePage } = farmersListSlice.actions;
export const farmersListReducer = farmersListSlice.reducer;
