import { selectFarmersListCohortId, selectFarmersListContactId, selectFarmersListPage, selectFarmersListRegionCode, selectFarmersListSearch, selectFarmersListStatus, } from '@features/farmer/listFarmers/farmersList.slice';
import { useAppSelector } from '@hooks/redux';
import { usePermission } from '@hooks/usePermission';
import { ApiToggle } from '@services';
import { useGetFarmersQuery } from '@services/farmer.api';
import { skipToken } from '@reduxjs/toolkit/query';
export const useGetFarmers = ({ shouldFilter = false } = {}) => {
    const { canSeeAllPartnerFarmers, canSeeAssignedPartnerFarmers, canSeeAssociateFarmers, canSeeAssociateAssignedFarmers, } = usePermission();
    const cohortId = useAppSelector(selectFarmersListCohortId);
    const regionCode = useAppSelector(selectFarmersListRegionCode);
    const contactId = useAppSelector(selectFarmersListContactId);
    const status = useAppSelector(selectFarmersListStatus);
    const search = useAppSelector(selectFarmersListSearch);
    const page = useAppSelector(selectFarmersListPage);
    const hasPage = page || page === 0;
    const trimmedSearch = search ? encodeURIComponent(search === null || search === void 0 ? void 0 : search.trim()) : '';
    const queryString = `${cohortId || regionCode || contactId || status || trimmedSearch || hasPage ? '?' : ''}${cohortId ? `cohortId=${cohortId}&` : ''}${regionCode ? `regionCode=${regionCode}&` : ''}${contactId ? `contactId=${contactId}&` : ''}${status ? `status=${status}&` : ''}${search ? `search=${trimmedSearch}&` : ''}${hasPage ? `page=${page}&` : ''}`.replace(/&$/, '');
    const params = canSeeAllPartnerFarmers || canSeeAssignedPartnerFarmers
        ? Object.assign({ toggle: ApiToggle.PARTNER }, (shouldFilter && { queryString })) : canSeeAssociateFarmers || canSeeAssociateAssignedFarmers
        ? Object.assign({ toggle: ApiToggle.ASSOCIATE }, (shouldFilter && { queryString })) : skipToken;
    const { data, isLoading, isFetching, isError } = useGetFarmersQuery(params);
    return {
        data: data === null || data === void 0 ? void 0 : data.farmers,
        count: data === null || data === void 0 ? void 0 : data.results,
        isLoading,
        isFetching,
        isError,
    };
};
