var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ApiToggle, api } from '@services/api.service';
export const cohortApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getCohorts: builder.query({
            queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
                return __awaiter(this, void 0, void 0, function* () {
                    let result = [];
                    if (arg.toggle === ApiToggle.PARTNER) {
                        const partnerResult = yield fetchWithBQ(`/private/cohorts`);
                        result = partnerResult.data;
                    }
                    else if (arg.toggle === ApiToggle.ASSOCIATE) {
                        const associateResult = yield fetchWithBQ('/private/cohorts/associate');
                        result = associateResult.data;
                    }
                    return { data: result };
                });
            },
        }),
    }),
});
export const { useGetCohortsQuery } = cohortApi;
