const tabCss = {
    root: {
        backgroundColor: 'transparent',
    },
    textColorSecondary: ({ theme }) => ({
        color: '#b07c48',
        '&.Mui-selected': {
            color: '#64370B',
        },
        '&:hover': {
            color: `${theme.palette.primary.main} !important`,
        },
        fontWeight: 'bold',
        transition: 'color .3s ease',
    }),
};
const MuiTab = { styleOverrides: tabCss };
export default MuiTab;
