var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Chip, Tooltip } from '@mui/material';
import { IconCross } from '@components/Icons';
import { ConditionalWrapper } from '@components/ConditionalWrapper/ConditionalWrapper';
import { useSelectedChipsStyles } from './SelectedChips.style';
import { RemainingChipsMessage } from '@components/Form/RemainingChipsMessage/RemainingChipsMessage';
import { useSelectedChips } from '@hooks/useSelectedChips';
const SelectedChip = (_a) => {
    var { chip, shouldDisplayTooltip = false, onChipDelete, TooltipProps } = _a, rest = __rest(_a, ["chip", "shouldDisplayTooltip", "onChipDelete", "TooltipProps"]);
    const { classes } = useSelectedChipsStyles();
    return (_jsx(ConditionalWrapper, Object.assign({ condition: shouldDisplayTooltip, wrapper: (children) => (_jsx(Tooltip, Object.assign({ title: chip.value, placement: "bottom", arrow: true }, TooltipProps, { children: children }))) }, { children: _jsx(Chip, Object.assign({ className: classes.chip, label: chip.value, onDelete: () => onChipDelete === null || onChipDelete === void 0 ? void 0 : onChipDelete(chip), deleteIcon: _jsx(IconCross, { "data-testid": "delete-chip-button", className: classes.chipDelete, onMouseDown: (event) => event.stopPropagation() }) }, rest)) })));
};
export const ResetButton = ({ className, onReset }) => {
    const { classes, cx } = useSelectedChipsStyles();
    return (_jsx(IconCross, { "data-testid": "reset-button", onMouseDown: (event) => event.stopPropagation(), className: cx(classes.reset, className), onClick: onReset }));
};
export const SelectedChips = ({ chips, maxChips, onChipDelete, onReset, remainingChipsLabel, shouldGrow = false, availableWidthInPercentage = 100, }) => {
    const { classes, cx } = useSelectedChipsStyles();
    const { chipsToDisplay, remainingChips, maxCalculatedWithForAChip } = useSelectedChips({
        chips,
        maxChips,
        availableWidthForChipsPercentage: availableWidthInPercentage,
    });
    return (_jsxs(Box, Object.assign({ className: classes.container }, { children: [_jsxs(Box, Object.assign({ className: cx(classes.chipContainer, shouldGrow && classes.chipContainerGrow) }, { children: [chipsToDisplay.map((chip) => (_jsx(SelectedChip, { chip: chip, shouldDisplayTooltip: true, TooltipProps: {
                            placement: 'top',
                        }, onChipDelete: onChipDelete, sx: {
                            maxWidth: shouldGrow ? 'auto' : maxCalculatedWithForAChip,
                        } }, chip.key))), remainingChips.length > 0 && (_jsx(RemainingChipsMessage, { remainingChips: remainingChips, label: remainingChipsLabel, TooltipProps: {
                            placement: 'top',
                        } }))] })), _jsx(Box, Object.assign({ className: classes.actionContainer }, { children: onReset && chips.length > 0 && _jsx(ResetButton, { onReset: onReset }) }))] })));
};
