import { makeStyles } from 'tss-react/mui';
export default makeStyles()((theme) => {
    return {
        statisticContainer: {
            display: 'flex',
            position: 'relative',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '20px',
            marginTop: '20px',
        },
        inactiveStatisticContainer: {
            opacity: '0.75',
        },
        leftCaption: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            marginRight: '15px',
            marginLeft: '1px',
            fontSize: '29px',
            color: theme.palette.secondary.main,
        },
        label: {
            alignSelf: 'center',
            color: theme.palette.primary.main,
        },
        greyLabel: {
            alignSelf: 'center',
            color: theme.palette.grey[500],
        },
        statistic: {
            alignSelf: 'end',
            color: theme.palette.primary.main,
        },
        greyStatistic: {
            color: theme.palette.grey[500],
            alignSelf: 'end',
        },
        stepProgressContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: theme.spacing(2),
        },
    };
});
