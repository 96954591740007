import { makeStyles } from 'tss-react/mui';
export const useAssignContactButtonStyles = makeStyles()((theme) => ({
    editButton: {
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        minWidth: 'auto',
    },
    editIcon: {
        width: 18,
    },
}));
