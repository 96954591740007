import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { StackedBarGraph, StackedItemLegend } from './StackedBarGraph';
import useStackedBarGraphStyle from './StackedBarGraph.style';
export const StackedBarGraphGroup = ({ data, currentPeriodId }) => {
    var _a, _b;
    const { classes } = useStackedBarGraphStyle();
    const lastElement = data[data.length - 1];
    const selectedElement = (_a = data.filter((val) => val.id === currentPeriodId)) === null || _a === void 0 ? void 0 : _a[0];
    if (!lastElement)
        throw new Error('StackedBarGraphGroup component should have more than one item in data props');
    return (_jsxs(_Fragment, { children: [data.map((v) => (_jsx(StackedBarGraph, Object.assign({ showLegend: false, isSelected: currentPeriodId === v.id }, v), v.name))), _jsx(Box, Object.assign({ className: classes.legendsContainer, mt: 1 }, { children: (_b = selectedElement === null || selectedElement === void 0 ? void 0 : selectedElement.items) === null || _b === void 0 ? void 0 : _b.map((item) => (_jsx(StackedItemLegend, { label: item.label, color: item.color }, item.label))) }))] }));
};
