import { makeStyles } from 'tss-react/mui';
export const useAlarmingDataSelectionStyles = makeStyles()((theme) => ({
    container: {
        paddingLeft: theme.spacing(5),
    },
    selectAll: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    dataList: {
        marginTop: theme.spacing(3),
        listStyle: 'none',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
    },
    selectableData: {
        borderTop: `1px solid ${theme.palette.grey['200']}`,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    infos: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    iconMinus: {
        padding: theme.spacing(1),
    },
    button: {
        minWidth: 'auto',
        padding: theme.spacing(3),
        marginLeft: 'auto',
    },
}));
