import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import clsx from 'clsx';
import { IconChevronDown } from '@components/Icons';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { useAlarmingDataCheckAccordionStyles } from '@features/alarmingData/components/AlarmingDataCheck/AlarmingDataCheckAccordion.style';
export const AlarmingDataCheckAccordion = ({ className, title, count, children, }) => {
    const { classes } = useAlarmingDataCheckAccordionStyles();
    return (_jsxs(Accordion, Object.assign({ disableGutters: true, className: clsx(classes.accordion, className) }, { children: [_jsxs(AccordionSummary, Object.assign({ className: classes.accordionSummary, expandIcon: _jsx(IconChevronDown, { className: classes.chevron }), "aria-controls": `panel1a-content-${title}`, id: `panel1a-header-${title}` }, { children: [_jsx(Typography, Object.assign({ className: classes.title }, { children: title })), _jsxs("div", Object.assign({ className: classes.sub }, { children: [_jsx(SubdirectoryArrowRightIcon, { className: classes.subIcon }), _jsx(Typography, Object.assign({ fontWeight: 900, component: "span", variant: "body2" }, { children: count }))] }))] })), _jsx(AccordionDetails, Object.assign({ className: classes.accordionDetails }, { children: children }))] })));
};
