const buttonCss = {
    root: {
        textTransform: 'none',
        borderRadius: '10px',
    },
    text: {
        fontSize: '14px',
    },
    contained: {
        textTransform: 'none',
        padding: '16px 30px 16px',
        lineHeight: 1,
        '&:hover': {},
    },
    containedPrimary: ({ theme }) => ({
        '&.Mui-disabled': {
            pointerEvents: 'unset',
            cursor: 'not-allowed',
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            opacity: 0.5,
        },
        background: theme.palette.primary.main,
        ':hover': {
            background: theme.palette.primary.dark,
        },
    }),
    containedSecondary: ({ theme }) => ({
        '&.Mui-disabled': {
            pointerEvents: 'unset',
            cursor: 'not-allowed',
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            opacity: 0.5,
        },
        background: theme.palette.secondary.main,
        ':hover': {
            background: theme.palette.secondary.dark,
        },
    }),
    containedInfo: ({ theme }) => ({
        '&.Mui-disabled': {
            pointerEvents: 'unset',
            cursor: 'not-allowed',
            background: theme.palette.info.main,
            color: theme.palette.info.contrastText,
            opacity: 0.5,
        },
        background: theme.palette.info.main,
        ':hover': {
            background: theme.palette.info.dark,
        },
    }),
    containedSuccess: ({ theme }) => ({
        '&.Mui-disabled': {
            pointerEvents: 'unset',
            cursor: 'not-allowed',
            background: theme.palette.success.main,
            color: theme.palette.success.contrastText,
            opacity: 0.5,
        },
        background: theme.palette.success.main,
        ':hover': {
            background: theme.palette.success.dark,
        },
    }),
};
const buttonProperties = {
    variant: 'contained',
    disableElevation: true,
};
const MuiButton = { styleOverrides: buttonCss, defaultProps: buttonProperties };
export default MuiButton;
