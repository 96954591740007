import { PRODUCT_PLAN, PRODUCT_PLAN_STATUSES } from '@services';
import { formatDate } from '@utils/date';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@hooks';
export const useRenewalStatus = ({ farmSeason, previousFarmSeason }) => {
    var _a;
    const { t } = useTranslation();
    const { user } = useAuth();
    const isBaselineYear = (farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.baseline_farm_season_id) === null;
    const farmSeasonProductPlan = (_a = farmSeason.farm_season_product_plan) === null || _a === void 0 ? void 0 : _a[0];
    const productPlan = farmSeasonProductPlan === null || farmSeasonProductPlan === void 0 ? void 0 : farmSeasonProductPlan.product_plan;
    const hasRenewed = !isBaselineYear &&
        (productPlan === null || productPlan === void 0 ? void 0 : productPlan.name) === PRODUCT_PLAN.STANDARD &&
        (farmSeasonProductPlan === null || farmSeasonProductPlan === void 0 ? void 0 : farmSeasonProductPlan.status) === PRODUCT_PLAN_STATUSES.ACTIVE &&
        !!(previousFarmSeason === null || previousFarmSeason === void 0 ? void 0 : previousFarmSeason.results_validated_by_agronomist_at);
    const renewalStatus = {
        label: t('labels.renewed'),
        isActive: true,
        value: formatDate(user === null || user === void 0 ? void 0 : user.language.iso_code, farmSeasonProductPlan === null || farmSeasonProductPlan === void 0 ? void 0 : farmSeasonProductPlan.paid_at),
    };
    return { hasRenewed, renewalStatus };
};
