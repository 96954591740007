var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { SvgIcon } from '@mui/material';
export const IconPrivacy = (_a) => {
    var { color = 'primary' } = _a, rest = __rest(_a, ["color"]);
    return (_jsx(SvgIcon, Object.assign({ viewBox: "0 0 107 134", fill: "none", xmlns: "http://www.w3.org/2000/svg", color: color }, rest, { children: _jsx("path", { d: "M53.5 0.4375L0.0625 20.4375V61.0625C0.0625 69.3958 1.41667 77.5208 4.125 85.4375C6.83333 93.3542 10.5833 100.646 15.375 107.312C20.1667 113.771 25.7917 119.292 32.25 123.875C38.7083 128.458 45.7917 131.688 53.5 133.562C61.2083 131.688 68.2917 128.458 74.75 123.875C81.2083 119.292 86.8333 113.771 91.625 107.312C96.4167 100.646 100.167 93.3542 102.875 85.4375C105.583 77.5208 106.938 69.3958 106.938 61.0625V20.4375L53.5 0.4375ZM60.0625 93.5625H46.9375V80.4375H60.0625V93.5625ZM60.0625 67H46.9375V33.5625H60.0625V67Z", fill: "currentColor" }) })));
};
