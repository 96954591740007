import { usePermission } from '@hooks/usePermission';
import { ROUTES } from '@router/routes';
export const useVisitFarm = ({ userId, isProductPlanActive, farmSeason }) => {
    const { canVisitAssociateFarm, canVisitPartnerFarm, canVisitAssignedAssociateFarm, canVisitAssignedPartnerFarm } = usePermission();
    const pathToFarm = `${process.env.FARMER_PORTAL_URL}${ROUTES.VISIT_FARM}/${userId}`;
    const canVisitFarm = canVisitAssociateFarm || canVisitPartnerFarm || canVisitAssignedAssociateFarm || canVisitAssignedPartnerFarm;
    const isBaseline = !(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.baseline_farm_season_id);
    const farmSeasonSelected = !!(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.season);
    const isFarmReadyForVisit = (isBaseline && isProductPlanActive && farmSeasonSelected) || !isBaseline;
    return { pathToFarm, canVisitFarm, isFarmReadyForVisit };
};
