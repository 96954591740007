import { makeStyles } from 'tss-react/mui';
export default makeStyles()((theme, { hasShadow }) => {
    return {
        contentContainer: {
            padding: `${theme.spacing(5)} ${theme.spacing(10)}`,
            position: 'sticky',
            zIndex: '50',
            top: 0,
            backgroundColor: theme.palette.content.main,
            borderRadius: '0px 20px 0px 0px',
            ':after': {
                content: '""',
                position: 'absolute',
                left: 0,
                width: '100%',
                bottom: '1px',
                height: '2px',
                zIndex: -1,
                transform: 'scale(0.999)',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                opacity: hasShadow ? 1 : 0,
                transition: 'opacity 0.5s ease',
            },
        },
        actionsContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        title: {
            paddingBottom: theme.spacing(3),
        },
    };
});
