var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { RequiredInputLabel } from '../RequiredInputLabel';
import { TextField } from '@mui/material';
import useLabelTextFieldStyle from './LabelTextField.style';
export const LabelTextField = (_a) => {
    var { id, label, type, required, fullWidth, variant, value, name, onChange, disabled, error, helperText, className, onKeyPress, autoComplete, autoFocus, endAdornment, withHelp, InputProps, helpName, tooltip, onBlur, onFocus } = _a, other = __rest(_a, ["id", "label", "type", "required", "fullWidth", "variant", "value", "name", "onChange", "disabled", "error", "helperText", "className", "onKeyPress", "autoComplete", "autoFocus", "endAdornment", "withHelp", "InputProps", "helpName", "tooltip", "onBlur", "onFocus"]);
    const { classes } = useLabelTextFieldStyle();
    return (_jsxs(_Fragment, { children: [_jsx(RequiredInputLabel, Object.assign({ className: classes.label, helpName: helpName, htmlFor: id, id: id, required: required, tooltip: tooltip, withHelp: withHelp }, { children: label })), _jsx(TextField, Object.assign({ InputProps: Object.assign({ endAdornment }, InputProps), autoComplete: autoComplete, autoFocus: autoFocus, className: `${classes.textField} ${className}`, disabled: disabled, error: error, fullWidth: fullWidth, helperText: helperText, id: id, name: name, required: required, type: type, value: value, variant: variant, onBlur: onBlur, onChange: onChange, onFocus: onFocus, onKeyPress: onKeyPress, onWheel: (e) => type === 'number' && e.target.blur() }, other))] }));
};
