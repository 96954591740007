import { useGetEmailConfigurations } from '@features/invite/farmer/useGetEmailConfigurations';
import { useCreatePartnerUserInvitationConfigurationMutation, useUpdatePartnerUserInvitationConfigurationMutation, } from '@services';
export const useUpdateEmailConfigurations = () => {
    const { data: emailConfigs, isLoading: isLoadingEmailConfigs, isError: isErrorGettingEmailConfigs, } = useGetEmailConfigurations();
    const [createEmailConfigMutation, { isLoading: isLoadingCreateEmailConfigMutation, isError: isErrorCreateMutation },] = useCreatePartnerUserInvitationConfigurationMutation();
    const [updateEmailConfigMutation, { isLoading: isLoadingUpdateEmailConfigMutation, isError: isErrorUpdateMutation },] = useUpdatePartnerUserInvitationConfigurationMutation();
    const isLoading = isLoadingCreateEmailConfigMutation || isLoadingUpdateEmailConfigMutation || isLoadingEmailConfigs;
    const isError = isErrorGettingEmailConfigs || isErrorCreateMutation || isErrorUpdateMutation;
    const manageEmailConfigurationsMutation = (data) => {
        if (isLoading)
            return;
        if (isError)
            return;
        if (!emailConfigs)
            return;
        const emailConfigToUpdate = emailConfigs === null || emailConfigs === void 0 ? void 0 : emailConfigs.find((c) => c.language_id === data.body.language_id);
        const shouldUpdateEmailConfig = (emailConfigToUpdate === null || emailConfigToUpdate === void 0 ? void 0 : emailConfigToUpdate.email_subject) !== data.body.email_subject ||
            (emailConfigToUpdate === null || emailConfigToUpdate === void 0 ? void 0 : emailConfigToUpdate.email_body) !== data.body.email_body;
        if (emailConfigToUpdate === null || emailConfigToUpdate === void 0 ? void 0 : emailConfigToUpdate.id) {
            if (shouldUpdateEmailConfig) {
                return updateEmailConfigMutation(Object.assign({ id: emailConfigToUpdate.id }, data)).unwrap();
            }
            return;
        }
        return createEmailConfigMutation(data).unwrap();
    };
    return {
        manageEmailConfigurationsMutation,
        isLoading,
        isError,
    };
};
