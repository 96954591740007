import { useEffect, useMemo, useState } from 'react';
export const useInvitationResend = ({ invitations, onChange, }) => {
    const [shouldResendToAll, setShouldResendToAll] = useState(false);
    const [savedInvitations, setSavedInvitations] = useState([]);
    const [resendInvitations, setResendInvitations] = useState([]);
    const invitationIds = useMemo(() => invitations === null || invitations === void 0 ? void 0 : invitations.map((inv) => inv.id), [invitations]);
    useEffect(() => {
        if (shouldResendToAll) {
            setResendInvitations(invitations);
            onChange === null || onChange === void 0 ? void 0 : onChange(invitationIds);
            return;
        }
        setResendInvitations(savedInvitations);
        onChange === null || onChange === void 0 ? void 0 : onChange(savedInvitations.map((s) => s.id));
    }, [invitations, invitationIds, shouldResendToAll, savedInvitations]);
    const handleChange = (values) => {
        if (shouldResendToAll && values.length < invitations.length)
            setShouldResendToAll(false);
        if (values.length === invitations.length)
            setShouldResendToAll(true);
        setResendInvitations(values);
        setSavedInvitations(values);
        onChange === null || onChange === void 0 ? void 0 : onChange(values === null || values === void 0 ? void 0 : values.map((v) => v.id));
    };
    const handleShouldResendToAll = () => setShouldResendToAll(!shouldResendToAll);
    const handleInvitationDeleted = (value) => {
        if (shouldResendToAll)
            setShouldResendToAll(false);
        setSavedInvitations(resendInvitations.filter((inv) => inv.id !== value.id));
        setResendInvitations(resendInvitations.filter((inv) => inv.id !== value.id));
    };
    const handleReset = () => {
        if (shouldResendToAll)
            setShouldResendToAll(false);
        setSavedInvitations([]);
        setResendInvitations([]);
    };
    return {
        handleChange,
        handleInvitationDeleted,
        handleReset,
        handleShouldResendToAll,
        resendInvitations,
        shouldResendToAll,
    };
};
