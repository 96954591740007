var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useGenerateResultsV3Mutation } from '@services/results.api';
import { useConfirmEncodingV2Mutation, useConfirmEncodingV3Mutation } from '@services/season.api';
import { CARBON_RESULT_MODEL } from '@utils/constants';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
export const useConfirmEncoding = ({ carbonResultModel, farmId, onSuccess, }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [confirmEncodingV2, { isLoading: isConfirmEncodingV2Loading }] = useConfirmEncodingV2Mutation();
    const [confirmEncodingV3, { isLoading: isConfirmEncodingV3Loading }] = useConfirmEncodingV3Mutation();
    const [generateResultsV3, { isLoading: isGenerateResultsV3Loading }] = useGenerateResultsV3Mutation();
    const confirmEncoding = (farmSeason) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            if (carbonResultModel === CARBON_RESULT_MODEL.DNDC) {
                yield confirmEncodingV3(farmSeason).unwrap();
                yield generateResultsV3({ farmSeasonId: Number(farmSeason) }).unwrap();
            }
            else {
                yield confirmEncodingV2({ farmId, farmSeasonId: farmSeason }).unwrap();
            }
            enqueueSnackbar(t('snackbar.encoding-validated'), {
                variant: 'default',
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
            });
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
        }
        catch (e) {
            // This is a workaround to tell the issue to the user. The button should not be clickable if the encoding is not finished.
            const encodingNotFinishedMessages = [
                'The encoding in V3 is not finished yet for this farm_season',
                'The encoding in V2 is not finished yet for this farm_season',
            ];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (encodingNotFinishedMessages.includes((_a = e === null || e === void 0 ? void 0 : e.data) === null || _a === void 0 ? void 0 : _a.message)) {
                enqueueSnackbar(t('errors.farmer-profile-encoding-not-finished'), { variant: 'error' });
                console.error('The encoding in V3 is not finished yet for the farm_season: ', farmSeason);
                return;
            }
            enqueueSnackbar(t('errors.server-error'), { variant: 'error' });
        }
    });
    const isLoading = isConfirmEncodingV2Loading || isConfirmEncodingV3Loading || isGenerateResultsV3Loading;
    return { confirmEncoding, isLoading };
};
