import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import DashboardHeader from '../DashboardHeader/DashboardHeader';
import { DashboardContent } from '../DashboardContent/DashboardContent';
import useDashboardLayoutStyle from './DashboardLayout.style';
import { useRef } from 'react';
import { DashboardPdf } from '@features/export/DashboardPdf';
import { useScroll } from '@hooks/useScroll';
export const DashboardLayout = () => {
    const { classes } = useDashboardLayoutStyle();
    const ref = useRef(null);
    const [hasScrolled] = useScroll(ref);
    return (_jsxs(_Fragment, { children: [_jsx(DashboardHeader, { shadow: hasScrolled }), _jsxs("div", Object.assign({ ref: ref, className: classes.contentContainer }, { children: [_jsx(DashboardContent, {}), _jsx(DashboardPdf, {})] }))] }));
};
export default DashboardLayout;
