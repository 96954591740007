var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { api, ApiToggle } from '@services/api.service';
export const rolesApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAssignableRoles: builder.query({
            queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
                var _a, _b, _c, _d;
                return __awaiter(this, void 0, void 0, function* () {
                    try {
                        if (arg.toggle === ApiToggle.PARTNER) {
                            const res = yield fetchWithBQ(`/private/roles/partners/assignable${arg.userId ? `?userId=${arg.userId}` : ''}`);
                            return { data: res.data };
                        }
                        if (arg.toggle === ApiToggle.ASSOCIATE) {
                            const res = yield fetchWithBQ('/private/roles/associate/assignable');
                            return { data: res.data };
                        }
                        return { data: [] };
                    }
                    catch (error) {
                        return {
                            error: {
                                status: (_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) !== null && _b !== void 0 ? _b : 500,
                                data: (_d = (_c = error === null || error === void 0 ? void 0 : error.response) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : 'Could not fetch assignable roles',
                            },
                        };
                    }
                });
            },
        }),
        getRole: builder.query({
            query: () => ({
                url: `/private/roles/:roleId`,
                method: 'GET',
            }),
        }),
    }),
});
export const { useGetRoleQuery, useGetAssignableRolesQuery } = rolesApi;
