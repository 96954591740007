import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DATA_VALIDATION_STATUSES, useDataValidationStatus, } from '@features/farmer/profile/farmSeasonStatus/DataValidationStatus/useDataValidationStatus';
import { CARBON_RESULT_MODEL } from '@utils/constants';
import { DashboardStep } from '@features/farmer/profile/components/FarmerDashboard/Step/DashboardStep';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import useCollapsableStyles from '@features/farmer/profile/components/FarmerDashboard/Collapsable.style';
import { Box } from '@mui/material';
export const DATA_VALIDATION_TEST_ID = {
    IN_PROGRESS: 'data-validation-in-progress',
    NOT_STARTED: 'data-validation-not-started',
    DONE: 'data-validation-done',
};
export const DataValidationStatus = (props) => {
    const { classes } = useCollapsableStyles();
    const { dataValidationStatus } = useDataValidationStatus(props);
    return (_jsx(_Fragment, { children: props.carbonModel === CARBON_RESULT_MODEL.CFT && (_jsxs(_Fragment, { children: [_jsx(DashboardStep, { icon: dataValidationStatus.status === DATA_VALIDATION_STATUSES.IN_PROGRESS ? (_jsx(CheckCircleOutlineRoundedIcon, { "data-testid": DATA_VALIDATION_TEST_ID.IN_PROGRESS, className: classes.icon })) : dataValidationStatus.status === DATA_VALIDATION_STATUSES.NOT_STARTED ? (_jsx(CheckCircleOutlineRoundedIcon, { "data-testid": DATA_VALIDATION_TEST_ID.NOT_STARTED, className: classes.greyIcon })) : (_jsx(CheckCircle, { "data-testid": DATA_VALIDATION_TEST_ID.DONE, className: classes.checkIcon })), stepStatus: dataValidationStatus }), _jsx(Box, { className: classes.stepConnector })] })) }));
};
