import { createSlice } from '@reduxjs/toolkit';
import { authApi, userApi } from '@services';
const initialState = { user: null };
const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
            state.user = payload.user;
        });
        builder.addMatcher(authApi.endpoints.user.matchFulfilled, (state, { payload }) => {
            state.user = payload.user;
        });
        builder.addMatcher(userApi.endpoints.updateUser.matchFulfilled, (state, { payload }) => {
            state.user = payload;
        });
        builder.addMatcher(authApi.endpoints.registerInvite.matchFulfilled, (state, { payload }) => {
            state.user = payload;
        });
        builder.addMatcher(authApi.endpoints.logout.matchFulfilled, () => (Object.assign({}, initialState)));
    },
});
export const authReducer = slice.reducer;
export const selectCurrentUser = (state) => state.auth.user;
