import { useState } from 'react';
export const useShowInvitationModal = () => {
    const [open, setOpen] = useState(false);
    const [shouldResend, setShouldResend] = useState(false);
    const openInviteModal = () => {
        setShouldResend(false);
        setOpen(true);
    };
    const openResendModal = () => {
        setShouldResend(true);
        setOpen(true);
    };
    const onClose = () => setOpen(false);
    return { open, openInviteModal, openResendModal, onClose, shouldResend };
};
