import { makeStyles } from 'tss-react/mui';
export const useAlarmingDataCheckCardStyles = makeStyles()((theme) => ({
    card: {
        padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
        boxShadow: '0px 4px 16px 4px rgba(50, 63, 69, 0.04)',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
}));
