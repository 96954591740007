var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import MuiModal from '@mui/material/Modal';
import { useNewModalStyles } from '@components/NewModal/NewModal.style';
import { createContext, useContext } from 'react';
import { Button, CircularProgress, Typography } from '@mui/material';
import { IconCross } from '@components/Icons';
export const ModalContext = createContext(null);
const useModalContext = () => {
    const context = useContext(ModalContext);
    if (!context)
        throw Error('Modal.* component must be used under Modal component');
    return context;
};
const NewModal = (_a) => {
    var { open, onClose, onConfirm, header, body, footer, className } = _a, rest = __rest(_a, ["open", "onClose", "onConfirm", "header", "body", "footer", "className"]);
    const { classes, cx } = useNewModalStyles();
    return (_jsx(MuiModal, Object.assign({ open: open, onClose: onClose }, rest, { children: _jsx(ModalContext.Provider, Object.assign({ value: { onClose, onConfirm } }, { children: _jsxs("div", Object.assign({ className: cx(classes.modal, className) }, { children: [header, body, footer] })) })) })));
};
const ModalHeader = (_a) => {
    var { children, className } = _a, rest = __rest(_a, ["children", "className"]);
    const { classes, cx } = useNewModalStyles();
    return (_jsx("div", Object.assign({ className: cx(classes.header, className) }, rest, { children: children })));
};
const ModalFooter = (_a) => {
    var { children, className } = _a, rest = __rest(_a, ["children", "className"]);
    const { classes, cx } = useNewModalStyles();
    return (_jsx("div", Object.assign({ className: cx(classes.footer, className) }, rest, { children: children })));
};
const ModalBody = (_a) => {
    var { children, className } = _a, rest = __rest(_a, ["children", "className"]);
    const { classes, cx } = useNewModalStyles();
    return (_jsx("div", Object.assign({ className: cx(classes.body, className) }, rest, { children: children })));
};
const ModalTitle = (_a) => {
    var { children, className } = _a, rest = __rest(_a, ["children", "className"]);
    const { classes, cx } = useNewModalStyles();
    return (_jsx(Typography, Object.assign({ variant: "h2", className: cx(classes.title, className) }, rest, { children: children })));
};
const ModalClose = (_a) => {
    var { className, onClick } = _a, rest = __rest(_a, ["className", "onClick"]);
    const { onClose } = useModalContext();
    const { classes, cx } = useNewModalStyles();
    const handleClick = (e) => {
        onClose === null || onClose === void 0 ? void 0 : onClose();
        onClick === null || onClick === void 0 ? void 0 : onClick(e);
    };
    return (_jsx(IconCross, Object.assign({ "data-testid": "close-modal", className: cx(classes.close, className), onClick: handleClick }, rest)));
};
const ModalConfirm = (_a) => {
    var { children, className, onClick, size = 'small', isLoading } = _a, rest = __rest(_a, ["children", "className", "onClick", "size", "isLoading"]);
    const { onConfirm } = useModalContext();
    const { classes, cx } = useNewModalStyles();
    const handleClick = (e) => {
        onConfirm === null || onConfirm === void 0 ? void 0 : onConfirm();
        onClick === null || onClick === void 0 ? void 0 : onClick(e);
    };
    return (_jsx(Button, Object.assign({ className: cx(classes.confirm, className), size: size, onClick: handleClick }, rest, { children: isLoading ? _jsx(CircularProgress, { size: 15 }) : children })));
};
const ModalCancel = (_a) => {
    var { children, className, onClick } = _a, rest = __rest(_a, ["children", "className", "onClick"]);
    const { classes, cx } = useNewModalStyles();
    const { onClose } = useModalContext();
    const handleClick = (e) => {
        onClose === null || onClose === void 0 ? void 0 : onClose();
        onClick === null || onClick === void 0 ? void 0 : onClick(e);
    };
    return (_jsx(Button, Object.assign({ variant: "text", className: cx(classes.cancel, className), onClick: handleClick }, rest, { children: children })));
};
NewModal.Header = ModalHeader;
NewModal.Title = ModalTitle;
NewModal.Body = ModalBody;
NewModal.Footer = ModalFooter;
NewModal.Close = ModalClose;
NewModal.Confirm = ModalConfirm;
NewModal.Cancel = ModalCancel;
export { NewModal };
