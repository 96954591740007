import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContactFilterStyle } from '@features/farmer/listFarmers/Filters/ContactFilter/ContactFilter.style';
import { useGetMembers } from '@hooks/useGetMembers';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { Box, FormControl, MenuItem, SvgIcon, Typography } from '@mui/material';
import { Select } from '@components/Select';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
const contactDefaultValue = 'all';
export const ContactFilter = ({ defaultValue, className, onChange }) => {
    const { t } = useTranslation();
    const { classes } = useContactFilterStyle();
    const [inputValue, setInputValue] = useState(defaultValue || contactDefaultValue);
    const { data: members } = useGetMembers();
    const membersWithoutPending = members === null || members === void 0 ? void 0 : members.filter((member) => !!(member === null || member === void 0 ? void 0 : member.user_id));
    const handleChange = (event) => {
        const inputValue = event.target.value;
        setInputValue(inputValue);
        const contactId = Number(inputValue);
        onChange === null || onChange === void 0 ? void 0 : onChange(inputValue === contactDefaultValue ? undefined : contactId);
    };
    if (!membersWithoutPending)
        return _jsx(_Fragment, {});
    const getContactFilterText = (value) => {
        if (value === contactDefaultValue)
            return t('common.all');
        const inputContactId = Number(value);
        const member = membersWithoutPending.find((member) => member.contact_id === inputContactId);
        return `${member.first_name} ${member.last_name}`;
    };
    return (_jsx(FormControl, Object.assign({ className: className }, { children: _jsxs(Select, Object.assign({ IconComponent: KeyboardArrowUpIcon, className: classes.select, value: inputValue, onChange: handleChange, name: "contact-select", renderValue: (value) => {
                return (_jsxs(Box, Object.assign({ className: classes.selectWrapper }, { children: [_jsx(SvgIcon, Object.assign({ className: classes.selectIcon }, { children: _jsx(PermContactCalendarIcon, {}) })), _jsxs(Typography, Object.assign({ variant: "body1", className: classes.selectOption }, { children: [t('common.contact'), " : ", getContactFilterText(value)] }))] })));
            } }, { children: [_jsx(MenuItem, Object.assign({ value: contactDefaultValue }, { children: t('common.all') })), membersWithoutPending === null || membersWithoutPending === void 0 ? void 0 : membersWithoutPending.map((member) => (_jsx(MenuItem, Object.assign({ className: classes.menuItem, value: member.contact_id }, { children: `${member.first_name} ${member.last_name}` }), member.contact_id)))] })) })));
};
