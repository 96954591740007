import { jsx as _jsx } from "react/jsx-runtime";
import { IconBE, IconFR, IconGB } from '@components/Icons';
export const parseNumber = (value) => {
    if (value) {
        return Math.round(value).toString();
    }
    return '0';
};
export const mapFlagToIsoCode = (iso) => {
    switch (iso) {
        case 'FR':
            return _jsx(IconFR, {});
        case 'GB':
            return _jsx(IconGB, {});
        default:
            return _jsx(IconBE, {});
    }
};
export const getAvatarInitials = (firstName, lastName, email) => {
    let initials = '';
    if (firstName && lastName)
        initials = `${firstName[0]}${lastName[0]}`;
    else if (email)
        initials = `${email[0]}${email[1]}`;
    return initials.toUpperCase();
};
export const getHookFormErrorMessage = (fieldError) => {
    var _a;
    return typeof (fieldError === null || fieldError === void 0 ? void 0 : fieldError.message) === 'string'
        ? fieldError === null || fieldError === void 0 ? void 0 : fieldError.message
        : Array.isArray(fieldError)
            ? (_a = fieldError === null || fieldError === void 0 ? void 0 : fieldError.find((e) => e === null || e === void 0 ? void 0 : e.message)) === null || _a === void 0 ? void 0 : _a.message
            : '';
};
