import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import useHomePageStyle from './HomePage.style';
import SideBar from '@components/SideBar/SideBar';
import TopBar from '@components/TopBar/TopBar';
import { Fab, SvgIcon } from '@mui/material';
import { IconChevronLeft } from '@components/Icons';
function HomePage() {
    const [sideBarOpen, setSidebarOpen] = useState(true);
    const { classes } = useHomePageStyle({ sideBarOpen });
    const toggleSideBar = () => {
        setSidebarOpen((sideBarOpen) => !sideBarOpen);
    };
    return (_jsxs("div", Object.assign({ className: classes.container }, { children: [_jsx(TopBar, { className: classes.topbar }), _jsxs("div", Object.assign({ className: classes.section }, { children: [_jsxs("div", Object.assign({ className: classes.sidebar }, { children: [_jsx(SideBar, {}), _jsx("div", { className: `hoverableCollapseBtn ${classes.hoverableCollapseBtn}` }), _jsx(Fab, Object.assign({ "aria-label": "collapse", className: `${classes.sideBarCollapseBtn} sideBarCollapseBtn`, size: "small", onClick: toggleSideBar }, { children: _jsx(SvgIcon, { className: `${classes.toggleIcon} toggleIcon ${!sideBarOpen ? 'sideBarClose' : ''}`, component: IconChevronLeft, viewBox: "0 0 9 9" }) }))] })), _jsx("div", Object.assign({ className: classes.content }, { children: _jsx(Outlet, {}) }))] }))] })));
}
export default HomePage;
