import { useDashboardProgrammeDataView } from '@features/dashboard/DashboardContent/DashboardProgramme/useDashboardProgrammeDataView';
import { useDashboardCarbonResultsDataView } from '@features/dashboard/DashboardContent/DashboardCarbonResults/useDashboardCarbonResultsDataView';
export const useDashboardData = () => {
    const { data: programmeData, isLoading: programmeDataIsLoading, isFetching: programmeDataIsFetching, isError: programmeDataIsError, } = useDashboardProgrammeDataView();
    const { data: carbonResultsData, isLoading: carbonResultsDataIsLoading, isFetching: carbonResultsDataIsFetching, isError: carbonResultsDataIsError, } = useDashboardCarbonResultsDataView();
    const isFetching = programmeDataIsFetching || carbonResultsDataIsFetching;
    const isLoading = programmeDataIsLoading || carbonResultsDataIsLoading;
    const isError = programmeDataIsError || carbonResultsDataIsError;
    const data = {
        programme: programmeData,
        carbonResults: carbonResultsData,
    };
    return { data, isLoading, isFetching, isError };
};
