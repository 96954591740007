import { makeStyles } from 'tss-react/mui';
export default makeStyles()((theme) => {
    return {
        contentContainer: {
            padding: `${theme.spacing(5)} ${theme.spacing(10)}`,
            height: '4000px',
            overflowY: 'auto',
            overflowX: 'hidden',
            scrollBehavior: 'smooth',
        },
        title: {
            paddingBottom: theme.spacing(4),
        },
    };
});
