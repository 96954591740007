var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useSnackbar } from 'notistack';
import { useDeleteFarmerFromPartnerMutation, useDeleteInvitationsMutation } from '@services';
import { useTranslation } from 'react-i18next';
export const useDeleteFarmer = ({ onSuccess, farmerId, invitationId, }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [deleteFarmer, { isLoading: isDeleteFarmerLoading }] = useDeleteFarmerFromPartnerMutation();
    const [deleteInvitations, { isLoading: isDeleteInvitationsLoading }] = useDeleteInvitationsMutation();
    const onDelete = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (farmerId)
                yield deleteFarmer({ farmerId });
            else if (invitationId)
                yield deleteInvitations({ ids: [invitationId] });
            enqueueSnackbar(t('snackbar.farmer-deleted'), { variant: 'success' });
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
        }
        catch (error) {
            enqueueSnackbar(t('errors.server-error'), { variant: 'error' });
        }
    });
    const isLoading = isDeleteFarmerLoading || isDeleteInvitationsLoading;
    return { onDelete, isLoading };
};
