import { CARBON_RESULT_MODEL } from '@utils/constants';
import { formatDate } from '@utils/date';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@hooks';
export const DATA_VALIDATION_STATUSES = {
    NOT_STARTED: 'NOT_STARTED',
    IN_PROGRESS: 'IN_PROGRESS',
    DONE: 'DONE',
};
export const useDataValidationStatus = ({ farmSeason, carbonModel }) => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const hasValidatedData = !!(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.summary_validated_at);
    const hasValidatedOperations = !!(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.operations_validated_at);
    const getDataValidationStatus = () => {
        if (carbonModel === CARBON_RESULT_MODEL.CFT && hasValidatedData)
            return DATA_VALIDATION_STATUSES.DONE;
        if (hasValidatedOperations)
            return DATA_VALIDATION_STATUSES.IN_PROGRESS;
        return DATA_VALIDATION_STATUSES.NOT_STARTED;
    };
    const status = getDataValidationStatus();
    const mapDataValidationStatusToLabel = (status) => {
        switch (status) {
            case DATA_VALIDATION_STATUSES.NOT_STARTED:
                return t('labels.not-started', { context: 'female' });
            case DATA_VALIDATION_STATUSES.IN_PROGRESS:
                return t('labels.in-progress');
            case DATA_VALIDATION_STATUSES.DONE:
                return formatDate(user === null || user === void 0 ? void 0 : user.language.iso_code, farmSeason.summary_validated_at);
        }
    };
    const translatedStatus = mapDataValidationStatusToLabel(status);
    const dataValidationStatus = {
        label: t('labels.data-validation'),
        status,
        isActive: carbonModel === CARBON_RESULT_MODEL.CFT ? hasValidatedData : false,
        value: translatedStatus,
    };
    return { dataValidationStatus, hasValidatedData };
};
