import { jsx as _jsx } from "react/jsx-runtime";
import { useParams } from 'react-router-dom';
import FormCard from '../FormCard/FormCard';
import ResetPasswordEmailForm from './ResetPasswordEmailForm';
import ResetPasswordForm from './ResetPasswordForm';
function ForgotPasswordSection() {
    const { resetToken } = useParams();
    return _jsx(FormCard, { children: resetToken ? _jsx(ResetPasswordForm, {}) : _jsx(ResetPasswordEmailForm, {}) });
}
export default ForgotPasswordSection;
