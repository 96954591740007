import { useAuth } from '@hooks';
import { formatDate } from '@utils/date';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePermission } from '@hooks/usePermission';
export const useOnboardingStepsViewModel = ({ profile }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { canAccessPlatformAsAssociate } = usePermission();
    const { t } = useTranslation();
    const { user } = useAuth();
    const oldestFarmSeason = (_c = (_b = (_a = profile === null || profile === void 0 ? void 0 : profile.farm_user) === null || _a === void 0 ? void 0 : _a.farm) === null || _b === void 0 ? void 0 : _b.farm_seasons) === null || _c === void 0 ? void 0 : _c.reduce((prev, curr) => {
        const prevDate = new Date(prev.created_at).getTime();
        const currDate = new Date(curr.created_at).getTime();
        if (prevDate > currDate)
            return prev;
        return curr;
    });
    const [currentStep, setCurrentStep] = useState(0);
    const defaultValue = canAccessPlatformAsAssociate ? '-' : t('labels.not-started', { context: 'female' });
    const hasBeenInvited = !!((_d = profile === null || profile === void 0 ? void 0 : profile.invitation) === null || _d === void 0 ? void 0 : _d.created_at);
    const hasCreatedAccount = !!((_e = profile === null || profile === void 0 ? void 0 : profile.invitation) === null || _e === void 0 ? void 0 : _e.registered_at) || !!(profile === null || profile === void 0 ? void 0 : profile.created_at);
    const hasRegistered = !!((oldestFarmSeason === null || oldestFarmSeason === void 0 ? void 0 : oldestFarmSeason.season_id) && (oldestFarmSeason === null || oldestFarmSeason === void 0 ? void 0 : oldestFarmSeason.created_at));
    const invitedStatus = {
        isActive: hasBeenInvited,
        value: hasBeenInvited
            ? formatDate(user === null || user === void 0 ? void 0 : user.language.iso_code, (_f = profile === null || profile === void 0 ? void 0 : profile.invitation) === null || _f === void 0 ? void 0 : _f.created_at)
            : defaultValue,
        label: t('labels.invitation-sent'),
    };
    const accountCreatedStatus = {
        isActive: hasCreatedAccount,
        value: hasCreatedAccount
            ? formatDate(user === null || user === void 0 ? void 0 : user.language.iso_code, (_h = (((_g = profile === null || profile === void 0 ? void 0 : profile.invitation) === null || _g === void 0 ? void 0 : _g.registered_at) || (profile === null || profile === void 0 ? void 0 : profile.created_at))) !== null && _h !== void 0 ? _h : undefined)
            : defaultValue,
        label: t('labels.account-created'),
    };
    const registeredStatus = {
        isActive: hasRegistered,
        value: hasRegistered
            ? formatDate(user === null || user === void 0 ? void 0 : user.language.iso_code, oldestFarmSeason.created_at)
            : defaultValue,
        label: t('labels.registered'),
    };
    const maxSteps = canAccessPlatformAsAssociate ? 2 : 3;
    useEffect(() => {
        const initialStep = canAccessPlatformAsAssociate ? -1 : 0;
        if (!canAccessPlatformAsAssociate && hasBeenInvited)
            setCurrentStep(initialStep + 1);
        if (hasCreatedAccount)
            setCurrentStep(initialStep + 2);
        if (hasRegistered)
            setCurrentStep(initialStep + 3);
    }, [canAccessPlatformAsAssociate, hasBeenInvited, hasCreatedAccount, hasRegistered]);
    return { currentStep, maxSteps, invitedStatus, accountCreatedStatus, registeredStatus };
};
