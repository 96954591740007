import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';
export default makeStyles()((theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'scroll',
        '::-webkit-scrollbar': {
            display: 'none',
        },
    },
    container: {
        display: 'flex',
        alignItems: 'flex-end',
        flexWrap: 'nowrap',
    },
    axeX: {
        height: 1,
        backgroundColor: '#BDBDBD',
    },
    lollipopGraphContainer: {
        height: '90px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    lollipopContentContainer: {
        zIndex: 2,
    },
    lollipopSelectionBackground: {
        position: 'absolute',
        top: '0',
        left: '50%',
        height: 120,
        width: 50,
        transform: 'translateX(-50%)',
        borderRadius: '4px',
        zIndex: 1,
    },
    revertedLollipopSelectionBackground: {
        transform: 'translateX(-50%) translateY(50%)',
    },
    selectionBgColor: {
        backgroundColor: alpha('#BDBDBD', 0.3),
    },
    lollipop: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    revertLollipop: {
        transform: 'translateY(100%)',
    },
    circle: {
        backgroundColor: theme.palette.secondary.dark,
        width: 8,
        height: 8,
        borderRadius: '50%',
    },
    revertCircle: {
        order: 2,
    },
    line: {
        width: 2,
        backgroundColor: theme.palette.secondary.light,
    },
    revertLine: {
        order: 1,
    },
    legend: {
        whiteSpace: 'nowrap',
        position: 'absolute',
        left: '50%',
        bottom: -24,
        transform: 'translateX(-50%)',
        textAlign: 'center',
    },
    revertLegend: {
        bottom: 4,
    },
    tooltip: {
        textTransform: 'capitalize',
    },
}));
