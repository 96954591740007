import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import useDashboardContentFallbackStyle from './DashboardContentFallback.style';
import { IconCross, IconPrivacy } from '@components/Icons';
export const DashboardContentFallback = ({ amount }) => {
    const { classes } = useDashboardContentFallbackStyle();
    const { t } = useTranslation();
    return (_jsx(_Fragment, { children: !amount || amount === 0 ? (_jsxs(Box, Object.assign({ className: classes.contentContainer }, { children: [_jsx(IconCross, { className: classes.icon }), _jsx(Typography, Object.assign({ variant: "h3" }, { children: t('titles.no-data-found') })), _jsx(Typography, Object.assign({ className: classes.subtitle, variant: "body1" }, { children: t('subtitles.no-data-found') }))] }))) : (_jsxs(Box, Object.assign({ className: classes.contentContainer }, { children: [_jsx(IconPrivacy, { color: "primary", className: classes.icon }), _jsx(Typography, Object.assign({ variant: "h3" }, { children: t('titles.privacy-risk') })), _jsx(Typography, Object.assign({ className: classes.subtitle, variant: "body1" }, { children: t('subtitles.privacy-risk') }))] }))) }));
};
