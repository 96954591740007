import { makeStyles } from 'tss-react/mui';
export default makeStyles()(() => {
    return {
        container: {
            padding: '20px',
            // position: 'relative',
            // width: '100%',
            // height: '100%',
            // zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
        },
        contentLayout: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            marginTop: '20px',
            '> *': {
                // maxWidth: '272px',
                // minWidth: '200px',
                width: '272px',
                margin: '0 60px 0 60px',
            },
        },
    };
});
