var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useIconSignalStyle from './IconSignal.style';
import { SvgIcon } from '@mui/material';
export const IconSignal = (_a) => {
    var { variant, color } = _a, rest = __rest(_a, ["variant", "color"]);
    const { classes, cx } = useIconSignalStyle();
    if (variant && color) {
        throw new Error('variant & color cannot both be applied for IconSignal component');
    }
    return (_jsxs(SvgIcon, Object.assign({ viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg", color: color }, rest, { children: [_jsx("rect", { className: cx({
                    [`${classes.lowSignalColor} ${classes.light}`]: variant === 'low',
                    [`${classes.mediumSignalColor} ${classes.light}`]: variant === 'medium',
                    [`${classes.highSignalColor} ${classes.bold}`]: variant === 'high',
                    [classes.noSignalColor]: variant === 'none',
                }), x: "26", y: "8", width: "6", height: "24", rx: "3", fill: "currentColor" }), _jsx("rect", { className: cx({
                    [`${classes.lowSignalColor} ${classes.light}`]: variant === 'low',
                    [`${classes.mediumSignalColor} ${classes.bold}`]: variant === 'medium',
                    [`${classes.highSignalColor} ${classes.bold}`]: variant === 'high',
                    [classes.noSignalColor]: variant === 'none',
                }), x: "17", y: "16", width: "6", height: "16", rx: "3", fill: "currentColor" }), _jsx("rect", { className: cx({
                    [`${classes.lowSignalColor} ${classes.bold}`]: variant === 'low',
                    [`${classes.mediumSignalColor} ${classes.bold}`]: variant === 'medium',
                    [`${classes.highSignalColor} ${classes.bold}`]: variant === 'high',
                    [classes.noSignalColor]: variant === 'none',
                }), x: "8", y: "24", width: "6", height: "8", rx: "3", fill: "currentColor" })] })));
};
