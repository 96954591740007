import { yupResolver } from '@hookform/resolvers/yup';
import { useGetAssignableRoles } from '@hooks/useGetAssignableRoles';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
export const useCreateValidationSchema = (t) => {
    const emailValidationSchema = yup
        .string()
        .email(t('members.invite.errors.email-invalid'))
        .required(t('members.invite.errors.email-required'));
    const inviteSchema = yup.object().shape({
        emails: yup.array().of(emailValidationSchema).required(t('members.invite.errors.email-required')),
    });
    const resendSchema = yup.object().shape({
        invitationIds: yup.array().of(yup.number()).required(t('members.invite.errors.email-required')),
    });
    return { emailValidationSchema, inviteSchema, resendSchema };
};
export const useInviteMembersFormData = ({ shouldResend } = {}) => {
    const { t } = useTranslation();
    const { inviteSchema, resendSchema } = useCreateValidationSchema(t);
    const { data: roles } = useGetAssignableRoles();
    const defaultInviteValues = roles
        ? {
            emails: [],
            roleId: roles[0].id,
        }
        : undefined;
    const defaultResendValues = {
        invitationIds: [],
    };
    const methods = useForm({
        defaultValues: shouldResend ? defaultResendValues : defaultInviteValues,
        resolver: yupResolver(shouldResend ? resendSchema : inviteSchema),
    });
    useEffect(() => {
        if (!shouldResend && roles) {
            methods.reset(defaultInviteValues);
        }
    }, [roles, shouldResend]);
    return { methods };
};
