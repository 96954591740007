import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import useTosModalViewStyle from './TosModalView.style';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const TosModalView = ({ className, onClose }) => {
    const { classes } = useTosModalViewStyle();
    const { i18n } = useTranslation();
    return (_jsxs(Paper, Object.assign({ className: `${className} ${classes.paper}`, elevation: 0 }, { children: [_jsx(Box, Object.assign({ className: classes.container }, { children: i18n.language !== 'fr' ? (_jsxs(Document, Object.assign({ className: classes.documentEN, file: "/general_conditions_en.pdf" }, { children: [_jsx(Page, { pageNumber: 1, scale: 1.3 }), _jsx(Page, { className: `${classes.pdfMargin}`, height: 900, pageNumber: 2, scale: 1.3, width: 600 })] }))) : (_jsxs(Document, Object.assign({ className: classes.documentFR, file: "/general_conditions_fr.pdf" }, { children: [_jsx(Page, { pageNumber: 1, scale: 1.3 }), _jsx(Page, { className: classes.pdfMargin, pageNumber: 2, scale: 1.3 }), _jsx(Page, { className: `${classes.pdfMarginLastFR}`, pageNumber: 3, scale: 1.3 })] }))) })), _jsx(CloseIcon, { className: classes.close, onClick: onClose })] })));
};
export default TosModalView;
