var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { MetricsLayout } from '../MetricsLayout/MetricsLayout';
import { StackedBarGraphGroup } from '@components/StackedBarGraph';
export const StackedBarMetrics = ({ totalAmount, unit, title, tooltip, icon, graphProps, boxVariant, }) => {
    const _a = graphProps || {}, { data } = _a, rest = __rest(_a, ["data"]);
    const shouldDisplayStackedBar = !!(data && data.length > 0);
    return (_jsx(MetricsLayout, { totalAmount: totalAmount, unit: unit, title: title, tooltip: tooltip, graph: shouldDisplayStackedBar ? _jsx(StackedBarGraphGroup, Object.assign({ data: data }, rest)) : undefined, icon: icon, boxVariant: boxVariant }));
};
