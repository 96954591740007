import { makeStyles } from 'tss-react/mui';
export const useFarmersTableStyles = makeStyles()((theme) => ({
    avatarGroup: {
        '& .MuiAvatar-root': {
            width: 20,
            height: 20,
            fontSize: theme.typography.body2.fontSize,
            padding: theme.spacing(1),
            backgroundColor: '#f6f3E5',
            color: theme.palette.secondary.dark,
            boxShadow: 'none',
        },
    },
    avatar: {
        height: 20,
        width: 20,
        padding: theme.spacing(1),
    },
    email: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    row: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    pending: {
        color: theme.palette.grey['500'],
        '&:after': {
            color: theme.palette.grey['500'],
        },
    },
    farmersTable: {
        '& .row-INVITATION_REFUSED': {
            background: `${theme.palette.error.light} !important`,
            opacity: 0.5,
            cursor: 'not-allowed',
        },
    },
    refused: {
        color: theme.palette.error.main,
        '&:after': {
            color: theme.palette.error.main,
        },
    },
}));
