import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FormControl, MenuItem, SvgIcon, Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { findSeasonById, useSeasonView } from '@hooks/useSeasonView';
import { Select } from '@components/Select';
import { useTranslation } from 'react-i18next';
import { useSeasonFilterStyle } from '@features/dashboard/DashboardHeader/SeasonFilter/SeasonFilter.style';
import { useAppDispatch, useAppSelector } from '@hooks';
import { changeSeason, selectSeasonId } from '@features/dashboard/dashboardSlice';
export const SeasonFilter = ({ onChange }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { data: seasons } = useSeasonView();
    const { classes } = useSeasonFilterStyle();
    const selectedSeasonId = useAppSelector(selectSeasonId);
    const selectedSeasonValue = selectedSeasonId || selectedSeasonId === 0 ? selectedSeasonId.toString() : '';
    if (!seasons)
        return null;
    const getHarvestYearFilterText = (seasonId) => { var _a; return `${(_a = findSeasonById(seasonId, seasons)) === null || _a === void 0 ? void 0 : _a.harvest_year}`; };
    const handleChange = (event) => {
        const seasonId = Number(event.target.value);
        const harvestFilter = getHarvestYearFilterText(seasonId);
        dispatch(changeSeason({ seasonId, harvestFilter }));
        onChange === null || onChange === void 0 ? void 0 : onChange(seasonId, harvestFilter);
    };
    return (_jsx(FormControl, { children: _jsx(Select, Object.assign({ IconComponent: KeyboardArrowUpIcon, className: classes.select, value: selectedSeasonValue, onChange: handleChange, name: "seasons-select", renderValue: (value) => {
                return (_jsxs(Box, Object.assign({ className: classes.selectWrapper }, { children: [_jsx(SvgIcon, Object.assign({ className: classes.selectIcon }, { children: _jsx(CalendarTodayOutlinedIcon, {}) })), _jsxs(Typography, Object.assign({ variant: "body1", className: classes.selectOption }, { children: [t('common.harvest'), " : ", getHarvestYearFilterText(Number(value))] }))] })));
            } }, { children: seasons === null || seasons === void 0 ? void 0 : seasons.map((season) => (_jsx(MenuItem, Object.assign({ className: classes.menuItem, value: `${season.id}` }, { children: `${t('common.harvest')} ${season.harvest_year}` }), season.id))) })) }));
};
