var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getRegionNameByCode, useRegionsByCountryView } from '@hooks/useRegionsByCountryView';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { Box, FormControl, ListSubheader, MenuItem, Typography } from '@mui/material';
import { Select } from '@components/Select';
import { mapFlagToIsoCode } from '@utils/string';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRegionFilterStyle } from './RegionFilter.style';
export const regionCodeDefault = 'all';
export const RegionFilter = ({ defaultValue, onChange, className }) => {
    const { t } = useTranslation();
    const { classes, cx } = useRegionFilterStyle();
    const [inputValue, setInputValue] = useState(defaultValue !== null && defaultValue !== void 0 ? defaultValue : regionCodeDefault);
    const { data: regionsByCountry } = useRegionsByCountryView();
    const getRegionFilterText = (regionCode) => regionCode === regionCodeDefault ? t('common.all') : getRegionNameByCode(regionCode, regionsByCountry);
    const handleChange = (event) => __awaiter(void 0, void 0, void 0, function* () {
        const regionCode = event.target.value;
        setInputValue(regionCode);
        const regionFilter = getRegionFilterText(regionCode);
        onChange === null || onChange === void 0 ? void 0 : onChange(regionFilter, regionCode === regionCodeDefault ? undefined : regionCode);
    });
    if (!regionsByCountry)
        return _jsx(_Fragment, {});
    return (_jsx(FormControl, Object.assign({ className: className }, { children: _jsxs(Select, Object.assign({ IconComponent: KeyboardArrowUpIcon, className: cx(classes.select, classes.selectRegionInput), value: inputValue, onChange: handleChange, name: "geography-select", renderValue: (value) => (_jsxs(Box, Object.assign({ className: classes.selectWrapper }, { children: [_jsx(MapOutlinedIcon, { className: classes.selectIconMap, width: 20 }), _jsxs(Typography, Object.assign({ variant: "body1", className: classes.selectOption }, { children: [t('common.location'), " : ", getRegionFilterText(value)] }))] }))) }, { children: [_jsx(MenuItem, Object.assign({ value: regionCodeDefault }, { children: t('common.all') })), regionsByCountry === null || regionsByCountry === void 0 ? void 0 : regionsByCountry.map((country) => {
                    const uniqueRegionCodes = [...new Set(country.regions.map((region) => region.regionCode))];
                    return [
                        _jsxs(ListSubheader, Object.assign({ className: classes.groupedOption }, { children: [mapFlagToIsoCode(country.countryCode), _jsx(Typography, Object.assign({ variant: "body2" }, { children: country.countryName }))] }), country.countryCode),
                        uniqueRegionCodes.map((regionCode) => {
                            const region = country.regions.find((region) => region.regionCode === regionCode);
                            return (_jsx(MenuItem, Object.assign({ className: classes.menuItem, value: `${region === null || region === void 0 ? void 0 : region.regionCode}` }, { children: region === null || region === void 0 ? void 0 : region.regionName }), region === null || region === void 0 ? void 0 : region.regionCode));
                        }),
                    ];
                })] })) })));
};
