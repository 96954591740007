import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMembersTableHeaderStyles } from './MembersTableHeader.style';
import { InviteMembersModal } from '@features/invite/member/Modal/InviteMembersModal';
import { useShowInvitationModal } from '@features/invite/hooks/useShowInvitationModal';
import { useGetAllMembersPendingInvitationsQuery } from '@services/invitation.api';
export const MembersTableHeader = () => {
    const { open, shouldResend, openInviteModal, openResendModal, onClose } = useShowInvitationModal();
    const { classes } = useMembersTableHeaderStyles();
    const { data: pendingMembers } = useGetAllMembersPendingInvitationsQuery();
    const { t } = useTranslation();
    return (_jsxs("div", Object.assign({ className: classes.container }, { children: [_jsx(Typography, Object.assign({ variant: "h2" }, { children: t('titles.my-members') })), _jsxs("div", Object.assign({ className: classes.actionContainer }, { children: [_jsx(Button, Object.assign({ disabled: !pendingMembers, color: "info", onClick: openResendModal }, { children: t('buttons.resend-invite') })), _jsx(Button, Object.assign({ onClick: openInviteModal, color: "primary" }, { children: t('buttons.invite-member') }))] })), _jsx(InviteMembersModal, { open: open, onClose: onClose, shouldResend: shouldResend })] })));
};
