import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CohortFilter } from '@components/CohortFilter/CohortFilter';
import { SearchInput } from '@components/Form/SearchInput/SearchInput';
import { ContactFilter } from '@features/farmer/listFarmers/Filters/ContactFilter/ContactFilter';
import { StatusFilter } from '@features/farmer/listFarmers/Filters/StatusFilter/StatusFilter';
import { changeCohort, changeContact, changeSearch, changeStatus, selectFarmersListCohortId, selectFarmersListContactId, selectFarmersListSearch, selectFarmersListStatus, } from '@features/farmer/listFarmers/farmersList.slice';
import { useGetFarmers } from '@features/farmer/listFarmers/useGetFarmers';
import { InviteFarmersModal } from '@features/invite/farmer/Modal/InviteFarmersModal';
import { useShowInvitationModal } from '@features/invite/hooks/useShowInvitationModal';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { usePermission } from '@hooks/usePermission';
import { Box, Button, Skeleton, Typography, debounce } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFarmersTableHeaderStyles } from './FarmersTableHeader.style';
export const FarmersTableHeader = () => {
    const { t } = useTranslation();
    const { classes } = useFarmersTableHeaderStyles();
    const { data: farmers, count, isLoading } = useGetFarmers({ shouldFilter: true });
    const dispatch = useAppDispatch();
    const { canInviteFarmers, canSeePartnerMembers, canSeeAssociateMembers } = usePermission();
    const { open, shouldResend, openInviteModal, openResendModal, onClose } = useShowInvitationModal();
    const search = useAppSelector(selectFarmersListSearch);
    const defaultCohortId = useAppSelector(selectFarmersListCohortId);
    const defaultContactId = useAppSelector(selectFarmersListContactId);
    const defaultStatus = useAppSelector(selectFarmersListStatus);
    const handleCohortFilterChange = (cohortFilter, cohortId) => {
        dispatch(changeCohort({ cohortId }));
    };
    const handleContactFilterChange = (contact) => {
        dispatch(changeContact({ contactId: contact }));
    };
    const handleStatusFilterChange = (status) => {
        dispatch(changeStatus({ status }));
    };
    const handleSearchChange = debounce((search) => {
        dispatch(changeSearch({ search }));
    }, 500);
    const handleSearchReset = () => dispatch(changeSearch({ search: '' }));
    if (isLoading)
        return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: classes.titleContainer }, { children: [_jsx(Skeleton, { sx: { padding: 0 }, variant: "text", height: 50, width: 250 }), _jsxs(Box, Object.assign({ sx: { display: 'flex', gap: 2 } }, { children: [_jsx(Skeleton, { variant: "rectangular", height: 40, width: 180 }), _jsx(Skeleton, { variant: "rectangular", height: 40, width: 180 })] }))] })), _jsxs("div", Object.assign({ className: classes.filtersContainerSkeleton }, { children: [_jsx(Skeleton, { sx: { mr: 2 }, variant: "text", height: 60, width: 180 }), _jsx(Skeleton, { sx: { mr: 2 }, variant: "text", height: 60, width: 180 }), _jsx(Skeleton, { sx: { mr: 2 }, variant: "text", height: 60, width: 180 }), _jsx(Skeleton, { sx: { mr: 2 }, variant: "text", height: 30, width: 100 })] }))] }));
    return (_jsxs("div", { children: [_jsxs("div", Object.assign({ className: classes.titleContainer }, { children: [_jsx(Typography, Object.assign({ variant: "h2" }, { children: t('titles.farmers') })), _jsxs(Box, Object.assign({ className: classes.actionsContainer }, { children: [canInviteFarmers && (_jsx(Button, Object.assign({ disabled: !(farmers && farmers.filter((f) => f.user_id === null).length > 0), color: "info", onClick: openResendModal }, { children: t('farmers.invite.resend-invite') }))), canInviteFarmers && _jsx(Button, Object.assign({ onClick: openInviteModal }, { children: t('farmers.invite-farmers') }))] }))] })), _jsxs("div", Object.assign({ className: classes.filtersContainer }, { children: [_jsx(CohortFilter, { defaultValue: defaultCohortId ? `${defaultCohortId}` : undefined, onChange: handleCohortFilterChange, className: classes.filter }), (canSeePartnerMembers || canSeeAssociateMembers) && (_jsx(ContactFilter, { defaultValue: defaultContactId ? `${defaultContactId}` : undefined, onChange: handleContactFilterChange, className: classes.filter })), _jsx(StatusFilter, { defaultValue: defaultStatus, onChange: handleStatusFilterChange, className: classes.filter }), _jsx(Typography, Object.assign({ variant: "body2" }, { children: t('common.farmer', { count: count || 0 }) }))] })), canInviteFarmers && _jsx(InviteFarmersModal, { open: open, onClose: onClose, shouldResend: shouldResend }), _jsx("div", Object.assign({ className: classes.searchContainer }, { children: _jsx(SearchInput, { defaultValue: search, onChange: handleSearchChange, onReset: handleSearchReset, placeholder: t('placeholders.search-name-email') }) }))] }));
};
