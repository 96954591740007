import UnauthorizedError from '@/types/UnauthorizedError';
import { api } from '@services';
import i18n from 'i18next';
export const authApi = api.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: 'private/auth/login',
                method: 'POST',
                body: credentials,
            }),
            transformErrorResponse: (error) => {
                if (error.data) {
                    const httpError = error.data;
                    throw new UnauthorizedError(httpError.message, httpError.code);
                }
            },
            transformResponse: (response) => {
                i18n.changeLanguage(response.user.language.iso_code);
                return response;
            },
        }),
        user: builder.query({
            query: () => ({
                url: `/private/auth/user`,
                method: 'GET',
            }),
        }),
        logout: builder.mutation({
            query: () => ({
                url: `/private/auth/logout`,
                method: 'POST',
            }),
        }),
        forgotPassword: builder.mutation({
            query: (credentials) => ({
                url: `${process.env.FARMER_PORTAL_API}/v1/auth/forgotpassword?origin=partner`,
                method: 'POST',
                body: credentials,
            }),
        }),
        resetPassword: builder.mutation({
            query: (credentials) => ({
                url: `${process.env.FARMER_PORTAL_API}/v1/auth/resetpassword`,
                method: 'POST',
                body: credentials,
            }),
        }),
        changePassword: builder.mutation({
            query: (credentials) => ({
                url: `/private/auth/changepassword`,
                method: 'POST',
                body: credentials,
            }),
            transformErrorResponse: (error) => {
                if (error.data) {
                    const httpError = error.data;
                    throw new UnauthorizedError(httpError.message, httpError.code);
                }
            },
        }),
        acceptInvite: builder.mutation({
            query: (data) => ({
                url: `/private/invitations/${data.id}/accept`,
                method: 'POST',
                headers: { authorization: `Bearer ${data.token}` },
            }),
        }),
        registerInvite: builder.mutation({
            query: (data) => ({
                url: `/private/invitations/${data.id}/register`,
                method: 'POST',
                body: data.user,
                headers: { authorization: `Bearer ${data.token}` },
            }),
        }),
        signupOwner: builder.mutation({
            query: (data) => ({
                url: '/private/auth/signup',
                method: 'POST',
                body: data,
            }),
        }),
    }),
});
export const { useLoginMutation, useUserQuery, useLazyUserQuery, useForgotPasswordMutation, useResetPasswordMutation, useChangePasswordMutation, useLogoutMutation, useRegisterInviteMutation, useAcceptInviteMutation, useSignupOwnerMutation, } = authApi;
export * from './auth.types';
