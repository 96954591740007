import { makeStyles } from 'tss-react/mui';
const useHelpButtonStyle = makeStyles()((theme, { height, size }) => ({
    container: {
        padding: 0,
        height: height || '12px',
        width: size === null || size === void 0 ? void 0 : size.innerBody,
        marginLeft: '5px',
        display: 'inline-block',
        position: 'relative',
        '& > a': {
            position: 'absolute',
            background: '#FFF',
            height: size === null || size === void 0 ? void 0 : size.innerBody,
            width: size === null || size === void 0 ? void 0 : size.innerBody,
            borderRadius: '100%',
        },
    },
    help: {
        position: 'absolute',
        top: size === null || size === void 0 ? void 0 : size.position,
        left: size === null || size === void 0 ? void 0 : size.position,
        height: size === null || size === void 0 ? void 0 : size.outerBody,
        width: size === null || size === void 0 ? void 0 : size.outerBody,
        padding: '0px',
        '&:hover': {
            color: theme.palette.primary.light,
        },
    },
}));
export default useHelpButtonStyle;
