import { jsx as _jsx } from "react/jsx-runtime";
import { ContactsEditForm } from '@features/assignment/ContactsEditForm/ContactEditForm';
import { useState } from 'react';
import { useChangeAssociateAssignedContacts } from '@features/assignment/useChangeAssociateAssignedContacts';
export const AssociateContactEditForm = ({ assignedContacts, assignableContacts, farmId, onCancel, onSave, }) => {
    const [selectedContacts, setSelectedContacts] = useState(assignedContacts || []);
    const { changeAssignedContacts, isLoading } = useChangeAssociateAssignedContacts({
        selectedContacts,
        assignedContacts,
        farmId,
        onSuccess: onSave,
    });
    return (_jsx(ContactsEditForm, { assignedContacts: selectedContacts, onContactChange: setSelectedContacts, assignableContacts: assignableContacts, onCancel: onCancel, onSubmit: changeAssignedContacts, isLoading: isLoading }));
};
