import { usePermission } from '@hooks/usePermission';
import { ApiToggle } from '@services';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetContactsQuery } from '@services/assignmentApi';
export const useFarmerContacts = ({ id }) => {
    const { canSeePartnerMembers, canSeeAssociateMembers } = usePermission();
    const fetchParams = canSeePartnerMembers && id
        ? { toggle: ApiToggle.PARTNER, invitation_id: id }
        : canSeeAssociateMembers && id
            ? { toggle: ApiToggle.ASSOCIATE, farm_id: id }
            : skipToken;
    return useGetContactsQuery(fetchParams);
};
