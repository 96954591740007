import { makeStyles } from 'tss-react/mui';
export const useInviteMembersModalStyles = makeStyles()((theme) => ({
    inputContainer: {
        width: '100%',
        marginBottom: theme.spacing(4),
    },
    inputTitle: {
        marginBottom: theme.spacing(1),
        textTransform: 'capitalize',
    },
    buttonsContainer: {
        display: 'flex',
        gap: theme.spacing(4),
    },
    buttonLoader: {
        color: theme.palette.common.white,
    },
}));
