import { api } from './api.service';
export const farmSeasonApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getRegisteredFarms: builder.query({
            query: () => ({
                url: `/private/farm-seasons/farms/registered/count`,
                method: 'GET',
            }),
        }),
    }),
});
export const { useGetRegisteredFarmsQuery } = farmSeasonApi;
