import { makeStyles } from 'tss-react/mui';
export const useAlarmingDataGroupedByTypeStyles = makeStyles()((theme) => ({
    noDataContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(8),
    },
    noDataIcon: {
        marginRight: theme.spacing(2),
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(8),
    },
}));
