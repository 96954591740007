import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Select } from '@components/Select';
import { Box, MenuItem } from '@mui/material';
export const SelectLanguages = ({ languageId, languages, onChange }) => {
    const getLangNameById = (id) => { var _a; return (_a = languages === null || languages === void 0 ? void 0 : languages.find((l) => l.id === id)) === null || _a === void 0 ? void 0 : _a.name; };
    const handleLanguageChange = (e) => {
        const langId = Number(e.target.value);
        const selectedLanguage = languages === null || languages === void 0 ? void 0 : languages.find((lang) => lang.id === langId);
        if (selectedLanguage)
            onChange(selectedLanguage);
    };
    if (!languages)
        return _jsx(_Fragment, {});
    return (_jsx(Select, Object.assign({ value: `${languageId}`, fullWidth: true, onChange: handleLanguageChange, renderValue: (selected) => _jsx(Box, { children: getLangNameById(Number(selected)) }) }, { children: languages === null || languages === void 0 ? void 0 : languages.map((lang) => (_jsx(MenuItem, Object.assign({ value: lang.id }, { children: lang.name }), lang.id))) })));
};
