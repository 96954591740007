import { alpha } from '@mui/material';
const styleOverrides = {
    root: ({ theme }) => ({
        borderRadius: '100px',
        height: '15px',
        backgroundColor: alpha(theme.palette.secondary.main, 0.2),
        boxShadow: 'inset 4px 4px 15px 0px rgba(0,0,0,.1)',
    }),
    bar: {
        borderRadius: '100px',
    },
};
const MuiLinearProgress = { styleOverrides };
export default MuiLinearProgress;
