import { makeStyles } from 'tss-react/mui';
export const useResendMemberEmailsFormStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    inputTitle: {
        textTransform: 'capitalize',
        marginBottom: theme.spacing(1),
    },
    select: {
        '& .MuiInputBase-root': {
            display: 'flex',
            flexWrap: 'nowrap',
        },
    },
    checkbox: {
        marginLeft: 0,
    },
}));
