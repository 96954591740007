import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Tooltip, Typography } from '@mui/material';
import useLollipopGraphStyle from './LollipopGraph.style';
const Lollipop = ({ label, height = 50, revert = false }) => {
    const { classes, cx } = useLollipopGraphStyle();
    return (_jsx(Tooltip, Object.assign({ classes: { tooltip: classes.tooltip }, title: label, arrow: true, placement: revert ? 'bottom' : 'top' }, { children: _jsxs(Box, Object.assign({ className: cx(classes.lollipop, revert && classes.revertLollipop) }, { children: [_jsx("div", { className: cx(classes.circle, revert && classes.revertCircle) }), _jsx(Box, { height: height, className: cx(classes.line, revert && classes.revertLine) })] })) })));
};
const MAX_LOLLIPOP_SHOWN = 5;
export const MAX_LOLLIPOP_HEIGHT = 50;
export const LollipopGraph = ({ data, currentPeriodId, renderTooltipContent }) => {
    const { classes, cx } = useLollipopGraphStyle();
    const minItemWidth = data.length < MAX_LOLLIPOP_SHOWN ? `${100 / data.length}%` : '20%';
    const modulo = data.length % MAX_LOLLIPOP_SHOWN;
    const containerWidthIncludingOverflow = data.length < MAX_LOLLIPOP_SHOWN
        ? '100%'
        : `${((data.length - modulo) / MAX_LOLLIPOP_SHOWN) * 100 + modulo * 20}%`;
    const topAmountItem = [...data].sort((a, b) => (Math.abs(a.y) > Math.abs(b.y) ? -1 : 1))[0];
    const haveNegativeValues = data.some((num) => num.y < 0);
    const allValuesAreNegative = !data.some((num) => num.y >= 0);
    return (_jsxs(Box, Object.assign({ className: classes.wrapper, pb: haveNegativeValues ? '90px' : '30px', mt: allValuesAreNegative ? '-30px' : 0 }, { children: [_jsx("div", Object.assign({ className: cx(classes.container) }, { children: data.map((item) => {
                    const lollipopHeight = topAmountItem.y === item.y
                        ? MAX_LOLLIPOP_HEIGHT
                        : (MAX_LOLLIPOP_HEIGHT / Math.abs(topAmountItem.y)) * Math.abs(item.y);
                    const isCurrent = currentPeriodId ? currentPeriodId === item.id : false;
                    const revert = item.y < 0;
                    const value = Math.abs(item.y);
                    return (_jsx(Box, Object.assign({ minWidth: minItemWidth, position: "relative" }, { children: _jsxs(Box, Object.assign({ className: classes.lollipopGraphContainer }, { children: [_jsxs(Box, Object.assign({ className: classes.lollipopContentContainer }, { children: [_jsx(Lollipop, { revert: revert, height: lollipopHeight, label: renderTooltipContent ? renderTooltipContent(item) : `${value} ${item.unit}` }), _jsx(Typography, Object.assign({ className: cx(classes.legend, revert && classes.revertLegend), variant: "body2", fontWeight: isCurrent ? 700 : 400 }, { children: item.x }))] })), _jsx(Box, { className: cx(classes.lollipopSelectionBackground, revert && classes.revertedLollipopSelectionBackground, isCurrent && classes.selectionBgColor) })] })) }), item.x));
                }) })), _jsx(Box, { width: containerWidthIncludingOverflow, className: cx(classes.axeX) })] })));
};
