import { makeStyles } from 'tss-react/mui';
export default makeStyles()((theme) => {
    return {
        title: {
            paddingBottom: '20px',
        },
        arrowError: {
            color: theme.palette.error.main,
        },
        arrowSuccess: {
            color: theme.palette.success.main,
        },
    };
});
