import { makeStyles } from 'tss-react/mui';
const useIconSignalStyle = makeStyles()((theme) => {
    return {
        light: {
            opacity: 0.2,
        },
        bold: {
            opacity: 1,
        },
        lowSignalColor: {
            color: theme.palette.error.main,
        },
        mediumSignalColor: {
            color: theme.palette.warning.main,
        },
        highSignalColor: {
            color: theme.palette.success.main,
        },
        noSignalColor: {
            color: theme.palette.grey[100],
        },
    };
});
export default useIconSignalStyle;
