var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ErrorCodes } from '@/types/ErrorCodes';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryParams } from '@hooks';
import { Box, Button, CircularProgress, Modal, Typography } from '@mui/material';
import { useLazyUserQuery, useRegisterInviteMutation, useSignupOwnerMutation } from '@services';
import { LabelCheckbox } from '@components/LabelCheckbox';
import { LabelPasswordField } from '@components/LabelPasswordField';
import { LabelTextField } from '@components/LabelTextField';
import { ValidationField } from '@components/ValidationField';
import { ROUTES } from '@router/routes';
import { useLazyGetPermissionsQuery } from '@services/permissions.api';
import { getLanguageId } from '@utils/language';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import useRegisterFormStyle from './RegisterForm.style';
import TosModalView from './TosModal/TosModalView';
export var RegisterVariant;
(function (RegisterVariant) {
    RegisterVariant[RegisterVariant["REGISTER_FROM_INVITATION"] = 0] = "REGISTER_FROM_INVITATION";
    RegisterVariant[RegisterVariant["REGISTER_FROM_WHITELIST"] = 1] = "REGISTER_FROM_WHITELIST";
})(RegisterVariant || (RegisterVariant = {}));
const RegisterForm = ({ registerVariant }) => {
    var _a, _b, _c, _d, _e;
    const { classes } = useRegisterFormStyle();
    const { t, i18n } = useTranslation();
    const [RegisterInvite] = useRegisterInviteMutation();
    const [SignupOwner] = useSignupOwnerMutation();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [openModal, setOpenModal] = useState(false);
    const [getPermissions] = useLazyGetPermissionsQuery();
    const [getUser] = useLazyUserQuery();
    const { queryParams } = useQueryParams();
    const inviteToken = queryParams.get('token');
    const email = queryParams.get('email');
    const predefinedFirstName = queryParams.get('firstName');
    const predefinedLastName = queryParams.get('lastName');
    const minSpecialCharacterMatch = /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/;
    const minDigitMatch = /^(?=.*[0-9])/;
    const minLength = 8;
    const navigate = useNavigate();
    const [showPasswordValidations, setShowPasswordValidations] = useState(false);
    const [passwordRules, setPasswordRules] = useState([
        {
            isValid: ''.length >= minLength,
            text: t('validations.password-must-be-at-least-x-characters-short', { min: minLength }),
        },
        {
            isValid: minDigitMatch.test(''),
            text: t('validations.password-must-at-least-contain-one-figure'),
        },
        {
            isValid: minSpecialCharacterMatch.test(''),
            text: t('validations.password-must-at-least-contain-one-special-character'),
        },
    ]);
    const RegisterFormSchema = yup.object({
        firstName: yup.string().required(t('validations.first-name-required')),
        lastName: yup.string().required(t('validations.last-name-required')),
        email: yup.string().email(t('validations.email-invalid')).required(t('validations.email-required')),
        password: yup
            .string()
            .required(t('validations.password-required'))
            .min(8, t('validations.password-must-be-at-least-x-characters-short', { min: 8 }))
            .matches(/^(?=.*[0-9])/, t('validations.password-must-at-least-contain-one-figure'))
            .matches(/^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/, t('validations.password-must-at-least-contain-one-special-character')),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref('password')], t('validations.password-must-match'))
            .required(t('validations.confirm-password-required')),
        acceptTermsOfUse: yup.bool().oneOf([true], t('validations.terms-of-use-required')),
    });
    const { register, handleSubmit, formState: { errors }, setValue, control, } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            acceptTermsOfUse: false,
            email: email,
        },
        resolver: yupResolver(RegisterFormSchema),
    });
    // TODO: Implement Errors
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        var _f;
        try {
            setLoading(true);
            if (registerVariant === RegisterVariant.REGISTER_FROM_INVITATION) {
                const response = yield RegisterInvite({
                    id: Number(id),
                    token: inviteToken,
                    user: {
                        first_name: data.firstName,
                        last_name: data.lastName,
                        email: email,
                        password: data.password,
                        password_confirmation: data.confirmPassword,
                        language_id: getLanguageId(i18n.language),
                    },
                }).unwrap();
                if (response.id) {
                    yield getPermissions().unwrap();
                    yield getUser().unwrap();
                    navigate(ROUTES.HOME);
                }
            }
            else {
                try {
                    const response = yield SignupOwner({
                        first_name: data.firstName,
                        last_name: data.lastName,
                        email: data.email,
                        password: data.password,
                        password_confirmation: data.confirmPassword,
                        language_id: getLanguageId(i18n.language),
                    }).unwrap();
                    if (response === null || response === void 0 ? void 0 : response.user.id) {
                        yield getUser().unwrap();
                        yield getPermissions().unwrap();
                        navigate(ROUTES.LOGIN);
                    }
                }
                catch (err) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    if (((_f = err.data) === null || _f === void 0 ? void 0 : _f.code) === ErrorCodes.EmailNotInWhitelist.code) {
                        enqueueSnackbar(t('errors.email-not-in-whitelist'), { variant: 'error' });
                        setLoading(false);
                    }
                    else {
                        throw err;
                    }
                }
            }
            setLoading(false);
        }
        catch (err) {
            const httpError = err;
            if ((httpError.data.name && httpError.data.name === 'ValidationError') ||
                httpError.data.name === 'InternalServerError') {
                enqueueSnackbar(httpError.data.message, { variant: 'error' });
            }
            else {
                enqueueSnackbar(t('errors.server-error'), { variant: 'error' });
            }
            setLoading(false);
        }
    });
    useEffect(() => {
        if (registerVariant === RegisterVariant.REGISTER_FROM_INVITATION) {
            if (!email) {
                navigate('/');
            }
            else {
                setValue('firstName', predefinedFirstName !== null && predefinedFirstName !== void 0 ? predefinedFirstName : '');
                setValue('lastName', predefinedLastName !== null && predefinedLastName !== void 0 ? predefinedLastName : '');
            }
        }
    }, []);
    return (_jsx(_Fragment, { children: _jsx(Box, Object.assign({ className: classes.formContainer }, { children: _jsxs("form", Object.assign({ className: classes.form, id: "register-form", noValidate: true, onSubmit: handleSubmit(onSubmit) }, { children: [_jsx(LabelTextField, { InputProps: Object.assign({}, register('firstName')), disabled: loading, error: errors.firstName !== undefined, helperText: (_a = errors.firstName) === null || _a === void 0 ? void 0 : _a.message, label: t('labels.first-name'), variant: "outlined", fullWidth: true, required: true }), _jsx(LabelTextField, { InputProps: Object.assign({}, register('lastName')), disabled: loading, error: errors.lastName !== undefined, helperText: (_b = errors.lastName) === null || _b === void 0 ? void 0 : _b.message, label: t('labels.last-name'), variant: "outlined", fullWidth: true, required: true }), _jsx(LabelTextField, { InputProps: Object.assign({}, register('email')), autoComplete: "email", error: errors.email !== undefined, helperText: (_c = errors.email) === null || _c === void 0 ? void 0 : _c.message, label: t('labels.email'), variant: "outlined", disabled: registerVariant === RegisterVariant.REGISTER_FROM_INVITATION, fullWidth: true, required: true }), _jsx(ValidationField, Object.assign({ open: showPasswordValidations, rules: passwordRules }, { children: _jsx(LabelPasswordField, { InputProps: Object.assign(Object.assign({}, register('password')), { onBlur: (event) => {
                                    setShowPasswordValidations(false);
                                    register('password').onBlur(event);
                                }, onChange: (event) => {
                                    const target = event.target;
                                    setPasswordRules([
                                        {
                                            isValid: target.value.length >= minLength,
                                            text: t('validations.password-must-be-at-least-x-characters-short', {
                                                min: minLength,
                                            }),
                                        },
                                        {
                                            isValid: minDigitMatch.test(target.value),
                                            text: t('validations.password-must-at-least-contain-one-figure'),
                                        },
                                        {
                                            isValid: minSpecialCharacterMatch.test(target.value),
                                            text: t('validations.password-must-at-least-contain-one-special-character'),
                                        },
                                    ]);
                                    register('password').onChange(event);
                                } }), disabled: loading, error: errors.password !== undefined, helperText: (_d = errors.password) === null || _d === void 0 ? void 0 : _d.message, label: t('labels.new-password'), variant: "outlined", onFocus: () => {
                                setShowPasswordValidations(true);
                            }, fullWidth: true, required: true }) })), _jsx(LabelPasswordField, { InputProps: Object.assign({}, register('confirmPassword')), disabled: loading, error: errors.confirmPassword !== undefined, helperText: (_e = errors.confirmPassword) === null || _e === void 0 ? void 0 : _e.message, label: t('labels.confirm-password'), variant: "outlined", fullWidth: true, required: true }), _jsx(Box, Object.assign({ mb: 2, textAlign: "left" }, { children: _jsx(Controller, { control: control, name: "acceptTermsOfUse", render: ({ field: { onChange, onBlur, value, ref } }) => {
                                var _a, _b;
                                return (_jsx(LabelCheckbox, Object.assign({ className: classes.termsOfUse }, register('acceptTermsOfUse'), { label: _jsx("span", Object.assign({ style: { textDecoration: 'underline' }, onClick: (e) => {
                                            e.preventDefault();
                                            setOpenModal(true);
                                        } }, { children: _jsx(Trans, { i18nKey: "labels.i-agree-to-the-terms-of-use-and-privacy-policy" }) })), error: ((_a = errors.acceptTermsOfUse) === null || _a === void 0 ? void 0 : _a.message) !== undefined, helperText: (_b = errors.acceptTermsOfUse) === null || _b === void 0 ? void 0 : _b.message, required: true, onBlur: onBlur, onChange: onChange, checked: value !== null && value !== void 0 ? value : false, inputRef: ref })));
                            } }) })), _jsx(Box, Object.assign({ mt: "14px", mb: "30px" }, { children: _jsx(Button, Object.assign({ type: "submit", variant: "contained", disableElevation: true, fullWidth: true }, { children: loading ? _jsx(CircularProgress, { size: 20 }) : t('buttons.save') })) })), _jsx(Box, Object.assign({ className: classes.centeredText }, { children: _jsxs(Typography, { children: [t('labels.already-have-an-account'), ' ', _jsx(Link, Object.assign({ color: "secondary", to: '/' }, { children: 'Login' }))] }) })), _jsx(Modal, Object.assign({ className: classes.modalHolder, open: openModal, onClose: () => setOpenModal(false) }, { children: _jsx(Box, { children: _jsx(TosModalView, { onClose: () => setOpenModal(false) }) }) }))] })) })) }));
};
export default RegisterForm;
