import { api } from './api.service';
export const createQueryStringFromQueryParams = (seasonId, regionCode, cohortId) => `${seasonId || regionCode || cohortId ? '?' : ''}${seasonId ? `seasonId=${seasonId}&` : ''}${regionCode ? `regionCode=${regionCode}&` : ''}${cohortId ? `cohortId=${cohortId}` : ''}`.replace(/&$/, '');
export const analyticsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getFarmsCropsProgrammeAnalytics: builder.query({
            query: ({ regionCode, cohortId }) => {
                const queryString = createQueryStringFromQueryParams(undefined, regionCode, cohortId);
                return {
                    url: `/private/analytics/programme/farms/crops${queryString}`,
                    method: 'GET',
                };
            },
        }),
        getRegionsByCountry: builder.query({
            query: ({ seasonId, regionCode, cohortId }) => {
                const queryString = createQueryStringFromQueryParams(seasonId, regionCode, cohortId);
                return {
                    url: `/private/analytics/programme/farms/localizations${queryString}`,
                    method: 'GET',
                };
            },
        }),
        getFarmsCarbonResultsAnalytics: builder.query({
            query: ({ regionCode, cohortId }) => {
                const queryString = createQueryStringFromQueryParams(undefined, regionCode, cohortId);
                return {
                    url: `/private/analytics/programme/farms/carbon-results${queryString}`,
                    method: 'GET',
                };
            },
        }),
        getCarbonResultsBestPerformers: builder.query({
            query: () => ({
                url: `/private/analytics/programme/carbon-results`,
                method: 'GET',
            }),
        }),
    }),
});
export const { useGetFarmsCropsProgrammeAnalyticsQuery, useGetRegionsByCountryQuery, useGetFarmsCarbonResultsAnalyticsQuery, useGetCarbonResultsBestPerformersQuery, } = analyticsApi;
