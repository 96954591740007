import { useEffect, useState } from 'react';
import { getElapsedTimeInMinutes } from '@utils/date';
export const useElapsedTime = (previousDate) => {
    const [elapsedMinutes, setElapsedMinutes] = useState(getElapsedTimeInMinutes(previousDate));
    useEffect(() => {
        const intervalId = setInterval(() => {
            setElapsedMinutes(getElapsedTimeInMinutes(previousDate));
        }, 1000);
        return () => clearInterval(intervalId);
    }, [previousDate]);
    return { elapsedMinutes };
};
