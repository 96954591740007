import { useLayoutEffect, useState } from 'react';
import debounce from 'lodash/debounce';
export const useScroll = (ref) => {
    const [hasScrolled, setHasScrolled] = useState(false);
    const getScrollAmount = () => {
        if (!(ref === null || ref === void 0 ? void 0 : ref.current))
            return;
        return setHasScrolled(ref.current.scrollTop > 0);
    };
    const debouncedScroll = debounce(getScrollAmount, 300, { leading: true });
    useLayoutEffect(() => {
        var _a;
        const cleanupRef = ref === null || ref === void 0 ? void 0 : ref.current;
        if (!(ref === null || ref === void 0 ? void 0 : ref.current))
            return;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.addEventListener('scroll', debouncedScroll);
        return () => cleanupRef === null || cleanupRef === void 0 ? void 0 : cleanupRef.removeEventListener('scroll', debouncedScroll);
    }, []);
    return [hasScrolled];
};
