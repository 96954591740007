var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useFarmerProfile } from '@hooks/useFarmerProfile';
import { useParams } from 'react-router-dom';
export const useFarmerDataComputation = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const { farmSeasonId: farmSeasonIdParam, userId: userIdParam } = useParams();
    const farmSeasonId = farmSeasonIdParam ? parseInt(farmSeasonIdParam, 10) : undefined;
    const userId = userIdParam ? parseInt(userIdParam, 10) : undefined;
    const _o = useFarmerProfile({ id: userId }), { data: profile } = _o, farmerProfileQueryResult = __rest(_o, ["data"]);
    const selectedFarmSeasonData = (_c = (_b = (_a = profile === null || profile === void 0 ? void 0 : profile.farm_user) === null || _a === void 0 ? void 0 : _a.farm) === null || _b === void 0 ? void 0 : _b.farm_seasons) === null || _c === void 0 ? void 0 : _c.find((fs) => {
        var _a, _b, _c;
        return Number(fs.id) === farmSeasonId &&
            ((_a = fs === null || fs === void 0 ? void 0 : fs.carbon_programme_year) === null || _a === void 0 ? void 0 : _a.year) !== undefined &&
            ((_b = fs === null || fs === void 0 ? void 0 : fs.carbon_programme_year) === null || _b === void 0 ? void 0 : _b.year) !== null &&
            ((_c = fs === null || fs === void 0 ? void 0 : fs.season) === null || _c === void 0 ? void 0 : _c.harvest_year);
    });
    const selectedFarmSeason = {
        id: Number(selectedFarmSeasonData === null || selectedFarmSeasonData === void 0 ? void 0 : selectedFarmSeasonData.id),
        carbonProgrammeYear: (_d = selectedFarmSeasonData === null || selectedFarmSeasonData === void 0 ? void 0 : selectedFarmSeasonData.carbon_programme_year) === null || _d === void 0 ? void 0 : _d.year,
        harvestYear: (_e = selectedFarmSeasonData === null || selectedFarmSeasonData === void 0 ? void 0 : selectedFarmSeasonData.season) === null || _e === void 0 ? void 0 : _e.harvest_year,
    };
    const farmer = ((_f = profile === null || profile === void 0 ? void 0 : profile.farm_user) === null || _f === void 0 ? void 0 : _f.id) && (profile === null || profile === void 0 ? void 0 : profile.userId)
        ? {
            id: Number(profile.farm_user.id),
            userId: Number(profile.userId),
            invitationId: ((_g = profile.invitation) === null || _g === void 0 ? void 0 : _g.id) ? Number((_h = profile.invitation) === null || _h === void 0 ? void 0 : _h.id) : undefined,
            firstName: profile === null || profile === void 0 ? void 0 : profile.first_name,
            lastName: profile === null || profile === void 0 ? void 0 : profile.last_name,
            farmSeasons: ((_m = (_l = (_k = (_j = profile === null || profile === void 0 ? void 0 : profile.farm_user) === null || _j === void 0 ? void 0 : _j.farm) === null || _k === void 0 ? void 0 : _k.farm_seasons) === null || _l === void 0 ? void 0 : _l.filter((fs) => {
                var _a, _b, _c;
                return ((_a = fs === null || fs === void 0 ? void 0 : fs.carbon_programme_year) === null || _a === void 0 ? void 0 : _a.year) !== undefined &&
                    ((_b = fs === null || fs === void 0 ? void 0 : fs.carbon_programme_year) === null || _b === void 0 ? void 0 : _b.year) !== null &&
                    ((_c = fs === null || fs === void 0 ? void 0 : fs.season) === null || _c === void 0 ? void 0 : _c.harvest_year);
            })) === null || _m === void 0 ? void 0 : _m.map((fs) => {
                var _a, _b;
                return ({
                    id: Number(fs.id),
                    carbonProgrammeYear: (_a = fs === null || fs === void 0 ? void 0 : fs.carbon_programme_year) === null || _a === void 0 ? void 0 : _a.year,
                    harvestYear: (_b = fs === null || fs === void 0 ? void 0 : fs.season) === null || _b === void 0 ? void 0 : _b.harvest_year,
                });
            })) || [],
        }
        : undefined;
    return Object.assign(Object.assign({}, farmerProfileQueryResult), { selectedFarmSeason, farmer });
};
