import { makeStyles } from 'tss-react/mui';
export default makeStyles()((theme) => {
    return {
        contentContainer: {
            padding: `${theme.spacing(5)} ${theme.spacing(10)}`,
        },
        export: {
            display: 'flex',
            alignItems: 'center',
            textTransform: 'capitalize',
        },
    };
});
