import { api } from './api.service';
export const countryApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllCountries: builder.query({
            query: () => ({
                url: `/public/countries`,
                method: 'GET',
            }),
        }),
    }),
});
export const { useGetAllCountriesQuery } = countryApi;
