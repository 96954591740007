var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MuiModal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import { useModalStyles } from './Modal.style';
import { IconCross } from '@components/Icons';
export const Modal = (_a) => {
    var { open, onClose, title, children } = _a, rest = __rest(_a, ["open", "onClose", "title", "children"]);
    const { classes } = useModalStyles();
    return (_jsx(MuiModal, Object.assign({ open: open, onClose: onClose }, rest, { children: _jsx("div", Object.assign({ className: classes.background }, { children: _jsxs("div", Object.assign({ className: classes.contentContainer }, { children: [_jsx(IconCross, { "data-testid": "close-modal", className: classes.closeIcon, onClick: onClose }), title && (_jsx(Typography, Object.assign({ variant: "h2", className: classes.title }, { children: title }))), children] })) })) })));
};
