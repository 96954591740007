import { makeStyles } from 'tss-react/mui';
export default makeStyles()(() => {
    return {
        titleContainer: {
            width: '100%',
            marginBottom: '30px',
        },
        form: {
            position: 'relative',
            padding: '1px',
        },
        modalHolder: {
            height: '100%',
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0,0,0,0.3)',
        },
        termsOfUse: {
            span: {
                paddingLeft: '0px',
            },
        },
        formContainer: {
            maxWidth: '368px',
            width: '100%',
            margin: '0 auto',
        },
        centeredText: {
            textAlign: 'center',
        },
    };
});
