import { ROUTES } from '@router/routes';
export const generateRoute = (route, params) => {
    if (!params)
        return route;
    const path = Object.keys(params).reduce((acc, param) => {
        // eslint-disable-next-line
        const routeParam = params[param];
        return acc.replace(`:${param}`, routeParam);
    }, route);
    if (path.includes(':')) {
        throw new Error(`Missing parameter value in route: ${path}`);
    }
    return path;
};
