import { makeStyles } from 'tss-react/mui';
export default makeStyles()(() => {
    return {
        container: {
        // position: 'relative',
        // width: '100%',
        // height: '100%',
        // zIndex: 1,
        },
        avatar: {
            borderRadius: '50%',
            backgroundColor: '#E7EDF9',
            width: '100px',
            height: '100px',
            display: 'flex;',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '32px 0 32px 0',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        updateBtn: {
            marginTop: '30px',
        },
        subtitle: {
            textAlign: 'center',
        },
    };
});
