import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Card, Collapse, IconButton, Skeleton, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCollapsableCardStyle from './CollapsableCard.style';
export const CollapsableCard = ({ isExpanded, header, action, currentStep, maxSteps, isLoading, children, }) => {
    const { classes } = useCollapsableCardStyle();
    const { t } = useTranslation();
    const [expand, setExpand] = useState(isExpanded);
    const getStepsLabel = () => {
        if (maxSteps === 0) {
            return '';
        }
        else if (currentStep === maxSteps) {
            return t('labels.completed', { context: 'female' });
        }
        return `${currentStep}/${maxSteps}`;
    };
    if (isLoading)
        return (_jsx(Skeleton, { "data-testid": "loading-skeleton", className: classes.container, style: { borderRadius: '10px', height: 80 }, animation: "wave", variant: "rectangular" }));
    return (_jsx(_Fragment, { children: _jsxs(Card, Object.assign({ className: classes.container }, { children: [_jsxs(Box, Object.assign({ display: "flex", justifyContent: 'space-between', flexDirection: "row" }, { children: [_jsxs(Box, Object.assign({ className: classes.headerContainer }, { children: [_jsx(Typography, Object.assign({ variant: "caption" }, { children: header })), _jsx(Typography, Object.assign({ className: classes.steps, variant: "caption" }, { children: getStepsLabel() }))] })), _jsxs(Box, { children: [action, _jsx(IconButton, Object.assign({ onClick: () => setExpand(!expand) }, { children: expand ? (_jsx(ExpandMoreIcon, { "data-testid": "expanded-icon" })) : (_jsx(ChevronRightIcon, { "data-testid": "closed-icon" })) }))] })] })), _jsx(Collapse, Object.assign({ in: expand }, { children: _jsx(_Fragment, { children: children }) }))] })) }));
};
