var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import useResetPasswordFormStyle from './ResetPasswordForm.style';
import { useResetPasswordMutation } from '@services';
import { useNavigate, useParams } from 'react-router-dom';
import { PasswordRules } from '@utils/config';
import { ValidationField } from '@components/ValidationField';
import { LabelPasswordField } from '@components/LabelPasswordField';
import { LabelTextField } from '@components/LabelTextField';
function ResetPasswordForm() {
    var _a, _b, _c;
    const { classes } = useResetPasswordFormStyle();
    const { t } = useTranslation();
    const { resetToken } = useParams();
    const navigate = useNavigate();
    const [sendState, setSendState] = useState(false);
    const [showPasswordValidations, setShowPasswordValidations] = useState(false);
    const [resetPassword, { isLoading }] = useResetPasswordMutation();
    const [passwordRules, setPasswordRules] = useState([
        {
            isValid: ''.length >= PasswordRules.minLength,
            text: t('validations.password-must-be-at-least-x-characters-short', { min: PasswordRules.minLength }),
        },
        {
            isValid: PasswordRules.digitMatch.test(''),
            text: t('validations.password-must-at-least-contain-one-figure'),
        },
        {
            isValid: PasswordRules.specialCharactersMatch.test(''),
            text: t('validations.password-must-at-least-contain-one-special-character'),
        },
    ]);
    const ResetPasswordFormSchema = yup.object({
        email: yup.string().email(t('validations.email-invalid')).required(t('validations.email-required')),
        password: yup
            .string()
            .required(t('validations.password-required'))
            .min(8, t('validations.password-must-be-at-least-x-characters-short', { min: 8 }))
            .matches(/^(?=.*[0-9])/, t('validations.password-must-at-least-contain-one-figure'))
            .matches(/^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/, t('validations.password-must-at-least-contain-one-special-character')),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref('password')], t('validations.password-must-match'))
            .required(t('validations.confirm-password-required')),
    });
    const { register, handleSubmit, formState: { errors }, setError, } = useForm({
        reValidateMode: 'onChange',
        resolver: yupResolver(ResetPasswordFormSchema),
    });
    const onSubmit = (data) => __awaiter(this, void 0, void 0, function* () {
        try {
            const res = yield resetPassword({
                token: resetToken,
                email: data.email,
                password: data.password,
                password_confirmation: data.confirmPassword,
            }).unwrap();
            if (res.result) {
                setSendState(true);
                setTimeout(() => {
                    navigate('/login');
                }, 5000);
            }
        }
        catch (err) {
            if (err.data.error == 'validation.passwords.user') {
                setError('email', { type: 'NOT_FOUND', message: t('validations.email-doesnt-exist') });
            }
            else {
                //TODO: Implement Toast for server error
                console.error(err);
            }
        }
    });
    return (_jsxs("div", Object.assign({ className: classes.container }, { children: [_jsx("div", Object.assign({ className: classes.titleContainer }, { children: _jsx(Typography, Object.assign({ align: "center", variant: "h2" }, { children: t('titles.reset-your-password') })) })), _jsxs("form", Object.assign({ className: classes.form, id: "reset-password-form", noValidate: true, onSubmit: handleSubmit(onSubmit) }, { children: [_jsx(LabelTextField, { InputProps: Object.assign({}, register('email')), autoComplete: "email", disabled: isLoading, error: errors.email !== undefined, helperText: (_a = errors.email) === null || _a === void 0 ? void 0 : _a.message, label: t('labels.email'), variant: "outlined", fullWidth: true, required: true }), _jsx(ValidationField, Object.assign({ open: showPasswordValidations, rules: passwordRules }, { children: _jsx(LabelPasswordField, { InputProps: Object.assign(Object.assign({}, register('password')), { onBlur: (event) => {
                                    setShowPasswordValidations(false);
                                    register('password').onBlur(event);
                                }, onChange: (event) => {
                                    const target = event.target;
                                    setPasswordRules([
                                        {
                                            isValid: target.value.length >= PasswordRules.minLength,
                                            text: t('validations.password-must-be-at-least-x-characters-short', {
                                                min: PasswordRules.minLength,
                                            }),
                                        },
                                        {
                                            isValid: PasswordRules.digitMatch.test(target.value),
                                            text: t('validations.password-must-at-least-contain-one-figure'),
                                        },
                                        {
                                            isValid: PasswordRules.specialCharactersMatch.test(target.value),
                                            text: t('validations.password-must-at-least-contain-one-special-character'),
                                        },
                                    ]);
                                    register('password').onChange(event);
                                } }), disabled: isLoading, error: errors.password !== undefined, helperText: (_b = errors.password) === null || _b === void 0 ? void 0 : _b.message, label: t('labels.new-password'), variant: "outlined", onFocus: () => {
                                setShowPasswordValidations(true);
                            }, fullWidth: true, required: true }) })), _jsx(LabelPasswordField, { InputProps: Object.assign({}, register('confirmPassword')), disabled: isLoading, error: errors.confirmPassword !== undefined, helperText: (_c = errors.confirmPassword) === null || _c === void 0 ? void 0 : _c.message, label: t('labels.confirm-new-password'), variant: "outlined", fullWidth: true, required: true }), _jsx(Box, Object.assign({ mt: "14px", mb: "30px" }, { children: _jsx(Button, Object.assign({ type: "submit", variant: "contained", disableElevation: true, fullWidth: true }, { children: isLoading ? _jsx(CircularProgress, { size: 20 }) : t('buttons.save') })) })), sendState && (_jsx("div", Object.assign({ className: classes.sendMessage }, { children: _jsx(Typography, Object.assign({ align: "center" }, { children: t('messages.password-reseted') })) })))] }))] })));
}
export default ResetPasswordForm;
