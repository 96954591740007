import { makeStyles } from 'tss-react/mui';
export const useAlarmingDataInfoStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    values: {
        marginLeft: theme.spacing(5),
    },
    name: {
        minWidth: '350px',
        paddingRight: theme.spacing(5),
        borderRight: `1px solid ${theme.palette.grey['200']}`,
    },
    button: {
        minWidth: 'auto',
        padding: theme.spacing(3),
        marginLeft: 'auto',
    },
}));
