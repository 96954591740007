import { makeStyles } from 'tss-react/mui';
export const useAnalysisProfileCardStyles = makeStyles()((theme) => ({
    card: {
        padding: theme.spacing(4),
        backgroundColor: theme.palette.grey['50'],
        borderRadius: '8px',
    },
    userInfo: {
        textDecoration: 'none',
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'center',
        marginTop: theme.spacing(2),
        padding: theme.spacing(1),
        backgroundColor: theme.palette.info.main,
        borderRadius: '8px',
    },
    refreshButton: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
    refreshIcon: {
        width: 18,
        height: 18,
    },
}));
