import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material/styles';
export const useTableStyles = makeStyles()((theme) => ({
    tableContainer: {
        height: '100%',
        width: '100%',
        '& .MuiDataGrid-root': {
            border: 'none',
            boxShadow: '2px 0px 17px 0px rgba(240,237,240,1)',
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-columnHeaders': {
            borderBottom: `2px solid ${theme.palette.info.light}`,
        },
        '& .MuiDataGrid-columnHeader': {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
            fontSize: theme.typography.body1.fontSize,
        },
        '& .MuiDataGrid-cell': {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
        },
        '& .MuiDataGrid-cellContent, & .MuiDataGrid-cell': {
            fontSize: theme.typography.body1.fontSize,
            border: 'none',
        },
        '& .centeredCell': {
            display: 'flex',
            justifyContent: 'center',
        },
        '& .MuiDataGrid-row': {
            border: 'none',
            '&:nth-of-type(even)': {
                background: alpha('#E7EDF9', 0.4),
                '&:hover': {
                    background: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
    },
    loadingTable: {
        minHeight: '100%',
        '& .MuiDataGrid-root': {
            height: '100%',
        },
    },
    loaderContainer: {
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    loader: {
        height: '8px',
        borderRadius: '0px',
        width: '100%',
    },
}));
