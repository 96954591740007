import { makeStyles } from 'tss-react/mui';
export const useFarmersPageStyle = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
        gap: theme.spacing(4),
        height: '100%',
    },
}));
