import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FarmSeasonCollapsable } from '@features/farmer/profile/farmSeasonStatus/FarmSeasonCollapsable';
import { OnboardingCollapsable } from './Onboarding/OnboardingCollapsable';
import { useGetFarmerProfileFromUrlId } from '@features/farmer/profile/hooks/useGetFarmerProfileFromUrlId';
import { Box, Skeleton } from '@mui/material';
export const FARMER_DASHBOARD_LOADER_TEST_ID = 'FarmerDashboardCard-loader';
export const FarmerDashboardCard = () => {
    var _a, _b, _c;
    const { profile, isLoading } = useGetFarmerProfileFromUrlId();
    const farmSeasons = (_c = (_b = (_a = profile === null || profile === void 0 ? void 0 : profile.farm_user) === null || _a === void 0 ? void 0 : _a.farm) === null || _b === void 0 ? void 0 : _b.farm_seasons) === null || _c === void 0 ? void 0 : _c.filter((fs) => fs === null || fs === void 0 ? void 0 : fs.season);
    if (isLoading)
        return (_jsxs(Box, Object.assign({ "data-testid": FARMER_DASHBOARD_LOADER_TEST_ID, sx: { display: 'flex', flexDirection: 'column', gap: 4 } }, { children: [_jsx(Skeleton, { height: 140, variant: "rounded", animation: "wave" }), _jsx(Skeleton, { height: 100, variant: "rounded", animation: "wave" })] })));
    if (!farmSeasons || (farmSeasons === null || farmSeasons === void 0 ? void 0 : farmSeasons.length) < 1)
        return null;
    return (_jsxs(_Fragment, { children: [farmSeasons === null || farmSeasons === void 0 ? void 0 : farmSeasons.map((farmSeason, index) => (_jsx(FarmSeasonCollapsable, { farmSeason: farmSeason, previousFarmSeason: farmSeasons === null || farmSeasons === void 0 ? void 0 : farmSeasons[index + 1], profile: profile }, index))), _jsx(OnboardingCollapsable, { profile: profile })] }));
};
