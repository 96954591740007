import { makeStyles } from 'tss-react/mui';
export default makeStyles()(() => {
    return {
        avatarMenuLogoutBtn: {
            fontSize: '0.75rem',
            lineHeight: '0.75rem',
            paddingLeft: 0,
        },
        logoutIcon: {
            fontSize: '17px',
            marginRight: '10px',
        },
    };
});
