import { createSlice } from '@reduxjs/toolkit';
import { alarmingDataApi } from '@services';
const initialState = {
    initialFetch: null,
    lastFetchedAt: null,
};
export const alarmingDataSlice = createSlice({
    name: 'alarmingData',
    initialState,
    reducers: {
        setInitialFetch: (state, action) => {
            var _a;
            state.initialFetch = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.initialFetch;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(alarmingDataApi.endpoints.getAlarmingData.matchFulfilled, (state) => ({
            initialFetch: state.initialFetch,
            lastFetchedAt: new Date().toString(),
        }));
    },
});
export const { setInitialFetch } = alarmingDataSlice.actions;
export const selectAlarmingDataInitialFetch = (state) => state.alarmingData.initialFetch;
export const selectAlarmingDataLastFetchedAt = (state) => state.alarmingData.lastFetchedAt;
export const alarmingDataReducer = alarmingDataSlice.reducer;
