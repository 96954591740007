import { makeStyles } from 'tss-react/mui';
export default makeStyles()(() => {
    return {
        changeBtn: {
            marginTop: '30px',
        },
        description: {
            paddingBottom: '32px',
            alignSelf: 'self-end',
        },
        descriptionContainer: {
            height: '164px',
            display: 'flex',
        },
        subtitle: {
            textAlign: 'center',
        },
        notificationContainer: {
            margin: '0 20px 20px 20px',
            width: 'initial',
        },
        notification: {
            color: 'white',
            fontWeight: '400',
            fontSize: '16px',
        },
    };
});
