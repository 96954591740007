import { useFarmerDataComputation } from '@/pages/AlarmingDataPage/useFarmerDataComputation';
import { useAlarmingDataComputation } from '@/pages/AlarmingDataPage/useAlarmingDataComputation';
export const useAlarmingDataPageView = () => {
    const { isLoading: isLoadingFarmerData, selectedFarmSeason, farmer } = useFarmerDataComputation();
    const { isLoading: isLoadingAlarmingData, data: alarmingData } = useAlarmingDataComputation();
    const isLoading = isLoadingAlarmingData || isLoadingFarmerData;
    const hasData = !!farmer &&
        !!farmer.firstName &&
        !!farmer.lastName &&
        !!farmer.id &&
        farmer.farmSeasons.length > 0 &&
        !!alarmingData;
    return {
        farmer,
        selectedFarmSeason,
        isLoading,
        hasData,
        alarmingData,
    };
};
