import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSettingsButtonStyle from './SettingsButton.style';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
const SettingsButton = () => {
    const { classes } = useSettingsButtonStyle();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const goToSettings = () => {
        navigate('/settings');
    };
    return (_jsxs(Button, Object.assign({ className: classes.avatarMenuLogoutBtn, variant: "text", onClick: goToSettings, fullWidth: true }, { children: [_jsx(SvgIcon, { className: classes.logoutIcon, component: EqualizerOutlinedIcon, viewBox: "0 0 24 24" }), t('buttons.settings')] })));
};
export default SettingsButton;
