import { jsx as _jsx } from "react/jsx-runtime";
import HelpIcon from '@mui/icons-material/Help';
import useHelpButtonStyle from './HelpButton.style';
export const HelpButton = ({ id, size }) => {
    const sizes = {
        small: {
            innerBody: '15px',
            outerBody: '17px',
            position: '-1px',
            height: '12px',
        },
        medium: {
            innerBody: '15px',
            outerBody: '20px',
            position: '-2px',
            height: '14px',
        },
        large: {
            innerBody: '16px',
            outerBody: '22px',
            position: '-2px',
            height: '16px',
        },
    };
    const { classes } = useHelpButtonStyle({
        height: size ? sizes[size].height : sizes.small.height,
        size: size ? sizes[size] : sizes.small,
    });
    return (_jsx("div", Object.assign({ className: classes.container }, { children: _jsx("a", Object.assign({ className: id.split('.').join(''), href: "#/", role: "button", tabIndex: -1, onClick: (ev) => {
                ev.preventDefault();
            } }, { children: _jsx(HelpIcon, { className: classes.help, color: "primary" }) })) })));
};
