import { makeStyles } from 'tss-react/mui';
export const useInputChipsStyles = makeStyles()((theme) => ({
    input: {
        '& .MuiInputBase-root': {
            height: 55,
        },
        '& .MuiInputBase-input': {
            paddingLeft: `${theme.spacing(1)} !important`,
        },
    },
    inputMinWidth: {
        '& .MuiInputBase-input': {
            width: 'auto',
            minWidth: '35%',
        },
    },
    resetButton: {
        padding: 0,
    },
}));
