import { makeStyles } from 'tss-react/mui';
export default makeStyles()((theme) => {
    return {
        container: {
            width: '400px',
            padding: '32px',
        },
        logo: {
            margin: '0 auto',
            width: '100%',
            display: 'block',
            marginBottom: '16px',
        },
        iconContainer: {
            margin: '0 auto',
            height: '80px',
            width: '80px',
            borderRadius: '16px',
            backgroundColor: '#F9F5E9',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '16px',
        },
        icon: {
            height: '48px',
            width: '40px',
            color: theme.palette.primary.main,
        },
        content: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '24px',
            whiteSpace: 'pre-wrap',
        },
        contentGroup: {
            width: '300px',
        },
        titleContainer: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '8px',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    };
});
