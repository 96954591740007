import { makeStyles } from 'tss-react/mui';
export default makeStyles()(() => {
    return {
        button: {
            padding: '10px 14px',
            minWidth: 0,
            borderRadius: 5,
            backgroundColor: '#f6f3E5',
            '&:hover': {
                backgroundColor: '#BDBDBD',
            },
        },
        bellIcon: {
            fontSize: '20px',
        },
        popover: {
            marginTop: '10px',
            marginLeft: '1px',
        },
    };
});
