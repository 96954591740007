import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@mui/material';
import { ArrowTextButton } from '@components/ArrowTextButton';
import { useNavigate } from 'react-router-dom';
import useFormCardStyle from './FormCard.style';
import { Logo } from '@components/Logo';
function FormCard({ children }) {
    const { classes } = useFormCardStyle();
    const navigate = useNavigate();
    return (_jsxs(Paper, Object.assign({ className: classes.container }, { children: [_jsx("div", { children: _jsx(ArrowTextButton, { label: "Back", onClick: () => navigate('/login') }) }), _jsx(Logo, { className: classes.logo }), _jsx("div", Object.assign({ className: classes.formContainer }, { children: children }))] })));
}
export default FormCard;
