import { useSearchParams } from 'react-router-dom';
export const useQueryParams = () => {
    const [queryParams, setQueryParamsRouter] = useSearchParams();
    const setQueryParams = (key, value) => setQueryParamsRouter({ [key]: value });
    const removeQueryParams = (key) => {
        if (queryParams.has(key))
            queryParams.delete(key);
        setQueryParamsRouter(queryParams);
    };
    return { queryParams, setQueryParams, removeQueryParams };
};
