import { makeStyles } from 'tss-react/mui';
export const useFarmerProfileAboutCardStyles = makeStyles()((theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f8f8f8',
        padding: `${theme.spacing(4)} ${theme.spacing(6)}`,
        boxShadow: 'none',
        gap: theme.spacing(3),
        borderRadius: '10px',
    },
    title: {
        fontWeight: 'bold',
    },
    contact: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
    contactTitle: {
        display: 'flex',
        gap: theme.spacing(3),
        alignItems: 'center',
    },
}));
