var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SvgIcon } from '@mui/material';
export const IconFarm = (_a) => {
    var { color = 'primary' } = _a, rest = __rest(_a, ["color"]);
    return (_jsx(SvgIcon, Object.assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 19 21", enableBackground: "new 0 0 19 21", color: color }, rest, { children: _jsxs("g", { children: [_jsx("path", { fill: "currentColor", d: "M17.593,6.23l-0.777-1.036c-0.742-0.99-2.146-2.257-3.2-2.89l-1.893-1.135\n\t\tc-1.264-0.758-3.186-0.757-4.448,0L5.382,2.304c-1.058,0.635-2.456,1.898-3.2,2.89L1.405,6.23C0.611,7.289,0,9.115,0,10.435v6.628\n\t\tc0,1.66,1.341,2.998,3,2.998h12.998c1.66,0,3-1.342,3-2.998v-6.628C18.998,9.11,18.389,7.292,17.593,6.23z M11.623,18.061H7.374\n\t\tv-3.781h4.249V18.061z M16.998,17.062c0,0.553-0.445,0.998-1,0.998h-2.25v-3.781v-0.125c0-1.104-0.895-2-2-2H7.25\n\t\tc-1.104,0-2,0.896-2,2v0.125v3.781H3c-0.556,0-1-0.443-1-0.998v-6.628C2,9.548,2.47,8.143,3.005,7.43l0.777-1.037\n\t\tC4.371,5.608,5.575,4.52,6.412,4.019l1.892-1.135c0.629-0.377,1.761-0.378,2.391,0l1.892,1.135c0.833,0.5,2.043,1.592,2.629,2.375\n\t\tl0.777,1.037c0.538,0.716,1.006,2.113,1.006,3.004V17.062z" }), _jsx("path", { fill: "currentColor", d: "M10.561,6.312H8.437c-0.877,0-1.594,0.713-1.594,1.593c0,0.886,0.714,1.594,1.594,1.594h2.124\n\t\tc0.878,0,1.594-0.713,1.594-1.594C12.154,7.019,11.441,6.312,10.561,6.312z" })] }) })));
};
