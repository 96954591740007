import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Grid, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InfoLabelValueBox } from '../InfoLabelValueBox/InfoLabelValueBox';
import { isObjectEmpty } from '@utils/isEmptyObject';
import { useFarmerProfileFarmCardStyles } from '@features/farmer/profile/components/FarmerProfileFarmCard/FarmerProfileFarmCard.style';
import { useGetFarmerProfileFromUrlId } from '@features/farmer/profile/hooks/useGetFarmerProfileFromUrlId';
export const FARMER_PROFILE_FARM_CARD_LOADING_TEST_ID = 'FarmerProfileFarmCard-loading-testid';
export const FarmerProfileFarmCard = () => {
    var _a, _b;
    const { classes } = useFarmerProfileFarmCardStyles();
    const { t } = useTranslation();
    const { profile, isLoading } = useGetFarmerProfileFromUrlId();
    const farm = (_a = profile === null || profile === void 0 ? void 0 : profile.farm_user) === null || _a === void 0 ? void 0 : _a.farm;
    if (isLoading)
        return (_jsx(Skeleton, { "data-testid": FARMER_PROFILE_FARM_CARD_LOADING_TEST_ID, variant: "rounded", animation: "wave", height: 325 }));
    if (!farm || isObjectEmpty(farm))
        return _jsx(_Fragment, {});
    return (_jsx(_Fragment, { children: _jsx(Card, Object.assign({ className: classes.farmCard }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 10 }, { children: [_jsx(InfoLabelValueBox, { label: t('labels.farm-name'), value: farm.name, placeholder: t('placeholders.farm-name') }), _jsx(InfoLabelValueBox, { label: t('labels.address'), value: farm.address, placeholder: t('placeholders.address') }), _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 6 }, { children: _jsx(InfoLabelValueBox, { label: t('labels.zip-code'), value: farm.postal_code, placeholder: t('placeholders.zip-code') }) })), _jsx(Grid, Object.assign({ item: true, xs: 6 }, { children: _jsx(InfoLabelValueBox, { label: t('labels.city'), value: farm.city, placeholder: t('placeholders.city') }) }))] })), ((_b = farm === null || farm === void 0 ? void 0 : farm.country) === null || _b === void 0 ? void 0 : _b.name) && (_jsx(InfoLabelValueBox, { label: t('labels.country'), value: t(farm.country.name), placeholder: t('placeholders.country') }))] })), _jsx(Grid, { item: true, xs: 2 })] })) })) }));
};
