import { CARBON_RESULT_MODEL, FARMER_STATUS } from '@utils/constants';
import { PROGRESS_CODES, PROGRESS_STATUS } from '@services';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@utils/date';
import { useAuth } from '@hooks';
const getFarmSeasonProgress = (code, farmSeason) => {
    return farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.farm_season_progress.find((progress) => progress.code === code);
};
export const INITIAL_SETUP_STATUSES = {
    NOT_STARTED: 'NOT_STARTED',
    IN_PROGRESS: 'IN_PROGRESS',
    DONE: 'DONE',
};
export const useInitialSetupStatus = ({ farmSeason, carbonModel, farmerStatus }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const { user } = useAuth();
    const isInitialSetupInProgress = carbonModel === CARBON_RESULT_MODEL.CFT
        ? !!(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.self_encoding_started_at)
        : farmerStatus === FARMER_STATUS.INITIAL_SETUP_IN_PROGRESS;
    const technicalItineraryProgressStatus = (_a = farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.farm_season_progress.find((progress) => progress.code === PROGRESS_CODES.TECHNICAL_ITINERARY)) === null || _a === void 0 ? void 0 : _a.status;
    const hasValidatedInitialSetup = carbonModel === CARBON_RESULT_MODEL.CFT
        ? !!(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.installation_validated_at)
        : technicalItineraryProgressStatus === PROGRESS_STATUS.COMPLETED;
    const installationValidationDate = carbonModel === CARBON_RESULT_MODEL.CFT
        ? formatDate(user === null || user === void 0 ? void 0 : user.language.iso_code, farmSeason.installation_validated_at)
        : formatDate(user === null || user === void 0 ? void 0 : user.language.iso_code, ((_c = (_b = getFarmSeasonProgress(PROGRESS_CODES.TECHNICAL_ITINERARY, farmSeason)) === null || _b === void 0 ? void 0 : _b.updated_at) !== null && _c !== void 0 ? _c : ''));
    const getInitialSetupStatus = () => {
        if (hasValidatedInitialSetup)
            return INITIAL_SETUP_STATUSES.DONE;
        if (isInitialSetupInProgress)
            return INITIAL_SETUP_STATUSES.IN_PROGRESS;
        return INITIAL_SETUP_STATUSES.NOT_STARTED;
    };
    const status = getInitialSetupStatus();
    const mapInitialSetupStatusToLabel = (status) => {
        switch (status) {
            case INITIAL_SETUP_STATUSES.NOT_STARTED:
                return t('labels.not-started', { context: 'female' });
            case INITIAL_SETUP_STATUSES.IN_PROGRESS:
                return t('labels.in-progress');
            case INITIAL_SETUP_STATUSES.DONE:
                return installationValidationDate;
        }
    };
    const translatedStatus = mapInitialSetupStatusToLabel(status);
    const initialSetupStatus = {
        label: t('labels.initial-setup'),
        isActive: hasValidatedInitialSetup || isInitialSetupInProgress,
        status,
        value: translatedStatus,
    };
    return { hasValidatedInitialSetup, isInitialSetupInProgress, initialSetupStatus };
};
