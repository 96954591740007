const selectCss = {
    root: ({ theme }) => ({
        '&:hover': {
            fieldset: {
                '&.MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.secondary.main,
                },
            },
        },
        '& > div.MuiSelect-select ': {
            padding: '12px 20px 18px',
            color: theme.palette.primary.light,
            height: '13px',
            minHeight: '13px',
            background: 'rgba(255,255,255,0.4)',
            '&.Mui-focused': {
                background: '#FFF',
            },
        },
        '&.MuiOutlinedInput-root.Mui-focused': {
            fieldset: {
                borderColor: theme.palette.secondary.main,
            },
        },
        fieldset: {
            borderColor: theme.palette.secondary.light,
        },
        svg: {
            fill: theme.palette.secondary.main,
        },
    }),
    select: ({ theme, ownerState }) => (Object.assign({ padding: `${theme.spacing(2)} ${theme.spacing(3)}` }, ((ownerState === null || ownerState === void 0 ? void 0 : ownerState.variant) === 'outlined' && {
        borderRadius: '10px',
        color: theme.palette.primary.main,
    }))),
};
const MuiSelect = { styleOverrides: selectCss };
export default MuiSelect;
