import { usePermission } from '@hooks/usePermission';
import { skipToken } from '@reduxjs/toolkit/query';
import { ApiToggle, useGetAssignableRolesQuery } from '@services';
export const useGetAssignableRoles = (userId) => {
    const { canAccessPlatformAsPartner, canAccessPlatformAsAssociate } = usePermission();
    const params = canAccessPlatformAsPartner
        ? { toggle: ApiToggle.PARTNER, userId: userId }
        : canAccessPlatformAsAssociate
            ? { toggle: ApiToggle.ASSOCIATE, userId: userId }
            : skipToken;
    return useGetAssignableRolesQuery(params);
};
