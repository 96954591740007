import { makeStyles } from 'tss-react/mui';
const useRequiredInputLabelStyle = makeStyles()((theme) => ({
    requiredColor: {
        color: theme.palette.error.main,
    },
    primary: {
        color: theme.palette.primary.main,
    },
    secondary: {
        color: theme.palette.secondary.main,
    },
}));
export default useRequiredInputLabelStyle;
