import { makeStyles } from 'tss-react/mui';
export default makeStyles()((theme) => {
    return {
        container: {
            width: '280px',
        },
        titleContainer: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '30px',
        },
        form: {
            position: 'relative',
            padding: '1px',
        },
        sendMessage: {
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.background.paper,
        },
    };
});
